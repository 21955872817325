import React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

interface SelectInputProps {
  value: string;
  valueChange: (field: string, value: string) => void;
  placeHolder: string;
  searchLabel: string;
  fieldName: string;
  options: { key: number; label: string; value: string }[];
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  value,
  valueChange,
  placeHolder,
  searchLabel,
  fieldName,
  options,
  size = 'medium',
  fullWidth = false,
}) => {
  return (
    <FormControl fullWidth={fullWidth} size={size}>
      <InputLabel id={`${fieldName}-label`}>{searchLabel}</InputLabel>
      <Select
        labelId={`${fieldName}-label`}
        id={fieldName}
        value={value}
        label={searchLabel}
        onChange={(event) => valueChange(fieldName, event.target.value as string)}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
