import React, { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCamerasDetails,
  getArmScheduleForCamera,
} from "../../../services/camera/camera.service";
import { getTimeZonesList } from "../../../services/site/site.service";
import {
  CameraDetailsResponse,
  ArmScheduleResponse,
  TimeZonesResponse,
} from "../../../types/Responses";

import "./CameraDetails.css";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";


// Define constants
const DAYS = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
const HOURS = Array.from(
  { length: 24 },
  (_, i) => i.toString().padStart(2, "0") + ":00"
);
console.log("ho", HOURS);
interface ScheduleCell {
  day: string;
  time: string;
  active: boolean;
  colorClass: string;
}

interface ScheduleEntry {
  day: string;
  time: string;
  active: boolean;
  colorClass: string;
}
interface Schedule {
  day: string;
  periods: {
    time: string;
    active: boolean;
  }[];
}

export interface ArmSchedule {
  id: number;
  idAbtObject: number;
  TimeOn: string; // Heure de début de la surveillance (format "HH:mm")
  TimeOff: string; // Heure de fin de la surveillance (format "HH:mm")
  bMonday: number; // 1 si la surveillance est activée le lundi, sinon 0
  bTuesday: number;
  bWednesday: number;
  bThursday: number;
  bFriday: number;
  bSaturday: number;
  bSunday: number;
}
const CameraDetailsPage = () => {
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { id: cameraIdentification } = useParams<{ id: string }>();

  const { i18n } = useTranslation(); // Obtient l'objet i18n
  const langue = i18n.language; // Langue active

  /******* integrate iframe */
  //const URL_DASHBOARD = process.env.REACT_APP_DASHBOARD_URL;
  const URL_DASHBOARD = "https://dashboard.imavideo-bt.com"; 
  const constructedUrl = `${URL_DASHBOARD}/${langue}/camera/armschedule/${cameraIdentification}/${sessionId}`;
 

  // const fetchScheduleData = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://apiv2-dev.imavideo-dev.com/AcobaSgbdP/SDK_V2/getArmScheduleList"
  //     ); // Utilise l'URL construite ici
  //     console.log(response.data); // Afficher les données récupérées
  //   } catch (error) {
  //     console.error("Erreur lors de la requête :", error);
  //   }
  // };
  // fetchScheduleData();

  // Fetch time zones list
  const TzListQuery = useQuery<TimeZonesResponse>({
    queryKey: ["time_zones"],
    queryFn: () => getTimeZonesList().then((res) => res.data),
  });

  // Memoize time zones list
  const timeZonesArray = useMemo(
    () =>
      Object.entries(TzListQuery.data?.data || {}).map(([id, label]) => ({
        id: Number(id),
        label,
      })),
    [TzListQuery.data]
  );

 //Fetch camera details and arm schedule
  const cameraDetailsQuery = useQuery<CameraDetailsResponse>({
    queryKey: ["cameraDetails", cameraIdentification, sessionId],
    queryFn: () =>
      getCamerasDetails({
        camera_identification: cameraIdentification || "",
        sessionId,
      }),
    enabled: !!cameraIdentification,
    
  });


  const armScheduleQuery = useQuery<ArmScheduleResponse>({
    queryKey: ["armSchedule", cameraIdentification, sessionId],
    queryFn: () =>
      getArmScheduleForCamera(cameraIdentification || "", sessionId),
    enabled: !!cameraIdentification,
  });

  // Memoize camera details and arm schedule
  const memoizedCameraDetails = useMemo(
    () => cameraDetailsQuery.data?.data || null,
    [cameraDetailsQuery.data]
  );
  const memoizedArmSchedule = useMemo(
    () => armScheduleQuery.data?.armschedule || null,
    [armScheduleQuery.data]
  );

  if (!memoizedCameraDetails || !memoizedArmSchedule) {
    return <div>Loading...</div>;
  }

  // Destructuring camera details
  const {
    camera_name,
    statusConnnected,
    statusConnectedLastUpdateTime,
    mac_address,
    serial_number,
    ip_wifi,
    wifi_mac_ddress,
    time_zone_id,
    site_subscription,
    localStorage,
    cnx_ip_address,
    actif_wifi,
    arm_schedule_activate,
    sirenActivateOnAlarmDetection,
    flashActivateOnAlarmDetection,
    isStreamFlipped,
    sensitivity,
    camera_type,
    trackingEnable,
    rectMatrixInfo,
    mobileNotifActivated,
  } = memoizedCameraDetails;

  const connectionStatusClass = statusConnnected
    ? "status-connected"
    : "status-disconnected";

  // Convert hex string to binary grid
  const convertHexToBinaryGrid = (hexString: string) => {
    const hexOnly = hexString.split(":")[0];
    const binaryString = hexOnly
      .split("")
      .map((char: string) => parseInt(char, 16).toString(2).padStart(4, "0"))
      .join("");

    const grid = [];
    for (let i = 0; i < binaryString.length; i += 24) {
      const formattedRow = binaryString
        .slice(i, i + 24)
        .split("")
        .join(" ");
      grid.push(formattedRow);
    }
    return grid.slice(0, 18);
  };

  const motionDetectionGrid = convertHexToBinaryGrid(rectMatrixInfo);

  /* const ArmSchedule: ArmSchedule[] = [
    {
      id: 1,
      idAbtObject: 123,
      TimeOn: "06:00",
      TimeOff: "06:05",
      bMonday: 0,
      bTuesday: 0,
      bWednesday: 1,
      bThursday: 0,
      bFriday: 0,
      bSaturday: 0,
      bSunday: 0,
    },
  ];*/

  // Generate schedule grid
  const generateScheduleGrid = (): {
    grid: ScheduleEntry[];
    inactivePeriods: { day: string; time: string }[];
  } => {
    const timeToMinutes = (time: string) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const isTimeInRange = (
      start: string,
      end: string,
      currentMinutes: number
    ): boolean => {
      const startMinutes = timeToMinutes(start);
      const endMinutes = timeToMinutes(end);

      if (startMinutes <= endMinutes) {
        return currentMinutes >= startMinutes && currentMinutes < endMinutes;
      } else {
        return currentMinutes >= startMinutes || currentMinutes < endMinutes;
      }
    };

    const grid: ScheduleEntry[] = [];
    const inactivePeriods: { day: string; time: string }[] = [];

    DAYS.forEach((day) => {
      HOURS.forEach((time) => {
        const hourInMinutes = timeToMinutes(time);

        const matchingEntry = memoizedArmSchedule.find((entry) => {
          switch (day) {
            case "Lun":
              return entry.bMonday === 1;
            case "Mar":
              return entry.bTuesday === 1;
            case "Mer":
              return entry.bWednesday === 1;
            case "Jeu":
              return entry.bThursday === 1;
            case "Ven":
              return entry.bFriday === 1;
            case "Sam":
              return entry.bSaturday === 1;
            case "Dim":
              return entry.bSunday === 1;
            default:
              return false;
          }
        });

        if (matchingEntry) {
          const { TimeOn, TimeOff } = matchingEntry;
          const isInactivePeriod = isTimeInRange(
            TimeOff,
            TimeOn,
            hourInMinutes
          );

          grid.push({
            day,
            time,
            active: !isInactivePeriod,
            colorClass: !isInactivePeriod ? "active" : "inactive",
          });

          if (isInactivePeriod) {
            inactivePeriods.push({ day, time });
          }
        } else {
          grid.push({
            day,
            time,
            active: true,
            colorClass: "active",
          });
        }
      });
    });

    return { grid, inactivePeriods };
  };

  // Generate schedule grid and inactive periods
  const { grid: scheduleGrid, inactivePeriods } = generateScheduleGrid();
  console.log("Schedule Grid:", scheduleGrid);
  console.log("Inactive Periods:", inactivePeriods);
  const transformToSchedule = (grid: ScheduleEntry[]): Schedule[] => {
    const scheduleMap: Record<string, Schedule> = {};

    grid.forEach((entry) => {
      const { day, time, active } = entry;
      if (!scheduleMap[day]) {
        scheduleMap[day] = { day, periods: [] };
      }
      scheduleMap[day].periods.push({ time, active });
    });

    return Object.values(scheduleMap);
  };

  const scheduleData = transformToSchedule(scheduleGrid);
  console.log("ssss", scheduleData);

  const extractInactivePeriods = (
    scheduleGrid: ScheduleEntry[][]
  ): Record<string, string[]> => {
    const inactivePeriods: Record<string, string[]> = {};

    scheduleGrid.forEach((daySchedule) => {
      daySchedule.forEach((entry) => {
        const { day, time, active, colorClass } = entry;

        // Initialiser la liste des périodes inactives pour le jour si elle n'existe pas encore
        if (colorClass === "inactive") {
          if (!inactivePeriods[day]) {
            inactivePeriods[day] = [];
          }
          inactivePeriods[day].push(time);
        }
      });
    });

    return inactivePeriods;
  };

  //const inactivePeriods = extractInactivePeriods(scheduleGrid);
  //console.log("inactivePeriods",inactivePeriods)

  /*const chartData = scheduleGrid.flat().map(cell => ({
    day: cell.day,
    time: cell.time,
    active: cell.active,
    colorClass: cell.active ? "active" : "inactive",
  }));
  console.log("char",chartData)*/

  return (
    <div className="camera-details-container">
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 30,
          marginTop: 12,
          width: "calc(100% - 262px)",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("camera_details.title")}
          </Typography>
          {/*<Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen} // Add this line
              sx={{
                marginRight: '10px',
                backgroundColor: '#9ad9dd',
                color: '#6b7c93',
                fontSize: '12px',
              }}
            >
              Delete Camera
            </Button>*/}
        </Toolbar>
      </AppBar>

      <TableContainer
        component={Paper}
        sx={{ width: "calc(100% - 262px)", marginLeft: 30 }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.name")}:</strong>
              </TableCell>
              <TableCell>{camera_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.connection_status")}:</strong>
              </TableCell>
              <TableCell>
                <span className={`connection-status ${connectionStatusClass}`}>
                  {statusConnnected
                    ? t("camera_details.connected")
                    : t("camera_details.disconnected")}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.last_connection_date")}:</strong>
              </TableCell>
              <TableCell>
  {statusConnectedLastUpdateTime
    ? new Date(statusConnectedLastUpdateTime + " UTC").toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        // Suppression de 'timeZoneName' pour enlever "UTC+X"
      })
    : "-"}
</TableCell>

            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.mac_address")}:</strong>
              </TableCell>
              <TableCell>{mac_address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.wifi_mac_address")}:</strong>
              </TableCell>
              <TableCell>{wifi_mac_ddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.serial_number")}:</strong>
              </TableCell>
              <TableCell>{serial_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.camera_type")}:</strong>
              </TableCell>
              <TableCell>
                {camera_type === "Extérieur"
                  ? t("camera_details.Exterieur")
                  : t("camera_details.Interieur")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.time_zone")}:</strong>
              </TableCell>
              <TableCell>
                {timeZonesArray.find((tz) => tz.id === Number(time_zone_id))
                  ?.label || t("camera_details.not_defined")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.site_subscription")}:</strong>
              </TableCell>
              <TableCell>{site_subscription}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <strong>{t("camera_details.sd_storage")}:</strong>
                <Box sx={{ paddingLeft: { xs: 4, sm: 6, md: 5.5 } }}>
                  <ul style={{ padding: 0 }}>
                    {localStorage.map((storage, index) => (
                      <div key={index} className="storage">
                        <strong>ID:</strong> {storage.idLocalStorage}
                        <br />
                        <strong>{t("camera_details.total_size")}:</strong>{" "}
                        {storage.totalSize}
                        <br />
                        <strong>{t("camera_details.used_size")}:</strong>{" "}
                        {storage.usedSize}
                        <br />
                        <strong>{t("camera_details.sd_model")}:</strong>{" "}
                        {storage.model}
                        <br />
                        <strong>{t("camera_details.used")}:</strong>{" "}
                        {storage.bUsed === "1" ? t("yes") : t("no")}
                        <br />
                        <strong>{t("camera_details.ready")}:</strong>{" "}
                        {storage.bReady === "1" ? t("yes") : t("no")}
                        <br />
                      </div>
                    ))}
                  </ul>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.stream_flipped")}:</strong>
              </TableCell>
              <TableCell>{isStreamFlipped ? t("yes") : t("no")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.sensitivity")}:</strong>
              </TableCell>
              <TableCell>{sensitivity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.tracking_enable")}:</strong>
              </TableCell>
              <TableCell>{trackingEnable ? t("yes") : t("no")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>
                  {t("camera_details.siren_on_motion_detection")}:
                </strong>
              </TableCell>
              <TableCell>
                {sirenActivateOnAlarmDetection ? t("yes") : t("no")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>
                  {t("camera_details.flash_on_motion_detection")}:
                </strong>
              </TableCell>
              <TableCell>
                {flashActivateOnAlarmDetection ? t("yes") : t("no")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>
                  {t("camera_details.mobile_notification_on_motion_detection")}:
                </strong>
              </TableCell>
              <TableCell>{mobileNotifActivated ? t("yes") : t("no")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.ip_address")}:</strong>
              </TableCell>
              <TableCell>{cnx_ip_address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.ip_wifi")}:</strong>
              </TableCell>
              <TableCell>{ip_wifi}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.wifi_active")}:</strong>
              </TableCell>
              <TableCell>{actif_wifi ? t("yes") : t("no")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>{t("camera_details.mes_mhs_status")}:</strong>
              </TableCell>
              <TableCell>{arm_schedule_activate ? "MES" : "MHS"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: "left" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "98%",
                  }}
                >
                  <strong style={{ marginBottom: "1rem", marginLeft: "0rem" }}>
                    {t("camera_details.motion_detection_grid")}
                  </strong>
                  <div
                    style={{
                      marginLeft: "4rem",
                      textAlign: "center",
                      width: "98%",
                    }}
                  >
                    {motionDetectionGrid.map((row, index) => (
                      <span key={index}>
                        {row}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: 5 }}>
        <Box
          sx={{
            marginTop: { xs: 0, sm: 3, md: 8 },
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ marginLeft: { xs: "30%", sm: "30%", md: "15%" } }}>
            {" "}
            {/* Ajuste la marge à gauche pour être plus réactif */}
            <Typography
              variant="h6"
              component="strong"
              sx={{
                whiteSpace: "nowrap", // Empêche le texte de se compresser ou de se déplacer
                color: "black", // Ajuste la couleur selon le design
              }}
            >
              <strong>{t("camera_details.surveillance_programming")}</strong>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ marginTop: 5, position:'absolute',width: "98%",height: "110%"}}>
          <iframe
            src={constructedUrl}
            title={t("camera_details.surveillance_programming")} // Titre unique
            style={{
              border: "none",
              marginBottom: "180px",
              marginLeft: "250px",
              backgroundColor: "transparent",
              width: "85%",
              height: "600px",
              display: "block",
              overflow: "hidden", 
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CameraDetailsPage;

/*import React, { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCamerasDetails,
  getArmScheduleForCamera,
  deleteCamera,
} from "../../../services/camera/camera.service";
import { getTimeZonesList } from "../../../services/site/site.service";
import {
  CameraDetailsResponse,
  ArmScheduleResponse,
  TimeZonesResponse,
} from "../../../types/Responses";
import "./CameraDetails.css";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  Button,
  TableHead,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import DeleteConfirmationDialog from "../DeleteCamera";
import { DeleteCameraRequest } from "../../../types/Requests";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import SurveillanceScheduleChart from "./Scheduele";

// Define constants
const DAYS = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
const HOURS = Array.from(
  { length: 24 },
  (_, i) => i.toString().padStart(2, "0") + ":00"
);
console.log('ho',HOURS)
interface ScheduleCell {
  day: string;
  time: string;
  active: boolean;
  colorClass: string;
}

interface ScheduleEntry {
  day: string;
  time: string;
  active: boolean;
  colorClass: string;
}

interface Schedule {
  day: string; // Day name, e.g., 'Lun'
  time: string; // Hour in 'HH:MM' format
  active: boolean; // Indicates if the period is active
}
export interface ArmSchedule {
  id: number;
  idAbtObject: number;
  TimeOn: string; // Heure de début de la surveillance (format "HH:mm")
  TimeOff: string; // Heure de fin de la surveillance (format "HH:mm")
  bMonday: number; // 1 si la surveillance est activée le lundi, sinon 0
  bTuesday: number; // 1 si la surveillance est activée le mardi, sinon 0
  bWednesday: number; // 1 si la surveillance est activée le mercredi, sinon 0
  bThursday: number; // 1 si la surveillance est activée le jeudi, sinon 0
  bFriday: number; // 1 si la surveillance est activée le vendredi, sinon 0
  bSaturday: number; // 1 si la surveillance est activée le samedi, sinon 0
  bSunday: number; // 1 si la surveillance est activée le dimanche, sinon 0
}
const CameraDetailsPage = () => {
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { id: cameraIdentification } = useParams<{ id: string }>();
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    data: "",
  });

  // Fetch time zones list
  const TzListQuery = useQuery<TimeZonesResponse>({
    queryKey: ["time_zones"],
    queryFn: () => getTimeZonesList().then((res) => res.data),
  });

  // Memoize time zones list
  const timeZonesArray = useMemo(
    () =>
      Object.entries(TzListQuery.data?.data || {}).map(([id, label]) => ({
        id: Number(id),
        label,
      })),
    [TzListQuery.data]
  );

  // Fetch camera details and arm schedule
  const cameraDetailsQuery = useQuery<CameraDetailsResponse>({
    queryKey: ["cameraDetails", cameraIdentification, sessionId],
    queryFn: () =>
      getCamerasDetails({
        camera_identification: cameraIdentification || "",
        sessionId,
      }),
    enabled: !!cameraIdentification,
  });

  const armScheduleQuery = useQuery<ArmScheduleResponse>({
    queryKey: ["armSchedule", cameraIdentification, sessionId],
    queryFn: () =>
      getArmScheduleForCamera(cameraIdentification || "", sessionId),
    enabled: !!cameraIdentification,
  });

  // Memoize data
  const memoizedCameraDetails = useMemo(
    () => cameraDetailsQuery.data?.data || null,
    [cameraDetailsQuery.data]
  );
  const memoizedArmSchedule = useMemo(
    () => armScheduleQuery.data?.armschedule || null,
    [armScheduleQuery.data]
  );

  // Mutation for deleting camera
  const deleteCameraMutation = useMutation({
    mutationFn: (request: DeleteCameraRequest) => {
      return deleteCamera(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess("Camera deleted successfully"));
      queryClient.invalidateQueries({ queryKey: ["cameras"] });
      handleCloseConfirmation();
    },
    onError: (error) => {
      dispatch(notifyError("Failed to delete the camera"));
      console.error(error);
    },
  });

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.data) {
      const requestData: DeleteCameraRequest = {
        subscription_id: deleteConfirmation.data,
        camera_identification: cameraIdentification || "",
      };

      try {
        await deleteCameraMutation.mutateAsync(requestData);
      } catch (error) {
        console.error("Error deleting camera:", error);
        dispatch(notifyError("Failed to delete the camera"));
      }
    }
  };

  const handleCloseConfirmation = () => {
    setDeleteConfirmation({ open: false, data: "" });
  };

  const handleClickOpen = () => {
    if (cameraIdentification) {
      setDeleteConfirmation({
        open: true,
        data: cameraIdentification,
      });
    }
  };

  if (!memoizedCameraDetails || !memoizedArmSchedule) {
    return <div>Loading...</div>;
  }

  // Destructuring camera details
  const {
    camera_name,
    statusConnnected,
    statusConnectedLastUpdateTime,
    mac_address,
    serial_number,
    camera_model,
    time_zone_id,
    site_subscription,
    localStorage,
    cnx_ip_address,
    actif_wifi,
    arm_schedule_activate,
    sirenActivateOnAlarmDetection,
    flashActivateOnAlarmDetection,
    isStreamFlipped,
    sensitivity,
    camera_type,
    trackingEnable,
    rectMatrixInfo,
    mobileNotifActivated,
  } = memoizedCameraDetails;

  const connectionStatusClass = statusConnnected
    ? "status-connected"
    : "status-disconnected";

  // Convert hex string to binary grid
  const convertHexToBinaryGrid = (hexString: string) => {
    const hexOnly = hexString.split(":")[0];
    const binaryString = hexOnly
      .split("")
      .map((char: string) => parseInt(char, 16).toString(2).padStart(4, "0"))
      .join("");

    const grid = [];
    for (let i = 0; i < binaryString.length; i += 24) {
      const formattedRow = binaryString
        .slice(i, i + 24)
        .split("")
        .join(" ");
      grid.push(formattedRow);
    }
    return grid.slice(0, 18);
  };

  const motionDetectionGrid = convertHexToBinaryGrid(rectMatrixInfo);

  const ArmSchedule: ArmSchedule[] = [
    {
      id: 1,
      idAbtObject: 123,
      TimeOn: "06:00",
      TimeOff: "22:00",
      bMonday: 0,
      bTuesday: 0,
      bWednesday: 1,
      bThursday: 0,
      bFriday: 0,
      bSaturday: 0,
      bSunday: 0,
    }
  
  ];
  // Generate schedule grid
  const generateScheduleGrid = () => {
    const timeToMinutes = (time: string) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const isTimeInRange = (
      start: string,
      end: string,
      currentMinutes: number
    ): boolean => {
      const startMinutes = timeToMinutes(start);
      const endMinutes = timeToMinutes(end);

      if (startMinutes <= endMinutes) {
        return currentMinutes >= startMinutes && currentMinutes < endMinutes;
      } else {
        return currentMinutes >= startMinutes || currentMinutes < endMinutes;
      }
    };

    const grid = DAYS.map(day => {
      return HOURS.map(time => {
        const hourInMinutes = timeToMinutes(time);

        const matchingEntry = memoizedArmSchedule.find(entry => {
          switch (day) {
            case "Lun":
              return entry.bMonday === 1;
            case "Mar":
              return entry.bTuesday === 1;
            case "Mer":
              return entry.bWednesday === 1;
            case "Jeu":
              return entry.bThursday === 1;
            case "Ven":
              return entry.bFriday === 1;
            case "Sam":
              return entry.bSaturday === 1;
            case "Dim":
              return entry.bSunday === 1;
            default:
              return false;
          }
        });

        if (matchingEntry) {
          const { TimeOn, TimeOff } = matchingEntry;
          const isInactivePeriod = isTimeInRange(TimeOff, TimeOn, hourInMinutes);

          return {
            day,
            time,
            active: !isInactivePeriod,
            colorClass: isInactivePeriod ? "inactive" : "active",
          };
        } else {
          return {
            day,
            time,
            active: true,
            colorClass: "active",
          };
        }
      });
    });

    return grid;
  };

  const scheduleGrid2D: Schedule[][] = [
    [
      { day: "Lun", time: "10:00", active: true },
      { day: "Lun", time: "11:00", active: false },
    ],
    [
      { day: "Mar", time: "10:00", active: true },
      { day: "Mar", time: "11:00", active: true },
    ],
    // Ajoutez d'autres jours et heures...
  ];

  //const scheduleGrid = generateScheduleGrid();
  const scheduleGrid: Schedule[] = scheduleGrid2D.flat();
  console.log("Schedule Grid:", scheduleGrid);
  const extractInactivePeriods = (scheduleGrid: ScheduleEntry[][]): Record<string, string[]> => {
    const inactivePeriods: Record<string, string[]> = {};
  
    scheduleGrid.forEach(daySchedule => {
      daySchedule.forEach(entry => {
        const { day, time, active, colorClass } = entry;
  
        // Initialiser la liste des périodes inactives pour le jour si elle n'existe pas encore
        if (colorClass === 'inactive') {
          if (!inactivePeriods[day]) {
            inactivePeriods[day] = [];
          }
          inactivePeriods[day].push(time);
        }
      });
    });
  
    return inactivePeriods;
  };
  
const inactivePeriods = extractInactivePeriods(scheduleGrid);
console.log("inactivePeriods",inactivePeriods)

  /*const chartData = scheduleGrid.flat().map(cell => ({
    day: cell.day,
    time: cell.time,
    active: cell.active,
    colorClass: cell.active ? "active" : "inactive",
  }));
  console.log("char",chartData)*//*

  return (
    <div className="camera-details-page">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            {t("Camera Details")}
          </Typography>
          <Button color="inherit" onClick={handleClickOpen}>
            {t("Delete Camera")}
          </Button>
        </Toolbar>
      </AppBar>
      <Box className="camera-info">
        <Typography variant="h4">{camera_name}</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Property")}</TableCell>
                <TableCell>{t("Value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("Status Connected")}</TableCell>
                <TableCell>
                  <span className={connectionStatusClass}>
                    {statusConnnected ? t("Connected") : t("Disconnected")}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Last Update")}</TableCell>
                <TableCell>{statusConnectedLastUpdateTime}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("MAC Address")}</TableCell>
                <TableCell>{mac_address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Serial Number")}</TableCell>
                <TableCell>{serial_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Camera Model")}</TableCell>
                <TableCell>{camera_model}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Time Zone")}</TableCell>
               
              </TableRow>
              {/* Add more rows as needed *//*
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="motion-detection-grid">
          <Typography variant="h5">{t("Motion Detection Grid")}</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {motionDetectionGrid.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
       {/* <SurveillanceScheduleChart
  scheduleGrid={scheduleGrid}
  days={DAYS}
  hours={HOURS}
 // inactivePeriods={inactivePeriods} // Pass inactive periods here
/>*//*
  <SurveillanceScheduleChart
        scheduleGrid={scheduleGrid} // Passer le tableau aplati
        days={DAYS}
      />

      </Box>
     
    </div>
  );
};

export default CameraDetailsPage;*/