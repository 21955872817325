export enum Color {
    AppBarBg = "rgba(100,100,100,0.1)",
    AppFooterBg = "#e0e0e0",
    AppFooterShadow = "rgba(0, 0, 0, 1)",
  
    CollapsableContentBg = "rgba(100,100,100,0.1)",
    CollapsableTitleBg = "#1C82AD",
  
    AssetPreviewBg = "rgba(100,100,100,0.1)",
    AssetCardBg = "rgba(255, 255, 255, 0.8)",
  
    LoaderPrimary = "#86E5FF",
    LoaderSecondary = "#5BC0F8",
    LoaderTertiary = "#3797d3",
    LoaderWhite = "white",
  
    TextWhite = "white",
    TextBlack = "black",
    TextGrey = "#6d6d6d",
    TextPrimary = "#0081B4",
    TextSuccess = "#28a745",
    TextWarn = "orange",
    TextDanger = "#dc3545",
  
    BorderWhite = "white",
    BorderBlack = "black",
    BorderGrey = "#6d6d6d",
    BorderGreyLight = "#d4cbcb",
    BorderPrimary = "#0081B4",
  
    TableBg = "#fafafa",
    TableTitleBg = "#1C82AD",
    TableTitleText = "white",
    TableColumnText = "steelblue",
    TableGoToBg = "#1C82AD",
    TableGoToContainerBg = "rgb(240,240,240)",
    TablePageText = "#0081B4",
    TableCurrentPageText = "black",
    TablePageContainerHover = "rgba(191, 234, 245,0.3)",
    TableEvenRowBg = "#f2f6fc",
    TableEvenRowBgDarker = "rgba(191, 234, 245,0.7)",
    TableOddRowBg = "#f7f7f7",
    TableOddRowBgLighter = "rgba(255, 255, 255, 0.8)",
    TableCellBorder = "rgba(0, 0, 0, 0.1)",
  
    BasicButtonShaddow1 = "rgba(0, 0, 0, 0.2)",
    BasicButtonShaddow2 = "rgba(0, 0, 0, 0.14)",
    BasicButtonShaddow3 = "rgba(0, 0, 0, 0.12)",
    ButtonPrimaryShadow = "rgba(0, 0, 0, 0.5)",
    ButtonPrimaryBg = "#0081B4",
    ButtonPrimaryBgHover = "#339ac3",
    ButtonDangerBg = "rgba(240,60,60,1)",
    ButtonDangerBgHover = "#f36363",
    ButtonTextBg = "trasnparent",
    ButtonTextBgHover = "rgba(0, 0, 0, 0.1)",
  
    ScrollBarThumb = "#c0c0c0",
    ScrollBarButton = "#555555",
    ScrollBarButtonHover = "black",
    ScrollBarThumbHover = "#a0a0a0",
    ScrollBarTrack = "#efefef",
  
    BackgroundRed = "#f2244c",
    BackgroundGreen = "#739e73",
    BackgroundBlue = "#0081B4",
    BackgroundSkyBlue = "#1da9e0",
    BackgroundPurple = "#9c4fe8",
    BackgroundYellow = "#bcb106",
    BackgroundOrange = "#f8851b",
    BackgroundBrown = "#924600",
    BackgroundWhite = "white",
    BackgroundGrey = "grey",
  
    BluredDivBg = "rgba(0,0,0,0.15)",
    SelectMultiTagBg = "rgb(230,230,230)",
  }
  