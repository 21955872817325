import React,{ useCallback,useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store'; // Assurez-vous d'importer correctement RootState depuis votre store
import { Box } from "@mui/material";
import Sidebar from './sidebar';
import Content from './content';

import NotificationToast from '../../components/reusable/notification/NotificationToast'; // Import du composant de notification

import { mainStyle } from '../../styles/layout/layout.style';

const mainElements = mainStyle;

interface MainProps {
  showSidebar: boolean;
}

const Main: React.FC<MainProps> = ({ showSidebar }) => {
  const [closedToasts, setClosedToasts] = useState<number[]>([]);
  // Sélectionnez les messages de notification depuis l'état Redux
  const notifications = useAppSelector((state: RootState) => state.notification.messages);
    // Callback to handle toast closure
    const onToastClose = useCallback((index: number) => {
      setClosedToasts((oldToasts) => [...oldToasts, index]);
    }, []);

  return (
    <Box  sx={{
     // marginLeft: showSidebar ? 0 : -60, // Adjust based on sidebar width
      //transition: 'margin-left 0.3s ease',
    }}>
      {showSidebar && <Sidebar />}
      <Content />
      {/* Bouclez à travers les messages de notification et affichez-les */}
      {notifications.map((notification, index) => (
        <NotificationToast
          key={index}
          message={notification.message}
          severity={notification.severity}
          showing={notification.showing}
          onClose={onToastClose} // Assurez-vous de gérer la fermeture de la notification
          index={index}
        />
      ))}
    </Box>
  );
};

export default Main;


