import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box, AppBar, Toolbar, Button, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { WifiTethering } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CreateCameraToSite from "../CreateCameraToSite";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import PortableWifiOffIcon from "@mui/icons-material/PortableWifiOff";
import {
  GetCameraListBySiteResponse,
  SiteListResponse,
} from "../../../types/Responses";
import {
  getCamerasBySite,
  getSitesList,
} from "../../../services/site/site.service";
import {
  DeleteCameraRequest,
  GetCameraListBySiteRequest,
  SitesListRequest,
} from "../../../types/Requests";
import { useMutation } from "@tanstack/react-query";
import { deleteCamera } from "../../../services/camera/camera.service";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicModal from "../../reusable/modals/BasicModal";
import SearchInput from "../../reusable/inputs/SearchInput";
import CustomPagination from "../../reusable/tables/CustomPagination";
import { invalidateSession } from "../../../store/reducers/user.slice";
import SiteInfoSection from "./SiteInfoSection/SiteInfoSection";

type FilterData = {
  NumeroDeSerie: string;
  Utilisateur: string;
  prom: string;
};

interface CameraData {
  camera_id: string;
  subscription_id: string;
  mac_address: string;
  serial_number: string;
  camera_model: string;
  status_connected: string;
  camera_name: string;
  time_zone_id: string;
  status_record: number;
  prom: string;
  last_connection_time?: string; // Propriété optionnelle
  last_restart_time?: string;
  user_tag?: string;
}

const SiteDetailsPage = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    data: DeleteCameraRequest | null;
  }>({
    open: false,
    data: null,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  //Fetching sites
  const { data, isLoading } = useQuery<SiteListResponse>({
    queryKey: ["sites", sessionId, id],
    queryFn: async () => {
      const filterApplied: SitesListRequest = {
        sessionId,
      };

      try {
        const response = await getSitesList(filterApplied);
        console.log("Response Data:", response.data);
        return response.data; // Extract data from Axios response
      } catch (err) {
        console.error("API Request Error:", err);
        throw err; // Ensure errors are thrown so react-query can handle them
      }
    },
  });

  //Memorizing sites
  const sites = useMemo(() => data?.data || [], [data]);
  const selectedSite = useMemo(
    () => sites.find((site) => site.site_id === id),
    [sites, id]
  );
  console.log("selectedSite++++", selectedSite);
  useEffect(() => {
    console.log("Selected site userId:", selectedSite?.user_id);
  }, [selectedSite]);

  //displayed filter GUI control
  const [filterData, setFilterData] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
  });
  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
  });

  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };

  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };
  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  // Function to check if filters are unequal
  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.NumeroDeSerie !== next.NumeroDeSerie ||
      current.Utilisateur !== next.Utilisateur ||
      current.prom !== next.prom
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  //get cameras by site
  // const { data: cameraData } = useQuery<GetCameraListBySiteResponse>({
  //   queryKey: ["camerasBySite", sessionId,searchFilter, id,pagination],
  //   queryFn: async () => {
  //     if (!id) {
  //       throw new Error("Site ID is undefined");
  //     }

  //     const filterApplied: GetCameraListBySiteRequest = {
  //       sessionId,
  //       site_id: id,
  //       page_number: pagination.page, // this aligns with your API's page numbering
  //       max_results: pagination.pageSize,
  //     };
  //     // Add filters only if they are not empty
  //     if (searchFilter.NumeroDeSerie !== "") {
  //       filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
  //     }
  //     if (searchFilter.Utilisateur !== "") {
  //       filterApplied.user_tag_like = searchFilter.Utilisateur;
  //     }
  //     if (searchFilter.prom !== "") {
  //       filterApplied.site_identifier_for_arc_like = searchFilter.prom;
  //     }
  //     try {
  //       const response = await getCamerasBySite(filterApplied);
  //       console.log("Response Data----:", response.data);
  //       return response.data; // Extraire les données de la réponse Axios
  //     } catch (err) {
  //       console.error("API Request Error:", err);
  //       throw err; // Assurez-vous que les erreurs sont lancées pour que react-query puisse les gérer
  //     }
  //   },
  // });

  const { data: cameraData } = useQuery<GetCameraListBySiteResponse>({
    queryKey: ["camerasBySite", sessionId, searchFilter, id, pagination],
    queryFn: async () => {
      if (!id) {
        throw new Error("Site ID is undefined");
      }

      const filterApplied: GetCameraListBySiteRequest = {
        sessionId,
        site_id: id,
        page_number: pagination.page, // this aligns with your API's page numbering
        max_results: pagination.pageSize,
      };
      // Add filters only if they are not empty
      if (searchFilter.NumeroDeSerie !== "") {
        filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
      }
      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }
      if (searchFilter.prom !== "") {
        filterApplied.site_identifier_for_arc_like = searchFilter.prom;
      }
      try {
        const response = await getCamerasBySite(filterApplied);

        // Vérifiez si la réponse contient une erreur
        if (response.data.errors) {
          const { code, message } = response.data.errors;

          // Si le code d'erreur est -37, traiter comme une session expirée
          if (code === "-37") {
            dispatch(notifyError(t("sessionExpiredMessage")));
            dispatch(invalidateSession());
            return;
          }

          // Autres types d'erreurs peuvent être traités ici
          throw new Error(`Erreur API : ${message}`);
        }
        return response.data; // Retourner les données de l'API si aucune erreur
      } catch (err) {
        console.error("API Request Error:", err);
        throw err;
      }
    },
  });

  // Memoize rows to avoid unnecessary re-renders
  const CamerasOfSite = useMemo(() => cameraData?.data || [], [cameraData]);

  //Mutation to delete camera
  const deleteCameraMutation = useMutation({
    mutationFn: (request: DeleteCameraRequest) => {
      return deleteCamera(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("Camera deleted successfully")));
      queryClient.invalidateQueries({ queryKey: ["camerasBySite", sessionId] });
    },
    onError: (error) => {
      dispatch(notifyError(t("Failed to delete the camera")));
      console.error(error);
    },
  });

  const handleDelete = (event: React.MouseEvent, cameraId: string) => {
    event.stopPropagation();
    const selectedCamera = CamerasOfSite.find(
      (camera) => camera.camera_id === cameraId
    );

    if (selectedCamera) {
      // Remplir les données de confirmation avec les informations requises
      setDeleteConfirmation({
        open: true,
        data: {
          subscription_id: selectedCamera.subscription_id || "", 
          camera_identification: cameraId, 
        },
      });
    }
  };

  const handleCloseConfirmation = () => {
    setDeleteConfirmation({ open: false, data: null });
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.data) {
      // Appel de la mutation pour supprimer la caméra
      deleteCameraMutation.mutate(deleteConfirmation.data);

      // Fermer la boîte de confirmation
      handleCloseConfirmation();
    }
  };

  const handleClose = () => {
    setOpenCreate(false); // Ferme le popup
  };

  const handleClickOpen = () => {
    setOpenCreate(true);
  };

  // Handles a click event on a row in the data grid.
  const handleRowClick = (params: { row: CameraData }): void => {
    const cameraId = params.row?.camera_id;
    if (cameraId) {
      navigate(`/details_site/${cameraId}/camerasBySite`);
    }
  };

  
 // Formate une date UTC en date locale avec le fuseau horaire de l'utilisateur 
 const formatDate = (dateString: string | null) => {
  if (!dateString) return "-";

  const utcDate = new Date(dateString + " UTC"); // Convertir en UTC
  return utcDate.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};


  //Columns
  const columns: GridColDef[] = [
    {
      field: "serial_number",
      headerName: t("numeroSerie"),
      minWidth: 160,
      flex: 1.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("numeroSerie")}
        </span>
      ),
      renderCell: (params) => (
        <span>{params.row ? params.row.serial_number : ""}</span>
      ),
    },
    {
      field: "mac_address",
      headerName: t("adresseMac"),
      minWidth: 135,
      flex: 1.3,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("adresseMac")}
        </span>
      ),
      renderCell: (params) => (
        <span>{params.row ? params.row.mac_address : ""}</span>
      ),
    },
    {
      field: "camera_model",
      headerName: t("modeleCamera"),
      minWidth: 160,
      flex: 1.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("modeleCamera")}
        </span>
      ),
      renderCell: (params) => (
        <span>{params.row ? params.row.camera_model : ""}</span>
      ),
    },
    {
      field: "camera_name",
      headerName: t("nomCamera"),
      minWidth: 140,
      flex: 1.3,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("nomCamera")}
        </span>
      ),
      renderCell: (params) => (
        <span>{params.row ? params.row.camera_name : ""}</span>
      ),
    },
    {
      field: "prom",
      headerName: "Prom",
      minWidth: 120,
      flex: 1.2,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {"Prom"}
        </span>
      ),
      renderCell: (params) => <span>{params.row ? params.row.prom : ""}</span>,
    },
    {
      field: "user_tag",
      headerName: t("Utilisateur"),
      minWidth: 160,
      flex: 1.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <span>{params.row ? params.row.user_tag : ""}</span>
      ),
    },
    {
      field: "date_of_creation",
      headerName: t("dateInstallation"),
      minWidth: 180,
      flex: 1.8,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("dateInstallation")}
        </span>
      ),
      renderCell: (params) => (
        <span>{formatDate(params.row ? params.row.date_of_creation : "")}</span>
      ),
    },
    {
      field: "last_Cnx_Tmp",
      headerName: t("lastConnection"),
      minWidth: 160,
      flex: 1.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("lastConnection")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {formatDate(params.row ? params.row.last_Cnx_Tmp : "")}
        </span>
      ),
    },
    {
      field: "last_Updated_Time",
      headerName: t("lastUpdated_Time"),
      minWidth: 160,
      flex: 1.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("lastUpdated_Time")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {formatDate(params.row ? params.row.last_Updated_Time : "")}
        </span>
      ),
    },
    {
      field: "status_connected",
      headerName: t("statutConnecte"),
      minWidth: 80,
      flex: 0.8,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("statutConnecte")}
        </span>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            color:
              params.row?.status_connected === "1"
                ? "rgb(40, 167, 69)"
                : "rgba(255, 0, 0, 0.5)",
            textAlign: "center",
          }}
        >
          {params.row?.status_connected === "1" ? (
            <WifiTethering sx={{ fontSize: 16 }} />
          ) : (
            <PortableWifiOffIcon sx={{ fontSize: 16 }} />
          )}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: t("Actions"),
      flex: 0.8,
      minWidth: 80,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("Actions")}
        </span>
      ),
      renderCell: (params) =>
        permissions.includes("deleteCameraSite") && (
          <Button
            variant="outlined"
            sx={{ color: "#5585b5", fontSize: 8, width: 2, marginRight: 2 }}
            onMouseEnter={() => setHoveredRowId(params.row?.camera_id)}
            onMouseLeave={() => setHoveredRowId(null)}
            onClick={(event) => handleDelete(event, params.row.camera_id)}
          >
            {hoveredRowId === params.row?.camera_id ? (
              t("Delete")
            ) : (
              <DeleteIcon />
            )}
          </Button>
        ),
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //Determines the CSS class name to apply to a row based on its index.
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  //Determine the row count
  const totalItems = cameraData?.total ?? 0;
  const totalPages = cameraData?.has_more
    ? pagination.page + 1
    : pagination.page;
  const isLastPage = cameraData?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize;

  return (
    <>
    {selectedSite && (
     <Box mt={3} marginTop={15}>
      <SiteInfoSection sites={sites} selectedSite={selectedSite} />
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 30,
          marginTop: 5,
          width: "calc(100% - 262px)",
          height: 55,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("Liste des caméras")}
          </Typography>
          {permissions.includes("addCameraSite") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                ml: "auto",
                margin: { xs: "4px", sm: "8px", md: "12px", lg: "16px" },
                position: "relative",
                bottom:2.5,
                backgroundColor: "rgb(224, 247, 250)",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                "&:hover": {
                  backgroundColor: "#3498DB",
                  color: "white",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                "&:focus": {
                  backgroundColor: "#3498DB",
                  color: "#393e46",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                color: "#393e46",
                fontSize: { xs: "8px", sm: "10px", md: "12px" },
                whiteSpace: "nowrap",
                width: { xs: "auto", sm: "auto" },
                maxWidth: { xs: "200px", sm: "250px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Ensure icon color changes with text color on hover
                "& .MuiSvgIcon-root": {
                  color: "inherit",
                },
                // Ensure icon color changes with text color on focus
                "& .MuiSvgIcon-root:focus": {
                  color: "inherit",
                },
              }}
            >
              <AddIcon
                sx={{
                  height: { xs: 12, sm: 15 },
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
              />
              {t("addCamera")}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <SearchInput
          placeHolder={t("Filtrer par numéro de série")}
          searchLabel={t("Filtrer par numéro de série")}
          fieldName="NumeroDeSerie"
          value={filterData.NumeroDeSerie}
          valueChange={changeFilterData}
          size="small"
          width="85%"
        />
        <SearchInput
          placeHolder={t("Filtrer par PROM")}
          searchLabel={t("Filtrer par PROM")}
          fieldName="prom"
          value={filterData.prom}
          valueChange={changeFilterData}
          size="small"
          width="85%"
        />
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          width="85%"
        />
      </Box>
      <Grid
        sx={{
          flexGrow: 1,

          width: "98.5%",
          marginBottom: 30,
        }}
      >
        <DataGrid
          rows={CamerasOfSite}
          columns={columns}
          onRowClick={handleRowClick}
          //autoHeight
          loading={isLoading}
          rowCount={rowCount}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            //an event handler that updates the pagination state whenever the user changes the page number or page size.
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
          }}
          getRowClassName={getRowClassName}
          getRowId={(row) => row.camera_id}
          sx={{
            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
              marginRight: "20px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            // width: "calc(100% - 262px)",
            marginBottom: 10,
            marginLeft: 30,
            "& .even-row": {
              backgroundColor: "#ffffff",
            },
            "& .odd-row": {
              backgroundColor: "#eff9ff",
            },
            fontSize: "small",
            "& .MuiDataGrid-scrollbar ": {
              position: "relative",
            },
            // Masquer les ellipses de tri et de filtre
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              display: "none",
            },
            height: "calc(100vh - 600px)",
          }}
        />
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: 28,
              right: { xs: "2rem", sm: "2rem", md: "3rem" }, // Position de droite, responsive
              padding: 2, // Padding optionnel
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" }, // Taille de police responsive
              marginRight: { xs: 1, sm: 2, md: 4 }, // Marge droite responsive
              marginBottom: { xs: 1, sm: 1, md: 6.5 }, // Marge basse responsive
            }}
          >
            {t("Page")} {pagination.page}
          </Typography>
        </Box>
      </Grid>

      {/* {selectedSite && (
        <CreateCameraToSite
          open={openCreate}
          handleClose={handleClose}
          onCreateSuccessCB={() => {
            queryClient.invalidateQueries({
              queryKey: ["camerasBySite", sessionId],
            });
            setOpenCreate(false); // Close modal after creation
          }}
          timeZoneId={selectedSite?.time_zone_id ?? 0}
          siteId={selectedSite?.site_id || ""}
          userId={selectedSite?.user_id || ""}
        />
        
      )} */}
         <CreateCameraToSite
          open={openCreate}
          handleClose={handleClose}
          onCreateSuccessCB={() => {
            queryClient.invalidateQueries({
              queryKey: ["camerasBySite", sessionId],
            });
            setOpenCreate(false); // Close modal after creation
          }}
          timeZoneId={selectedSite?.time_zone_id ?? 0}
          siteId={selectedSite?.site_id || ""}
          userId={selectedSite?.user_id || ""}
        />

      <BasicModal
        open={deleteConfirmation.open}
        handleClose={handleCloseConfirmation}
        fullWidth={true} // Ensures the modal takes up the full width of the screen
        title={t("Confirmation de suppression")}
        maxWidth={"sm"}
        actions={
          <>
            {/* Cancel button */}
            <Button onClick={handleCloseConfirmation} color="primary">
              {t("cancel")}
            </Button>

            {/* Confirm delete button */}
            <Button
              onClick={handleConfirmDelete}
              variant="contained"
              color="primary"
            >
              {t("supprimer")}
            </Button>
          </>
        }
      >
        {t("Êtes vous sûr de vouloir supprimer cette Camera ?")}
      </BasicModal>
    </Box>
)}</>);
};

export default SiteDetailsPage;
