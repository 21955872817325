import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the shape of a notification message
type NotificationMessage = {
  message: string;
  severity: "success" | "info" | "warning" | "error";
  showing: boolean;
};

// Define the initial state shape
type InitialState = {
  messages: NotificationMessage[];
};

// Set the initial state
const initialState: InitialState = {
  messages: [],
};

// Create a Redux slice
const notificationSlice = createSlice({
  name: "notification", // Slice name
  initialState, // Initial state
  reducers: {
    // Reducer to add a success notification
    notifySuccess(state, action: PayloadAction<string>) {
      let message: NotificationMessage = {
        message: action.payload,
        severity: "success",
        showing: true,
      };
      state.messages.push({ ...message });
      console.log("msg+",message)
    },

    // Reducer to add an error notification
    notifyError(state, action: PayloadAction<string>) {
      let message: NotificationMessage = {
        message: action.payload,
        severity: "error",
        showing: true,
      };
      state.messages.push({ ...message });
      console.log("msg",message)
    },

    // Reducer to add an info notification
    notifyInfo(state, action: PayloadAction<string>) {
      let message: NotificationMessage = {
        message: action.payload,
        severity: "info",
        showing: true,
      };
      state.messages.push({ ...message });
    },

    // Reducer to add a warning notification
    notifyWarning(state, action: PayloadAction<string>) {
      let message: NotificationMessage = {
        message: action.payload,
        severity: "warning",
        showing: true,
      };
      state.messages.push({ ...message });
    },

    // Reducer to set the notification messages
    setMessages(state, action: PayloadAction<NotificationMessage[]>) {
      state.messages = [...action.payload];
    },

    // Reducer to clear all notification messages
    readMessages(state) {
      state.messages = [];
    },
  },
});

// Export the reducer and actions
export default notificationSlice.reducer;
export const {
  notifySuccess,
  notifyError,
  notifyInfo,
  notifyWarning,
  setMessages,
  readMessages,
} = notificationSlice.actions;
