import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, AppBar, Toolbar, Typography, Button, Grid } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { WifiTethering } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PortableWifiOffIcon from "@mui/icons-material/PortableWifiOff";
import "./CameraListByUser.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  GetUsersListResponse,
  CamerasListResponse,
} from "../../../../types/Responses";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SearchInput from "../../../reusable/inputs/SearchInput";
import {
  DeleteCameraRequest,
  GetCameraListRequest,
} from "../../../../types/Requests";
import {
  deleteCamera,
  getCamerasList,
} from "../../../../services/camera/camera.service";
import {
  notifyError,
  notifySuccess,
} from "../../../../store/reducers/notification.slice";
import CustomPagination from "../../../reusable/tables/CustomPagination";
import BasicModal from "../../../reusable/modals/BasicModal";
import { invalidateSession } from "../../../../store/reducers/user.slice";

interface CameraListByUserProps {
  usersList: GetUsersListResponse | undefined;
  memoizedUsersList: GetUsersListResponse["users"];
}

interface CameraData {
  camera_id: string;
  subscription_id: string;
  mac_address: string;
  serial_number: string;
  camera_model: string;
  status_connected: string;
  camera_name: string;
  time_zone_id: string;
  status_record: number;
  prom: string;
  last_connection_time?: string; // Propriété optionnelle
  last_restart_time?: string;
  user_tag?: string;
}

type FilterData = {
  NumeroDeSerie: string;
  Utilisateur: string;
  prom: string;
};

const CameraListByUser: React.FC<CameraListByUserProps> = ({
  usersList,
  memoizedUsersList,
}) => {
  const { id } = useParams<{ id: string }>();
  console.log("iddd", id);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);

  //initial states
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    data: DeleteCameraRequest | null; // Utiliser le type DeleteCameraRequest ici
  }>({
    open: false,
    data: null, // Initialiser avec null ou un objet par défaut
  });

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  //displayed filter GUI control
  const [filterData, setFilterData] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
  });

  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
  });

  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };

  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };

  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  // Function to check if filters are unequal
  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.NumeroDeSerie !== next.NumeroDeSerie ||
      current.Utilisateur !== next.Utilisateur ||
      current.prom !== next.prom
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  const { data: camerasData, isLoading } = useQuery<CamerasListResponse>({
    queryKey: ["cameras", sessionId, id, searchFilter, pagination],
    queryFn: async () => {
      if (!id) {
        throw new Error("Site ID is undefined");
      }
      const filterApplied: GetCameraListRequest = {
        sessionId,
        user_id: id || "",
        page_number: pagination.page, // this aligns with your API's page numbering
        max_results: pagination.pageSize,
      };
      // Add filters only if they are not empty
      if (searchFilter.NumeroDeSerie !== "") {
        filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
      }
      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }
      if (searchFilter.prom !== "") {
        filterApplied.site_identifier_for_arc_like = searchFilter.prom;
      }
      try {
        const response = await getCamerasList(filterApplied);

        // Vérifiez si la réponse contient une erreur
        if (response.data.errors) {
          const { code, message } = response.data.errors;

          // Si le code d'erreur est -37, traiter comme une session expirée
          if (code === "-37") {
            dispatch(notifyError(t("sessionExpiredMessage")));
            dispatch(invalidateSession());
            return;
          }

          // Autres types d'erreurs peuvent être traités ici
          throw new Error(`Erreur API : ${message}`);
        }
        return response.data; // Retourner les données de l'API si aucune erreur
      } catch (err) {
        console.error("API Request Error:", err);
        throw err;
      }
    },
  });

  // Memoize rows to avoid unnecessary re-renders
  const CamerasByUser = useMemo(() => camerasData?.data || [], [camerasData]);

  // Fonction pour ouvrir la boîte de dialogue de confirmation avec les données nécessaires
  const handleDelete = (event: React.MouseEvent, cameraId: string) => {
    event.stopPropagation();
    const selectedCamera = CamerasByUser.find(
      (camera) => camera.camera_id === cameraId
    );

    if (selectedCamera) {
      // Remplir les données de confirmation avec les informations requises
      setDeleteConfirmation({
        open: true,
        data: {
          subscription_id: selectedCamera.subscription_id || "", // Assurez-vous que cela est bien défini
          camera_identification: cameraId, // Utiliser cameraId pour identification
        },
      });
    }
  };

  //Mutation to delete a camera
  const deleteCameraMutation = useMutation({
    mutationFn: (request: DeleteCameraRequest) => {
      return deleteCamera(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("Camera deleted successfully")));
      queryClient.invalidateQueries({ queryKey: ["cameras"] });
    },
    onError: (error) => {
      dispatch(notifyError(t("Failed to delete the camera")));
      console.error(error);
    },
  });

  // Delete Camera
  const handleConfirmDelete = async () => {
    if (deleteConfirmation.data) {
      // Appel de la mutation pour supprimer la caméra
      deleteCameraMutation.mutate(deleteConfirmation.data);

      // Fermer la boîte de confirmation
      handleCloseConfirmation();
    }
  };

  const handleCloseConfirmation = () => {
    setDeleteConfirmation({ open: false, data: null }); // Réinitialiser l'état
  };

  //Determines the CSS class name to apply to a row based on its index.
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  // Handles a click event on a row in the data grid.
  const handleRowClick = (params: { row: CameraData }): void => {
    const cameraId = params.row?.camera_id;
    if (cameraId) {
      navigate(`/details/${cameraId}/camerasByClient`);
    }
  };

  // Formate une date UTC en date locale avec le fuseau horaire de l'utilisateur
  const formatDate = (dateString: string | null) => {
    if (!dateString) return "-";

    const utcDate = new Date(dateString + " UTC"); // Convertir en UTC
    console.log("e+", utcDate.toLocaleString());
    return utcDate.toLocaleString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  //Determine the rows count
  const totalItems = camerasData?.total ?? 0;
  const totalPages = camerasData?.has_more
    ? pagination.page + 1
    : pagination.page;
  const isLastPage = camerasData?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // Standard case

  // Columns configuration for DataGrid
  const columns: GridColDef[] = [
    {
      field: "serial_number",
      headerName: t("numeroSerie"),
      minWidth: 130,
      flex: 1.3,
      sortable: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("numeroSerie")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.serial_number : ""}
        </span>
      ),
    },
    {
      field: "mac_address",
      headerName: t("adresseMac"),
      minWidth: 135,
      flex: 1.3,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("adresseMac")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.mac_address : ""}
        </span>
      ),
    },
    {
      field: "camera_model",
      headerName: t("modeleCamera"),
      minWidth: 160,
      flex: 1.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("modeleCamera")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.camera_model : ""}
        </span>
      ),
    },
    {
      field: "camera_name",
      headerName: t("nomCamera"),
      minWidth: 140,
      flex: 1.3,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("nomCamera")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.camera_name : ""}
        </span>
      ),
    },
    {
      field: "site_name",
      headerName: t("siteName"),
      minWidth: 150,
      flex: 1.5,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("siteName")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.site_name : ""}
        </span>
      ),
    },
    {
      field: "prom",
      headerName: t("Prom"),
      minWidth: 100,
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("Prom")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.prom : ""}
        </span>
      ),
    },
    {
      field: "user_tag",
      headerName: t("Utilisateur"),
      minWidth: 130,
      flex: 1.3,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {params.row ? params.row.user_tag : ""}
        </span>
      ),
    },
    {
      field: "date_of_creation",
      headerName: t("dateInstallation"),
      minWidth: 150,
      flex: 1.5,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("dateInstallation")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {formatDate(params.row ? params.row.date_of_creation : "")}
        </span>
      ),
    },
    {
      field: "last_Cnx_Tmp",
      headerName: t("lastConnection"),
      minWidth: 140,
      flex: 1.4,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("lastConnection")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {formatDate(params.row ? params.row.last_Cnx_Tmp : "")}
        </span>
      ),
    },
    {
      field: "last_Updated_Time",
      headerName: t("lastUpdated_Time"),
      minWidth: 140,
      flex: 1.4,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("lastUpdated_Time")}
        </span>
      ),
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {formatDate(params.row ? params.row.last_Updated_Time : "")}
        </span>
      ),
    },
    {
      field: "status_connected",
      headerName: t("statutConnecte"),
      minWidth: 60,
      flex: 0.6,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("statutConnecte")}
        </span>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            color:
              params.row?.status_connected === "1"
                ? "rgb(40, 167, 69)"
                : "rgba(255, 0, 0, 0.5)",
            textAlign: "center",
          }}
        >
          {params.row?.status_connected === "1" ? (
            <WifiTethering sx={{ fontSize: 16 }} />
          ) : (
            <PortableWifiOffIcon sx={{ fontSize: 16 }} />
          )}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: t("Actions"),
      flex: 0.8,
      minWidth: 80,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069", fontSize: 13 }}
        >
          {t("Actions")}
        </span>
      ),
      renderCell: (params) =>
        permissions.includes("deleteCamera") && (
          <Button
            variant="outlined"
            sx={{ color: "#5585b5", fontSize: 8, width: 20 }}
            onMouseEnter={() => setHoveredRowId(params.row?.camera_id)}
            onMouseLeave={() => setHoveredRowId(null)}
            onClick={(event) => handleDelete(event, params.row.camera_id)}
          >
            {hoveredRowId === params.row?.camera_id ? (
              t("Delete")
            ) : (
              <DeleteIcon />
            )}
          </Button>
        ),
    },
  ];

  const noCamerasFound = !isLoading && CamerasByUser.length === 0;

  return (
    <Box mt={3} sx={{ height: 400, marginBottom: 10 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 30,
          marginTop: -25,
          width: "calc(100% - 252px)",
          height: 55,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("Liste des caméras")}
          </Typography>
        </Toolbar>
      </AppBar>
      {!noCamerasFound && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 25,
          }}
        >
          <SearchInput
            placeHolder={t("Filtrer par numéro de série")}
            searchLabel={t("Filtrer par numéro de série")}
            fieldName="NumeroDeSerie"
            value={filterData.NumeroDeSerie}
            valueChange={changeFilterData}
            size="small"
            width="85%"
          />
          <SearchInput
            placeHolder={t("Filtrer par PROM")}
            searchLabel={t("Filtrer par PROM")}
            fieldName="prom"
            value={filterData.prom}
            valueChange={changeFilterData}
            size="small"
            width="85%"
          />
          <SearchInput
            placeHolder={t("Filtrer par utilisateur")}
            searchLabel={t("Filtrer par utilisateur")}
            fieldName="Utilisateur"
            value={filterData.Utilisateur}
            valueChange={changeFilterData}
            size="small"
            width="85%"
          />
        </Box>
      )}
      {/* No cameras found message */}
      {noCamerasFound && <Typography>{t("noCamerasFound")}</Typography>}

      {CamerasByUser.length > 0 ? (
        <Grid
          sx={{
            flexGrow: 1,
            marginLeft: "235px",
            width: "85.5%",
            marginBottom: 30,
          }}
        >
          <DataGrid
            rows={CamerasByUser}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.camera_id}
            rowCount={rowCount}
            onRowClick={handleRowClick}
            paginationMode="server"
            slots={{
              pagination: CustomPagination,
            }}
            pageSizeOptions={[5, 10, 15]}
            paginationModel={{
              page: pagination.page - 1,
              pageSize: pagination.pageSize,
            }}
            onPaginationModelChange={(model: GridPaginationModel) => {
              setPagination((prev) => ({
                ...prev,
                page: model.page + 1,
                pageSize: model.pageSize,
              }));
            }}
            getRowClassName={getRowClassName}
            sx={{
              "& .MuiTablePagination-actions button": {
                color: "black",
                width: "90px",
              },
              "& .MuiTablePagination-actions button svg": {
                fontSize: "25px",
              },
              marginBottom: 10,
              height: "calc(100vh - 600px)",
              "& .even-row": {
                backgroundColor: "#ffffff",
              },
              "& .odd-row": {
                backgroundColor: "#eff9ff",
              },
              "& .MuiDataGrid-menuIcon": {
                display: "none",
              },
              "& .MuiDataGrid-sortIcon": {
                display: "none",
              },
            }}
          />
          <Box sx={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                bottom: 28,
                right: { xs: "2rem", sm: "2rem", md: "3rem" },
                padding: 2,
                fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" },
                marginRight: { xs: 1, sm: 2, md: 1 },
                marginBottom: { xs: 1, sm: 1, md: 6.5 },
              }}
            >
              {t("Page")} {pagination.page}
            </Typography>
          </Box>
        </Grid>
      ) : null}

      <BasicModal
        open={deleteConfirmation.open}
        handleClose={handleCloseConfirmation}
        fullWidth={true} // Ensures the modal takes up the full width of the screen
        title={t("Confirmation de suppression")}
        maxWidth={"sm"}
        actions={
          <>
            {/* Cancel button */}
            <Button onClick={handleCloseConfirmation} color="primary">
              {t("cancel")}
            </Button>

            {/* Confirm delete button */}
            <Button
              onClick={handleConfirmDelete}
              variant="contained"
              color="primary"
            >
              {t("supprimer")}
            </Button>
          </>
        }
      >
        {t("Êtes vous sûr de vouloir supprimer cette Camera ?")}
      </BasicModal>
    </Box>
  );
};
export default CameraListByUser;
