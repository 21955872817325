import { setItemInLocalStorage,getItemFromLocalStorage,deleteItemFromLocalStorage } from "./localStorage";

// Accessor key for user session in local storage.
const accessor = "user";
// Accessor key for user permissions in local storage.
const permsAccessor = "perms";

// Checks if a user is currently logged in.
export function isLoggedIn() {
  // Retrieve user from local storage.
  let user = getItemFromLocalStorage(accessor);

  // Return true if user is found in local storage, otherwise return false.
  if (user !== null) {
    return true;
  } else {
    return false;
  }
}

// Logs in a user by storing the provided session in local storage.
export function logIn(session: string) {
  // Check if a non-empty session is provided.
  if (session) {
    // Encode and store the session in local storage.
    const sessionEncoded = window.btoa(session);
    setItemInLocalStorage(accessor, sessionEncoded);
  } else {
    // Log an error if an empty session is provided.
    console.log("Please provide a non-empty session.");
  }
}


// saves the user permissions in local storage.
export function savePerms(permissions: string[]) {
  // Check if a non-empty session is provided.
  if (permissions) {
    const stringifiedPerms = JSON.stringify(permissions);//because local storage can only store string key-value pairs.
    // Encode and store the session in local storage.
    const permsEncoded = window.btoa(stringifiedPerms);
    setItemInLocalStorage(permsAccessor, permsEncoded);
  } else {
    // Log an error if an empty session is provided.
    console.log("Please provide a non-empty premissions.");
  }
}

// Logs out the currently logged-in user by removing their session from local storage.
export function logOut() {
  // Check if a user is currently logged in.
  if (isLoggedIn()) {
    // Delete the user session from local storage.
    deleteItemFromLocalStorage(accessor);
  } else {
    // Log a message if no user is logged in.
    console.log("Not logged in.");
  }
}

// Retrieves and decodes the user session from local storage.
export function getUserSession() {
  // Check if a user is currently logged in.
  if (isLoggedIn()) {
    // Retrieve and decode the user session from local storage.
    const sessionEncoded = getItemFromLocalStorage(accessor);
    const sessionDecoded = window.atob(sessionEncoded as string);
    return sessionDecoded;
  } else {
    // Return a message indicating that no user is logged in.
    return "Not logged in";
  }
}

// Retrieves and decodes the user permissions from local storage.
export function getUserPermissions() {
  // Check if a user is currently logged in.
  if (isLoggedIn()) {
    // Retrieve and decode the user session from local storage.
    const permsEncoded = getItemFromLocalStorage(permsAccessor);
    if(permsEncoded && typeof permsEncoded === 'string') {
      const permsDecoded = window.atob(permsEncoded as string);
      const parsedPerms = JSON.parse(permsDecoded) as string[]
      return parsedPerms;
    }else{
      return []
    }
  } else {
    // Return a message indicating that no user is logged in.
    return [];
  }
}
