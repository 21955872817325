// src/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json' // Fichier de traduction en anglais
import translationFR from './locales/fr.json'; // Fichier de traduction en français
import translationPT from './locales/pt.json'; // Fichier de traduction en portugais

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  pt: {
    translation: translationPT,
  },
};

i18n
  .use(initReactI18next) // Initialise i18next
  .init({
    resources,
    lng: 'fr', // Langue par défaut
    interpolation: {
      escapeValue: false, // Ne pas échapper les valeurs interprétées par i18next
    },
  });

  console.log('translationEN:', translationEN); 
export default i18n;
