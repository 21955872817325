import { css, SerializedStyles } from "@emotion/react";
import { Color } from "../../../constants/color";

/**
 * Type definition for styles related to tabs.
 */
type TabsStyles = {
  tabsContainer: SerializedStyles;
  tabPanel: SerializedStyles;
  tabTitle: SerializedStyles;
  tabContent: SerializedStyles;
};

/**
 * Styles for tabs.
 */
const tabsStyle: TabsStyles = {
  tabsContainer: css({
    "& .Mui-selected": {
      color: Color.TextPrimary + " !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: Color.TextPrimary + " !important",
    },
  }),
  tabTitle: css({}),
  tabPanel: css({
    height: "100%",
  
  }),
  tabContent: css({
    "& .MuiBox-root": {
      height: "calc(100% - 48px)",
   
    },
  }),
};

export default tabsStyle;
