import React, { useEffect, useMemo, useState } from "react";
import { Box, AppBar, Toolbar, Button, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getUsersList } from "../../../services/user/user.service";
import { GetUsersListResponse } from "../../../types/Responses";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import SearchInput from "../../reusable/inputs/SearchInput";
import { GetUsersListRequest } from "../../../types/Requests";
import CustomPagination from "../../reusable/tables/CustomPagination";
import { logOut } from "../../../utils/auth";
import { notifyError } from "../../../store/reducers/notification.slice";
import AddIcon from "@mui/icons-material/Add";
import { invalidateSession } from "../../../store/reducers/user.slice";
import CreateClient from "../CreateClient";

// Define the interface for the Client object
interface Client {
  id: string;
  firstName: string | null;
  lastName: string | null;
  city: string | null;
  country: string | null;
  CompanyName: string | null;
  email: string | null;
  nbSite: number;
  nbCameras: number;
  nbMaveric: number;
  userTag: string;
}

type FilterData = {
  Utilisateur: string;
  email: string;
};

const MesClientsPage: React.FC = () => {
  console.log("MesClientsPage Component Rendered");
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);
  const [sortBy, setSortBy] = useState<string>("nbCameras"); 
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | undefined | null>("asc");


  // Open the create site dialog
  const handleClickOpen = (): void => {
    setOpenCreate(true);
  };

  // Close the create site dialog
  const handleCloseCreate = (): void => {
    setOpenCreate(false);
  };

  // local state for pagination
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  // useEffect pour récupérer la taille de page depuis localStorage
  useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPagination((prev) => ({ ...prev, pageSize: Number(storedPageSize) }));
    }
  }, []);

  //displayed filter GUI (Graphic User Interface) control
  const [filterData, setFilterData] = useState<FilterData>({
    Utilisateur: "",
    email: "",
  });

  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    Utilisateur: "",
    email: "",
  });

  //GUI filter change
  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };

  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };
  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  // Function to check if filters are unequal
  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.Utilisateur !== next.Utilisateur || current.email !== next.email
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });

      // Reset pagination to the first page when filters change
      setPagination((prev) => ({ ...prev, page: 1 }));

      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  const { data, isLoading } = useQuery<GetUsersListResponse>({
    queryKey: ["users", sessionId, searchFilter, sortBy, sortOrder,  pagination],
    queryFn: async () => {
      const filterApplied: GetUsersListRequest = {
        sessionId,
        page_number: pagination.page, // Aligné avec la numérotation des pages de l'API
        max_results: pagination.pageSize,
        sortBy: sortBy, 
        order: sortOrder ?? "asc",
      };

      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }

      if (searchFilter.email !== "") {
        filterApplied.email_like = searchFilter.email;
      }

      console.log("Request Parametersss:", filterApplied);

      try {
        const response = await getUsersList(filterApplied);

        // Vérifiez si la réponse indique que la session est requise
        if (response.data.error === "session required") {
          // Déclencher une notification ou un message d'erreur
          dispatch(notifyError(t("sessionExpiredMessage")));
          dispatch(invalidateSession());
          return;
        }

        return response.data;
      } catch (err) {
        console.error("API Request Error:", err);
        throw err;
      }
    },
  });

  // Memoize the users list to avoid recalculations on each render
  const users = useMemo(() => data?.users || [], [data]);

  // Handle row click event to navigate to the client details page
  const handleRowClick = (clientId: string): void => {
    navigate(`/details/${clientId}`);
  };

   
  // Handle sorting
   
 
  // const handleSortModelChange = (newSortModel: GridSortModel) => {
  //   // Vérifier si le modèle de tri contient des éléments
  //   if (newSortModel.length > 0) {
  //     const { field, sort } = newSortModel[0]; // Obtenir le champ trié et l'ordre
  
  //     // Convertir la valeur de 'sort' en "ASC" ou "DESC"
  //     const normalizedSort: "asc" | "desc" | null =
  //       sort && (sort.toLowerCase() === "asc" || sort.toLowerCase() === "desc")
  //         ? (sort.toLowerCase() as "asc" | "desc")
  //         : null;
  
  //     setSortBy(field); // Mettre à jour le champ trié
  //     setSortOrder(normalizedSort); // Mettre à jour l'ordre de tri
  //   } else {
  //     // Réinitialiser à des valeurs par défaut si aucun tri n'est appliqué
  //     setSortBy("nbCameras"); // Remplacer par une valeur par défaut
  //     setSortOrder("asc"); // Ordre ascendant par défaut
  //   }
  // };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    // Vérifier si le modèle de tri contient des éléments
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0]; // Obtenir le champ trié et l'ordre
  
      // Convertir la valeur de 'sort' en "ASC" ou "DESC"
      const normalizedSort: "asc" | "desc" | null =
        sort && (sort.toLowerCase() === "asc" || sort.toLowerCase() === "desc")
          ? (sort.toLowerCase() as "asc" | "desc")
          : "asc"; // Utiliser "asc" comme valeur par défaut si aucune direction n'est spécifiée
  
      setSortBy(field); // Mettre à jour le champ trié
      setSortOrder(normalizedSort); // Mettre à jour l'ordre de tri
    } else {
      // Si aucun tri n'est sélectionné, réinitialiser à la colonne précédemment triée
      setSortBy(prevSortBy => prevSortBy || "nbCameras"); // Reprendre la dernière colonne triée ou une valeur par défaut
      setSortOrder("asc"); // Réinitialiser l'ordre de tri à ascendant
    }
  };
  
  
    
    
    

  // Define columns for the DataGrid component
  const columns: GridColDef[] = [
    /*{
      field: "firstName",
      headerName: t("firstName"), // Using t("key") to translate dynamically
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold" }}>{t("firstName")}</span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.firstName}</span>
        </div>
      ),
    },
    {
      field: "lastName",
      headerName: t("lastName"),
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold" }}>{t("lastName")}</span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.lastName}</span>
        </div>
      ),
    },
    {
      field: "city",
      headerName: t("city"),
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold" }}>{t("city")}</span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.city}</span>
        </div>
      ),
    },
    {
      field: "country",
      headerName: t("country"),
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold" }}>{t("country")}</span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.country}</span>
        </div>
      ),
    },*/
    {
      field: "userTag",
      headerName: "Utilisateur",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable:true,
    
      maxWidth: 450,
      flex: 4,
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.userTag}</span>
        </div>
      ),
    },
    {
      field: "email",
      headerName: t("email"),
      sortable: true,
      maxWidth: 450,
      flex: 4,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("email")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.email}</span>
        </div>
      ),
    },
    {
      field: "nbSite",
      headerName: t("nbSite"),
      sortable:true,
      
      maxWidth: 450,
      flex: 4,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("nbSite")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.nbSite}</span>
        </div>
      ),
    },
    {
      field: "nbCameras",
      headerName: t("nbCameras"),
      maxWidth: 450,
      flex: 4,
      sortable: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("nbCameras")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.nbCameras}</span>
        </div>
      ),
    },
  ];

  // Function to determine the CSS class for a row based on its index
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  // Calculate the row count
  const totalItems = users?.length ?? 0;
  const totalPages = data?.has_more ? pagination.page + 1 : pagination.page;
  const isLastPage = data?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // // Standard calculation for pages with full items

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 29.5,
          marginTop: 12,
          width: "calc(100% - 252px)",
          height: 55,
          borderRadius: "4px",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("clients")}
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            sx={{
              marginRight: "10px",
              backgroundColor: "#9ad9dd",
              color: "#6b7c93",
              fontSize: "12px",
            }}
          >
            <AddIcon
              sx={{ height: 15, color: "#555555", marginRight: "5px" }}
            />
            {t("addClient")} 
          </Button>*/}
          {permissions.includes("CustAdd") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                ml: "auto",
                margin: { xs: "4px", sm: "8px", md: "12px", lg: "16px" }, // Responsive margins
                position: "relative",
                top: "-5px",
                backgroundColor: "rgb(224, 247, 250)",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                "&:hover": {
                  backgroundColor: "#3498DB",
                  color: "white",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                "&:focus": {
                  backgroundColor: "#3498DB",
                  color: "#393e46",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                color: "#393e46",
                fontSize: { xs: "8px", sm: "10px", md: "12px" }, // Responsive font size
                whiteSpace: "nowrap",
                width: { xs: "auto", sm: "auto" }, // Responsive width
                maxWidth: { xs: "200px", sm: "250px" }, // Max width for larger screens
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Ensure icon color changes with text color on hover
                "& .MuiSvgIcon-root": {
                  color: "inherit",
                },
                // Ensure icon color changes with text color on focus
                "& .MuiSvgIcon-root:focus": {
                  color: "inherit",
                },
              }}
            >
              <AddIcon
                sx={{
                  height: { xs: 12, sm: 15 },
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
              />
              {t("addClient")}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          width="30%"
        />
        <SearchInput
          placeHolder={t("Filtrer par email")}
          searchLabel={t("Filtrer par email")}
          fieldName="email"
          value={filterData.email}
          valueChange={changeFilterData}
          size="small"
          width="30%"
        />
      </Box>
      <Box sx={{ flexGrow: 1, marginTop: 2, width: "98.5%" }}>
        <DataGrid
          rows={users}
          columns={columns}
          rowCount={rowCount}
          getRowId={(row) => row.id}
          loading={isLoading}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
            // Enregistrer la taille de la page dans localStorage
            localStorage.setItem("pageSize", model.pageSize.toString());
          }}
          getRowClassName={getRowClassName}
          onRowClick={(params) => handleRowClick(params.row.id)}
          onSortModelChange={handleSortModelChange} 
          className="custom-scrollbar"
          sx={{
            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            marginLeft: 29.5, // Adjust margin or use responsive values
            height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 300px)" }, // Adjust height based on screen size
            "& .even-row": { backgroundColor: "#ffffff" },
            "& .odd-row": { backgroundColor: "#eff9ff" },
            // Masquer les ellipses de tri et de filtre
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            // "& .MuiDataGrid-sortIcon": {
            //   display: "none",
            // },
          }}
        />
        <Box sx={{ position: "relative" }}></Box>
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            bottom: 0,
            right: { xs: "calc(2rem)", sm: "calc(2rem)", md: "calc(3rem)" }, // Further adjusted right position to move left
            padding: 2, // Optional padding for spacing
            fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" }, // Responsive font size
            marginRight: { xs: 1, sm: 2, md: 4 }, // Responsive margin
            marginBottom: { xs: 1, sm: 1, md: 7.5 }, // Responsive margin
          }}
        >
          {t("Page")} {pagination.page}
        </Typography>
      </Box>
      <CreateClient
        open={openCreate}
        handleClose={handleCloseCreate}
        onCreateSuccessCB={() => {
          queryClient.invalidateQueries({ queryKey: ["users", sessionId] });
          setOpenCreate(false); // Close modal after creation
        }}
      />
    </Box>
  );
};

export default MesClientsPage;
