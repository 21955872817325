import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  TableContainer,
  FormControl,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";

import ProfilesPage from "./ProfilesPage";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteTeamMember,
  teamList,
  teamRoles,
  updateTeamMemberRole,
} from "../../../services/auth/auth.service";
import {
  invalidateSession,
  setRefetchTeamMembers,
  setTeam,
} from "../../../store/reducers/user.slice";
import { TeamMember } from "../../../types/Responses";
import HorizontalCard from "../../reusable/cards/HorizontalCard";
import {
  DeleteTeamMemberRequest,
  UpdateTeamMemberRoleRequest,
} from "../../../types/Requests";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicTabs, { TabPanel } from "../../reusable/tables/BasicTabs";
import tabsStyle from "../../../styles/components/tabs/tabs.style";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import BasicModal from "../../reusable/modals/BasicModal";
import { useNavigate } from "react-router-dom";

interface Role {
  id: number;
  name: string;
  functions: any[]; // Assurez-vous de mettre le type correct pour functions si nécessaire
}
const tabElements = tabsStyle;
const EquipePage: React.FC = () => {
  // States for dialogs and selected member/role
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [tabValue, setTabValue] = useState(0);
  //Hooks
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Getting session ID ,team data and permissions from Redux store
  const allRoles = useAppSelector((state) => state.user.allRoles);
  const permissions = useAppSelector((state) => state.user.permissions);
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const myTeam = useAppSelector((state) => state.user.team);
  const refetchTeamMembers = useAppSelector((state) => state.user.refetchTeam);

  // Determine if the user has permission to remove team members
  const canRemoveMember = permissions.includes("removeMember");
  const canUpdateRole = permissions.includes("RoleUpdate");

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Handle role change in Autocomplete
  const handleRoleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: { key: string; label: string; value: string } | null
  ) => {
    setSelectedRole(value);
  };

  // Open delete confirmation dialog
  const handleOpenDeleteDialog = (member: any) => {
    setSelectedMember(member); // Mettre à jour les détails du membre sélectionné
    setOpenDeleteDialog(true); // Ouvrir le dialogue de confirmation de suppression
  };

  // Close delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false); // Fermer le dialogue sans suppression
  };

  // Close role management dialog
  const handleCloseRoleDialog = () => {
    setOpenRoleDialog(false);
  };

  // Open role management dialog
  const handleManageAccessClick = (member: any) => {
    setOpenRoleDialog(true);
    setSelectedMember(member);
    setSelectedRole(member.role ? member.role : null); // Assurez-vous que selectedRole est correctement initialisé
    console.log("Selected Role in handleManageAccessClick:", member.role);
  };

  // Mutation for updating team member role
  const updateMemberRoleMutation = useMutation({
    mutationFn: (request: UpdateTeamMemberRoleRequest) => {
      return updateTeamMemberRole(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("team member role changed successfully")));
      refetchTeamMembers();
    },
    onError: (error: AxiosError) => {
      dispatch(notifyError(t("failed to update user role")));
      if (
        error.hasOwnProperty("response") &&
        error.response!.hasOwnProperty("status")
      ) {
        if (error.response!.status === 401) {
          dispatch(invalidateSession());
        }
      }
    },
  });

  // Confirm role update
  const handleUpdateConfirmClick = () => {
    if (selectedRole !== null && selectedRole.value) {
      const desiredRole = allRoles.find(
        (role) => role.name === selectedRole.value
      )?.id;
      console.log("Selected Role Name:", selectedRole.value);
      if (desiredRole !== undefined) {
        const request: UpdateTeamMemberRoleRequest = {
          role_id: desiredRole,
          usercompany_id: selectedMember.id,
        };
        console.log("desiredR", desiredRole);
        updateMemberRoleMutation.mutate(request);
      }
      setOpenRoleDialog(false);
    }
  };

  //Fetch team data using React Query
  const teamQuery = useQuery({
    queryKey: ["installerTeam", sessionId],
    queryFn: () => {
      if (sessionId) {
        return teamList().then((res) => {
          // Vérifiez si la réponse indique que la session est requise
          if (res.data.error === "Session Key Required for this function") {
            dispatch(notifyError(t("sessionExpiredMessage")));
            dispatch(invalidateSession());
          }
          return res.data; // Retourner les données de l'équipe
        });
      }
      return null; // Si sessionId est nul, retourner null
    },
  });

  // const teamQuery = useQuery({
  //   queryKey: ["installerTeam", sessionId],
  //   queryFn: () => {
  //     if (sessionId) {
  //       return teamList().then((res) => res.data);
  //     }
  //   },
  // });

  // Update Redux store with team data on query success
  useEffect(() => {
    if (teamQuery.isSuccess) {
      dispatch(setRefetchTeamMembers(teamQuery.refetch));
      if (
        teamQuery.data &&
        teamQuery.data.hasOwnProperty("data") &&
        teamQuery.data.data.length > 0
      ) {
        const team: TeamMember[] = [...teamQuery.data.data];
        dispatch(setTeam(team));
      }
    }
  }, [teamQuery.isSuccess, teamQuery.data, dispatch]);

  // Fetch roles data using React Query
  // const rolesQuery = useQuery({
  //   queryKey: ["teamRoles", sessionId],
  //   queryFn: () => {
  //     if (sessionId) {
  //       return teamRoles().then((res) => res.data);
  //     }
  //   },
  // });

  // Fetch roles data using React Query
const rolesQuery = useQuery({
  queryKey: ["teamRoles", sessionId],
  queryFn: () => {
    if (sessionId) {
      return teamRoles().then((res) => {
        // Vérifiez si la réponse indique que la session est requise
        if (res.data.error === "Session Key Required for this function") {
          dispatch(notifyError(t("sessionExpiredMessage")));
          dispatch(invalidateSession());
          // Si vous ne voulez rien retourner dans ce cas, vous pouvez simplement ne pas mettre de return ici.
        }
        return res.data; // Retourner les données de l'équipe
      });
    }
  },
});


  // Memorize the roles list
  const rolesList: Role[] = useMemo(() => {
    return rolesQuery.data?.data?.roles || [];
  }, [rolesQuery.data]);
  console.log("roles", rolesList);

  // Update selected role on member selection
  useEffect(() => {
    if (selectedMember !== null) {
      let desiredRole = allRoles.find(
        (role) => role.id === selectedMember.role
      );
      if (desiredRole !== undefined) {
        setSelectedRole(desiredRole.name);
      }
    }
  }, [selectedMember]);

  // Mutation for deleting team member
  const deleteMemberMutation = useMutation({
    mutationFn: (request: DeleteTeamMemberRequest) => {
      return deleteTeamMember(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("team member deleted successfully")));
      queryClient.invalidateQueries({ queryKey: ["installerTeam"] });
    },
    onError: (error: AxiosError) => {
      dispatch(notifyError(t("failed to delete team member")));
      if (
        error.hasOwnProperty("response") &&
        error.response!.hasOwnProperty("status")
      ) {
        if (error.response!.status === 401) {
          dispatch(invalidateSession());
        }
      }
    },
  });

  //handle delete
  const handleDeleteConfirmClick = () => {
    const request: DeleteTeamMemberRequest = {
      usercompany_id: selectedMember.id,
      sessionId: sessionId,
    };
    deleteMemberMutation.mutate(request);
    setOpenDeleteDialog(false); // Fermer le dialogue après confirmation de la suppression
  };

  return (
    <Box p={3}>
      {/* Tabs */}
      <Box
        css={tabElements.tabsContainer}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      ></Box>
      <BasicTabs
        tabs={[
          { key: 0, label: t("Mon Équipe"), value: 0 },
          ...(permissions.includes("seeRoles")
            ? [{ key: 1, label: t("Roles"), value: 1 }]
            : []),
        ].filter(Boolean)} // Filter out falsy values
        value={tabValue}
        handleChange={handleTabChange}
        valueAccessor="value"
        labelAccessor="label"
        keyAccessor="key"
        fullWidth={true}
      />
      {permissions.includes("seeOtherTeamMembers") && (
        // Tab Panels
        <TabPanel value={tabValue} index={0}>
          <br />
          {/* Mon équipe content */}
          <Box
            p={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            css={{
              width: "80%",
              maxWidth: 1200,
              marginLeft: "200px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                overflowX: "hidden",
                width: "50%",
                marginLeft: 25,
                backgroundColor: "#e4f1fe",
              }}
            >
              {myTeam.map((teamMember, index) => (
                <HorizontalCard
                  key={index}
                  image={`${teamMember.firstName} ${teamMember.lastName}`}
                  description={teamMember.email}
                  title={`${teamMember.firstName} ${teamMember.lastName}`}
                  note={teamMember.role || ""}
                  imageIsName={true}
                  actions={
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleManageAccessClick(teamMember)}
                        disabled={!canUpdateRole}
                        sx={{
                          width: 139.5,
                          backgroundColor: !canUpdateRole
                            ? "#4f4f4f"
                            : "primary",
                          color: !canUpdateRole ? "#b0b0b0" : "white",
                          "&:hover": {
                            backgroundColor: !canUpdateRole
                              ? "#4f4f4f"
                              : "#1565C0",
                          },
                        }}
                      >
                        {t("Manage Role")}
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: 139.5,
                          backgroundColor: !canRemoveMember
                            ? "#4f4f4f"
                            : "secondary.main",
                          color: !canRemoveMember ? "#b0b0b0" : "white",
                          "&:hover": {
                            backgroundColor: !canRemoveMember
                              ? "#4f4f4f"
                              : "secondary.dark",
                          },
                        }}
                        onClick={() => handleOpenDeleteDialog(teamMember)}
                        disabled={!canRemoveMember}
                      >
                        {t("Delete")}
                      </Button>
                    </>
                  }
                  actionsWidth={325}
                  colorIndex={-1}
                />
              ))}
            </TableContainer>
          </Box>
        </TabPanel>
      )}
      {permissions.includes("seeRoles") && (
        <TabPanel value={tabValue} index={1}>
          {/* Profil content */}
          <ProfilesPage />
        </TabPanel>
      )}

      <BasicModal
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        maxWidth="sm"
        fullWidth
        title={t("Confirmation de suppression")}
        actions={
          <>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleDeleteConfirmClick}
              color="primary"
              variant="contained"
            >
              {t("supprimer")}
            </Button>
          </>
        }
      >
        <Typography variant="body1">
          {t("Are you sure you want to delete")}{" "}
          {selectedMember &&
            `${selectedMember.firstName} ${selectedMember.lastName}`}{" "}
          ?
        </Typography>
      </BasicModal>

    {/*manage role*/}
      <BasicModal
        open={openRoleDialog}
        handleClose={handleCloseRoleDialog}
        maxWidth="sm"
        fullWidth
        title={t("Manage role")}
        actions={
          <>
            <Button onClick={handleCloseRoleDialog} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpdateConfirmClick}
            >
              {t("save")}
            </Button>
          </>
        }
      >
        <Typography variant="body1" gutterBottom>
          {t("Gérer le rôle pour")}{" "}
          {selectedMember &&
            `${selectedMember.firstName} ${selectedMember.lastName}`}
        </Typography>
        <FormControl fullWidth margin="normal">
          <Autocomplete
            options={allRoles.map((role) => ({
              key: role.id,
              label: role.name,
              value: role.name,
            }))}
            value={selectedRole}
            onChange={handleRoleChange}
            renderOption={(props, option) => (
              <li {...props}>
                <Chip
                  label={option.label}
                  style={{
                    backgroundColor: "#e4f1fe",
                    color: "#303841",
                  }}
                />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Rôle"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </FormControl>
      </BasicModal>
    </Box>
  );
};

export default EquipePage;
