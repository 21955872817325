import React from 'react';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import UpcomingIcon from '@mui/icons-material/Upcoming';
import MesClientsPage from '../components/pageComponents/MesClientsPage'; 
import MesSitesPage from '../components/pageComponents/MesSitesPage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MesCamerasPage from '../components/pageComponents/MesCamerasPage';
import ListeAlarmesPage from '../components/pageComponents/ListeAlarmesPage';
import SessionsOperators from '../components/pageComponents/SessionsOperators';
import StorageIcon from '@mui/icons-material/Storage';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ClientDetailsPage from '../components/pageComponents/ClientDetailsPage/ClientDetailsPage';
import CameraDetailsPage from '../components/pageComponents/CameraDetailsPage';
import PersonIcon from '@mui/icons-material/Person';
import ProfilPage from '../components/pageComponents/ComptePage/ProfilPage';
import PasswordPage from '../components/pageComponents/ComptePage/PasswordPage';
import EquipePage from '../components/pageComponents/EquipePage/EquipePage';
import GroupsIcon from '@mui/icons-material/Groups';
import ProfilesPage from '../components/pageComponents/EquipePage/ProfilesPage';
import SiteDetailsPage from '../components/pageComponents/SiteDetailsPage';
import TotalSessionOperators from '../components/pageComponents/SessionsOperators/TotalSessionOperators';
import ResetPassword from '../components/pageComponents/ResetPwPage/ResetPasswordContent';


export interface Path {
  name: string;
  path: string;
  icon: React.ReactElement;
  component: React.FC;
  navVisible: boolean;
  selectedRoute: string;
}

const paths: Path[] = [
  {
    name: "Mes Clients",
    path: "/mes_clients",
    icon: <PeopleAltIcon />, 
    component: MesClientsPage, 
    navVisible: true,
    selectedRoute: "/mes_clients",
  },
  {
    name: "Mes Sites", 
    path: "/site", 
    icon: <AccountTreeIcon/>,
    component: MesSitesPage,
    navVisible: true,
    selectedRoute: "/site", 
  },
  {
    name: "Mes Caméras", 
    path: "/cameras", 
    icon: <CameraAltIcon />,
    component: MesCamerasPage, 
    navVisible: true,
    selectedRoute: "/cameras", 
  },
  {
    name: "Liste des alarmes", 
    path: "/listalarms", 
    icon: <UpcomingIcon/>,
    component: ListeAlarmesPage, 
    navVisible: true,
    selectedRoute: "/listalarms", 
  },
  {
    name: "Sessions opérateurs", 
    path: "/records", 
    icon: <StorageIcon/>,
    component:TotalSessionOperators, 
    navVisible: true,
    selectedRoute: "/records", 
  },
  {
    name: "Mon profil",
    path: "/mon_profil",
    icon: <PersonIcon />,
    component: ProfilPage,
    navVisible: true,
    selectedRoute: "/mon_profil",
  },
  {
    name: "Mot de passe",
    path: "/mot_de_passe",
    icon: <PersonIcon />,
    component: PasswordPage,
    navVisible: false,
    selectedRoute: "/mot_de_passe",
  },
  {
    name: "Mon équipe",
    path: "/mon_equipe",
    icon: <GroupsIcon />,
    component: EquipePage,
    navVisible: true,
    selectedRoute: "/mon_equipe",
  },
  {
    name: "Profil",
    path: "/profil",
    icon: <GroupsIcon />,
    component: ProfilesPage,
    navVisible: false,
    selectedRoute: "/profil",
  },
  {
    name: "Sessions opérateurs", 
    path: "/records/:id", 
    icon: <StorageIcon/>,
    component:SessionsOperators, 
    navVisible: false,
    selectedRoute: "/records/:id", 
  },
  
  {
    name: "Détails du client",
    path: "/details/:id",
    icon: <CameraAltIcon />,
    component: ClientDetailsPage,
    navVisible: false,
    selectedRoute: "/mes_clients",
  },
  {
    name: "Détails caméras du client",
    path: "/details/:id/camerasByClient",
    icon: <CameraAltIcon />,
    component: CameraDetailsPage,
    navVisible: false,
    selectedRoute: "/mes_clients",
  },
  {
    name: "Détails de caméra",
    path: "/camera/single/details/:id",
    icon: <CameraAltIcon />,
    component: CameraDetailsPage,
    navVisible: false,
    selectedRoute: "/cameras",
  },
  {
    name: "Détails de site",
    path: "/details_site/:id",
    icon: <AccountTreeIcon/>,
    component: SiteDetailsPage,
    navVisible: false,
    selectedRoute: "/site",
  },
  {
    name: "Détails caméras de site",
    path: "/details_site/:id/camerasBySite ",
    icon: <AccountTreeIcon/>,
    component: CameraDetailsPage,
    navVisible: false,
    selectedRoute: "/site",
  },
  {
    name: "Reinitialiser mot de passe",
    path: "/reset",
    icon: <AccountTreeIcon/>,
    component: ResetPassword ,
    navVisible: false,
    selectedRoute: "/reset",
  },

];

export default paths;

