import React, { useEffect, useState } from "react";
import { SiteListResponse } from "../../../../types/Responses";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../store/hooks";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  Button,
  LinearProgress,
} from "@mui/material";
import EditSite from "../../EditSite";

interface SiteInfoSectionProps {
  sites: SiteListResponse["data"]; // Un tableau de sites
  selectedSite?: SiteListResponse["data"][number];
}

const SiteInfoSection: React.FC<SiteInfoSectionProps> = ({
  sites,
  selectedSite,
}) => {
  // Hooks
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);

  // State for managing the create/edit dialog
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  // State to control the open/close status of the edit dialog
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: {
      site_name: "",
      siteIdentifierForArc: "",
      timeZoneId: 0,
      text: "",
      address: "",
    },
  });

  useEffect(() => {
    if (selectedSite) {
      setTimeout(() => setLoading(false), 500);
    }
  }, [selectedSite]);

  // Function to open the edit dialog
  const handleClickOpen = (): void => {
    setEditDialog({
      open: true,
      data: {
        site_name: selectedSite?.site_name || "",
        siteIdentifierForArc: selectedSite?.siteIdentifierForArc || "",
        timeZoneId: selectedSite?.time_zone_id ?? 0,
        text: selectedSite?.site_customtext || "",
        address: selectedSite?.site_adresse || "",
      },
    });
    setOpenCreate(true);
  };

  // Function to close the create/edit dialog
  const handleCloseCreate = (): void => {
    setEditDialog({
      open: false, // Set open to false to close the dialog
      data: {
        site_name: "",
        siteIdentifierForArc: "",
        timeZoneId: 0,
        text: "",
        address: "",
      },
    });
    setOpenCreate(false); 
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 30,
          marginTop: 12,
          width: "calc(100% - 262px)",
          height: 55,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("Site Details")}
          </Typography>
          {permissions.includes("SiteEdit") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                ml: "auto",
                margin: { xs: "4px", sm: "8px", md: "12px", lg: "16px" }, 
                position: "relative",
                top: "-5px",
                backgroundColor: "rgb(224, 247, 250)",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                "&:hover": {
                  backgroundColor: "#3498DB",
                  color: "white",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                "&:focus": {
                  backgroundColor: "#3498DB",
                  color: "#393e46",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                color: "#393e46",
                fontSize: { xs: "8px", sm: "10px", md: "12px" }, 
                whiteSpace: "nowrap",
                width: { xs: "auto", sm: "auto" }, 
                maxWidth: { xs: "200px", sm: "250px" }, // Max width for larger screens
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Ensure icon color changes with text color on hover
                "& .MuiSvgIcon-root": {
                  color: "inherit",
                },
                // Ensure icon color changes with text color on focus
                "& .MuiSvgIcon-root:focus": {
                  color: "inherit",
                },
              }}
            >
              {t("Edit Site")}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <LinearProgress
            sx={{
              height: 10,
              borderRadius: 1,
              width: "60%",
              backgroundColor: "#34495e",
            }}
          />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            width: "calc(100% - 262px)",
            marginLeft: 30,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {" "}
                  <strong>{t("Nom de site")}:</strong>
                </TableCell>
                <TableCell>{selectedSite?.site_name || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {" "}
                  <strong>{t("Prom")}:</strong>
                </TableCell>
                <TableCell>
                  {selectedSite?.siteIdentifierForArc || ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("imprimer")}:</strong>
                </TableCell>
                <TableCell>{selectedSite?.time_zone_name || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("Texte Personnalise")}:</strong>
                </TableCell>
                <TableCell>{selectedSite?.site_customtext || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("adressSite")}:</strong>
                </TableCell>
                <TableCell>{selectedSite?.site_adresse || ""}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <EditSite
        open={editDialog.open}
        handleClose={handleCloseCreate}
        onCreateSuccessCB={() => {
          queryClient.invalidateQueries({
            queryKey: ["sites", sessionId, selectedSite?.site_id],
          });
          setOpenCreate(false);
        }}
        siteData={editDialog.data}
        sessionId={sessionId}
        siteId={selectedSite?.site_id || ""}
        timeZoneId={selectedSite?.time_zone_id ?? 0}
      />
    </Box>
  );
};

export default SiteInfoSection;
