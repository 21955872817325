import React, { useState, useMemo, useEffect } from "react";
import { Box, Typography, AppBar, Toolbar, responsiveFontSizes } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import SearchInput from "../../reusable/inputs/SearchInput";
import { useTranslation } from "react-i18next";
import LongMenu from "../../common/LongMenu";
import { useNavigate } from "react-router-dom";
import AlarmVideoClip from "./AlarmVideoClip";
import {
  getAlarmsList,
  getVideoAlarm,
} from "../../../services/alarm/alarm.service";
import { useQuery } from "@tanstack/react-query";
import { AlarmsListResponse, VideoUrlResponse } from "../../../types/Responses";
import SelectInput from "../../reusable/inputs/SelectInput";
import { GetAlarmListRequest } from "../../../types/Requests";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import CustomPagination from "../../reusable/tables/CustomPagination";
import "./ListeAlarmes.css";
import { notifyError } from "../../../store/reducers/notification.slice";
import { invalidateSession } from "../../../store/reducers/user.slice";

interface AlarmData {
  id_alarm: string;
  site_name: string;
  site_identifier_for_arc: string;
  start_date: number;
  end_date: number;
  camera_id: string;
  date_of_creation: string;
  user_Tag: string;
  serial_number: string;
}

type FilterData = {
  NumeroDeSerie: string;
  Utilisateur: string;
  prom: string;
  alarmId: string;
  startDate: string; 
};

const ListeAlarmesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.user.sessionId);

  // Initial states for date filter, dialog visibility, selected video URL, and selected alarm ID
  const [dateFilter, setDateFilter] = useState("all");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [selectedAlarmId, setSelectedAlarmId] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });
  const [sortBy, setSortBy] = useState<string>("prom"); 
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined | null>("ASC");


  
  const dateFilterOptions = [
    { key: 0, label: t("Filtrer par date"), value: "all" },
    { key: 1, label: t("Les dernières 48h"), value: "last48" },
    { key: 2, label: t("Les dernières 24h"), value: "last24" },
    { key: 3, label: t("La dernière heure"), value: "lastHour" },
];

  //displayed filter GUI (Graphic User Interface) control
  const [filterData, setFilterData] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
    alarmId: "",
    startDate: "",
  });

  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
    alarmId: "",
    startDate: ""
  });

  

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    // Vérifier si le modèle de tri contient des éléments
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0]; // Obtenir le champ trié et l'ordre
  
      // Convertir la valeur de 'sort' en "ASC" ou "DESC"
      const normalizedSort: "ASC" | "DESC" | null = sort ? (sort.toUpperCase() as "ASC" | "DESC") : null;
  
      setSortBy(field); // Mettre à jour le champ trié
      setSortOrder(normalizedSort); // Mettre à jour l'ordre de tri
    } else {
      setSortBy(""); // Réinitialiser le champ trié si aucun tri n'est appliqué
      setSortOrder(null); // Réinitialiser l'ordre
    }
  };
  
  
  

   // useEffect pour récupérer la taille de page depuis localStorage
   useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPagination((prev) => ({ ...prev, pageSize: Number(storedPageSize) }));
    }
  }, []);

  //GUI filter change
  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };
  

  useEffect(() => {
    const fetchData = async () => {
      if (filterData.startDate) {
        // Appel API avec filterData
      }
    };
    fetchData();
  }, [filterData]);

  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };
  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  // Function to check if filters are unequal
  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.NumeroDeSerie !== next.NumeroDeSerie ||
      current.Utilisateur !== next.Utilisateur ||
      current.prom !== next.prom ||
      current.alarmId !== next.alarmId ||
      current.startDate !== next.startDate 
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  const handleDateFilterChange = (fieldName: string, value: string) => {
    setDateFilter(value);
  };

// Get alarm list using React Query
// const { data } = useQuery<AlarmsListResponse>({
//   queryKey: ["alarms", sessionId, searchFilter, pagination],
//   queryFn: async () => {
//     const filterApplied: GetAlarmListRequest = {
//       sessionId,
//       page_number: pagination.page,
//       max_results: pagination.pageSize,
//     };

//     // Add filters only if they are not empty
//     if (searchFilter.NumeroDeSerie) {
//       filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
//     }
//     if (searchFilter.Utilisateur) {
//       filterApplied.user_tag_like = searchFilter.Utilisateur;
//     }
//     if (searchFilter.prom) {
//       filterApplied.site_identifier_for_arc_like = searchFilter.prom;
//     }
//     if (searchFilter.alarmId) {
//       filterApplied.id_alarm = searchFilter.alarmId;
//     }

//     // Define constants for time calculations in milliseconds
//     const last48HoursInMS = 48 * 60 * 60 * 1000; // Last 48 hours
//     const last24HoursInMS = 24 * 60 * 60 * 1000; // Last 24 hours
//     const lastHourInMS = 60 * 60 * 1000;          // Last hour
//     if (searchFilter.startDate !== "all") {
//     const now = new Date(); // Current date
//     let desiredTime = 0;    // Initialize desired time variable

//     if (searchFilter.startDate === "last48") {
//       desiredTime = now.getTime() - last48HoursInMS;
//     } else if (searchFilter.startDate === "last24") {
//       desiredTime = now.getTime() - last24HoursInMS; // Calculate timestamp for last 24 hours
//     } else if (searchFilter.startDate === "lastHour") {
//       desiredTime = now.getTime() - lastHourInMS; // Calculate timestamp for last hour
//     }

//     // Assign the computed timestamp to the filter if it's a valid date range
//     if (desiredTime > 0) {
//       const startDate = Math.floor(desiredTime / 1000);
//       filterApplied.start_date = startDate; // Convert milliseconds to seconds
//     }
//   }
//     // Call your API and ensure you return the correct data type
//     const response = await getAlarmsList(filterApplied);
//     return response.data; 
   
//   },
// });


const { data } = useQuery<AlarmsListResponse>({
  queryKey: ["alarms", sessionId, searchFilter,sortBy, sortOrder, pagination],
  queryFn: async () => {
    const filterApplied: GetAlarmListRequest = {
      sessionId,
      page_number: pagination.page,
      max_results: pagination.pageSize,
      // sort_by: "prom",  
      // sort_order: "ASC"
      sort_by: sortBy, 
      sort_order: sortOrder ?? "ASC",
    };

    // Add filters only if they are not empty
    if (searchFilter.NumeroDeSerie) {
      filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
    }
    if (searchFilter.Utilisateur) {
      filterApplied.user_tag_like = searchFilter.Utilisateur;
    }
    if (searchFilter.prom) {
      filterApplied.site_identifier_for_arc_like = searchFilter.prom;
    }
    if (searchFilter.alarmId) {
      filterApplied.id_alarm = searchFilter.alarmId;
    }

    // Define constants for time calculations in milliseconds
    const last48HoursInMS = 48 * 60 * 60 * 1000; // Last 48 hours
    const last24HoursInMS = 24 * 60 * 60 * 1000; // Last 24 hours
    const lastHourInMS = 60 * 60 * 1000; // Last hour
    if (searchFilter.startDate !== "all") {
      const now = new Date(); // Current date
      let desiredTime = 0; // Initialize desired time variable

      if (searchFilter.startDate === "last48") {
        desiredTime = now.getTime() - last48HoursInMS;
      } else if (searchFilter.startDate === "last24") {
        desiredTime = now.getTime() - last24HoursInMS; // Calculate timestamp for last 24 hours
      } else if (searchFilter.startDate === "lastHour") {
        desiredTime = now.getTime() - lastHourInMS; // Calculate timestamp for last hour
      }

      // Assign the computed timestamp to the filter if it's a valid date range
      if (desiredTime > 0) {
        const startDate = Math.floor(desiredTime / 1000);
        filterApplied.start_date = startDate; // Convert milliseconds to seconds
      }
    }
    try {
      const response = await getAlarmsList(filterApplied);

      // Vérifiez si la réponse contient une erreur
      if (response.data.errors) {
        const { code, message } = response.data.errors;

        // Si le code d'erreur est -37, traiter comme une session expirée
        if (code === "-37") {
          dispatch(notifyError(t("sessionExpiredMessage")));
          dispatch(invalidateSession());
          return;
        }

        // Autres types d'erreurs peuvent être traités ici
        throw new Error(`Erreur API : ${message}`);
      }
    
      return response.data; // Retourner les données de l'API si aucune erreur
    } catch (err) {
      console.error("API Request Error:", err);
      throw err; // Rejeter l'erreur pour une gestion ultérieure
    }
  },
});

// Formate une date UTC en date locale avec le fuseau horaire de l'utilisateur 
const formatDate = (dateString: string | null) => {
  if (!dateString) return "-";

  const utcDate = new Date(dateString + " UTC"); // Convertir en UTC
  return utcDate.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};


  // Memorize alarms list
  const memoizedAlarmsList = useMemo(() => data?.data || [], [data]);
 
  //Row count
  const totalItems = data?.total ?? 0;
  const totalPages = data?.has_more ? pagination.page + 1 : pagination.page;
  const isLastPage = data?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // Standard case
  console.log();

  // Menu items for each row
  const menuItems = [
    {
      id: "1",
      label: t("Vidéo clip d'alarme"),
      color: "black",
      handleClick: async (row: AlarmData) => {
        console.log("Alarm data clicked:", row);
        try {
          // Set the selected alarm ID
          setSelectedAlarmId(row.id_alarm);
          // Fetch video URL
          const videoUrl = await getVideoAlarm({
            sessionId,
            alarm_id: row.id_alarm,
          }).then((res) => res.data.videoUrl);
          setSelectedVideoUrl(videoUrl);
          setOpenDialog(true);
        } catch (error) {
          console.error("Failed to fetch video URL", error);
        }
      },
    },
    {
      id: "2",
      label: t("Sessions opérateurs"),
      color: "black",
      handleClick: (row: AlarmData) => {
        navigate(`/records/${row.id_alarm}`);
      },
    },
  ];

  //Fetches video URL data based on the selected alarm ID.
  const {
    data: videoData,
    error,
    isLoading,
  } = useQuery<VideoUrlResponse>({
    queryKey: ["video", sessionId, selectedAlarmId],
    queryFn: () =>
      selectedAlarmId
        ? getVideoAlarm({ sessionId, alarm_id: selectedAlarmId }).then(
            (res) => res.data
          )
        : Promise.resolve(null),
    enabled: !!selectedAlarmId,
  });

  // Effect to log videoData, error, and isLoading
  useEffect(() => {
    console.log("Fetching video data for alarm ID:", selectedAlarmId);
    if (videoData) {
      console.log("Video Data:", videoData);
    } else if (error) {
      console.error("Error fetching video data:", error);
    }
    console.log("Loading state:", isLoading);
  }, [videoData, error, isLoading, selectedAlarmId]);

  // Determine the CSS class for a row based on whether its index is even or odd
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  // Columns
  const columns: GridColDef[] = [
    {
      field: "utilisateur",
      headerName: t("Utilisateur"),
      flex: 1,
      sortable:false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => <span>{params.row && params.row.user_tag}</span>,
    },
    {
      field: "id_alarm",
      headerName: t("Id Alarme"),
      flex: 1,
      sortable:false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Id Alarme")}
        </span>
      ),
      renderCell: (params) => {
        console.log("params.row", params.row);
        return <span>{params.row && params.row.id_alarm}</span>;
      },
    },
    {
      field: "serial_number",
      headerName: "Numéro de série",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable:false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
         {t("Numéro de série")}
        </span>
      ),
      renderCell: (params) => {
        console.log("params.row", params.row);
        return <span>{params.row && params.row.serial_number}</span>;
      },
    },

    {
      field: "prom",
      headerName: "PROM",
      sortable: true,
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
          
        >
          {"PROM"}
        </span>
      ),
      renderCell: (params) => (
        <span>{params.row && params.row.site_identifier_for_arc}</span>
      ),
    },
    {
      field: "date_of_creation",
      headerName: t("Date"),
      sortable: true,
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Date de déclenchement")}
        </span>
      ),
      renderCell: (params) => (
        <span>{formatDate(params.row && params.row.date_of_creation)}</span>
      ),
    },
    
    {
      field: "actions",
      headerName: t("Actions"),
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Actions")}
        </span>
      ),
      renderCell: (params) => (
        <strong>
          <LongMenu menuItems={menuItems} row={params.row} />
        </strong>
      ),
    },
  ];

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 29.5,
          marginTop: 12,
          width: "calc(100% - 252px)",
          height: 55,
          borderRadius: "4px",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white" }}
          >
            {t("Liste des alarmes")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          width="95%"
        />

        <SearchInput
          placeHolder={t("Filtrer par alarm Id")}
          searchLabel={t("Filtrer par alarm Id")}
          fieldName="alarmId"
          value={filterData.alarmId}
          valueChange={changeFilterData}
          size="small"
          width="95%"
        />

        <SearchInput
          placeHolder={t("Filtrer par numéro de série")}
          searchLabel={t("Filtrer par numéro de série")}
          fieldName="NumeroDeSerie"
          value={filterData.NumeroDeSerie}
          valueChange={changeFilterData}
          size="small"
          width="95%"
        />

        <SearchInput
          placeHolder={t("Filtrer par PROM")}
          searchLabel={t("Filtrer par PROM")}
          fieldName="prom"
          value={filterData.prom}
          valueChange={changeFilterData}
          size="small"
          width="95%"
        />

        <SelectInput
          options={dateFilterOptions}
          placeHolder={t("Filtrer par date")} 
          searchLabel={t("Filtrer par date")} 
          fieldName="startDate"
          value={filterData.startDate}
          valueChange={changeFilterData}
          size="small"
          fullWidth
        />
      </Box>
      <Box sx={{ flexGrow: 1, marginLeft: "222px", width: "calc(100% - 250px)"  }}>
        <DataGrid
          rows={memoizedAlarmsList}
          loading={isLoading}
          columns={columns}
          rowCount={rowCount}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
            // Enregistrer la taille de la page dans localStorage
            localStorage.setItem("pageSize", model.pageSize.toString());
          }}
          getRowId={(row: AlarmData) => row.id_alarm} // Specify custom id field
          className="custom-scrollbar"
          getRowClassName={getRowClassName}
          onSortModelChange={handleSortModelChange} 
          sx={{
            height: "calc(100vh - 300px)",
            marginLeft: 2,

            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            "& .even-row": {
              backgroundColor: "#ffffff",
            },
            "& .odd-row": {
              backgroundColor: "#eff9ff",
            },
            // Masquer les ellipses de tri et de filtre
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            // "& .MuiDataGrid-sortIcon": {
            //   display: "none",
            // },
   
          }}
        />
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: { xs: 35, sm: 35, md: -50 }, // Décalage plus bas
              right: { xs: "2rem", sm: "2rem", md: "3rem" }, // Position de droite, responsive
              padding: 2, // Padding optionnel
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" }, // Taille de police responsive
              marginRight: { xs: 1, sm: 2, md: 1 }, // Marge droite responsive
              marginBottom: { xs: 1, sm: 1, md: 6.5 }, // Marge basse responsive
            }}
          >
            {t("Page")} {pagination.page}
          </Typography>
        </Box>
      </Box>
      <AlarmVideoClip
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        videoUrl={videoData?.data || ""} // Access the `data` property for the URL
      />
    </Box>
  );
};

export default ListeAlarmesPage;