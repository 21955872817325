import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, AppBar, Toolbar, Typography, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  GetUsersListResponse,
  SiteListResponse,
} from "../../../../types/Responses";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SearchInput from "../../../reusable/inputs/SearchInput";
import {
  SiteDeleteRequest,
  SitesListRequest,
} from "../../../../types/Requests";
import {
  notifyError,
  notifySuccess,
} from "../../../../store/reducers/notification.slice";
import CustomPagination from "../../../reusable/tables/CustomPagination";
import BasicModal from "../../../reusable/modals/BasicModal";
import { invalidateSession } from "../../../../store/reducers/user.slice";
import {
  deleteSite,
  getSitesList,
} from "../../../../services/site/site.service";
import CreateSite from "../../CreateSite";
import CreateSiteByUser from "../../CreateSiteByUser";

type FilterData = {
  prom: string;
  Utilisateur: string;
};

const SiteListByUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  console.log("iddd", id);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);

  //initial states
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    data: "",
  });

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  //displayed filter GUI control
  const [filterData, setFilterData] = useState<FilterData>({
    Utilisateur: "",
    prom: "",
  });

  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    Utilisateur: "",
    prom: "",
  });

  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };

  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };

  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  // Function to check if filters are unequal
  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.Utilisateur !== next.Utilisateur || current.prom !== next.prom
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  const { data: SitesData, isLoading } = useQuery<SiteListResponse>({
    queryKey: ["sitesByUser", sessionId, id, searchFilter, pagination],
    queryFn: async () => {
      if (!id) {
        throw new Error("Site ID is undefined");
      }
      const filterApplied: SitesListRequest = {
        sessionId,
        user_id: id || "",
        page_number: pagination.page,
        max_results: pagination.pageSize,
      };

      if (searchFilter.prom !== "") {
        filterApplied.site_identifier_for_arc_like = searchFilter.prom;
      }
      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }

      try {
        const response = await getSitesList(filterApplied);

        // Vérifiez si la réponse contient une erreur
        if (response.data.errors) {
          const { code, message } = response.data.errors;

          // Si le code d'erreur est -37, traiter comme une session expirée
          if (code === "-37") {
            dispatch(notifyError(t("sessionExpiredMessage")));
            dispatch(invalidateSession());
            return;
          }

          // Autres types d'erreurs peuvent être traités ici
          throw new Error(`Erreur API : ${message}`);
        }

        console.log("Response Data:", response.data);
        return response.data; // Retourner les données de l'API si aucune erreur
      } catch (err) {
        console.error("API Request Error:", err);
        throw err; // Rejeter l'erreur pour une gestion ultérieure
      }
    },
  });

  // Memoize rows to avoid unnecessary re-renders
  const SitesByUser = useMemo(() => SitesData?.data || [], [SitesData]);
  console.log("SitesByUser", SitesByUser);

  // Open the create site dialog
  const handleClickOpen = (): void => {
    setOpenCreate(true);
  };

  // Close the create site dialog
  const handleCloseCreate = (): void => {
    setOpenCreate(false);
  };

  // Handle the delete button click and open the delete confirmation dialog
  const handleDelete = (event: React.MouseEvent, siteId: string) => {
    event.stopPropagation();
    setDeleteConfirmation({ open: true, data: siteId });
  };

  // Close the delete confirmation dialog
  const handleCloseConfirmation = () => {
    setDeleteConfirmation({ open: false, data: "" });
  };

  //Determines the CSS class name to apply to a row based on its index.
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const deleteSiteMutation = useMutation({
    mutationFn: (request: SiteDeleteRequest) => {
      return deleteSite(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("Site deleted successfully")));
      queryClient.invalidateQueries({ queryKey: ["sitesByUser"] });
    },
    onError: (error: any) => {
      // Vérifiez si l'erreur est liée au code -93
      const errorCode = error.response?.data?.errors?.code;
      const errorMessage = error.response?.data?.errors?.message;

      if (errorCode === "-93") {
        dispatch(
          notifyError(
            t("Cannot delete this site. There are cameras associated with it")
          )
        );
      } else {
        // Message d'erreur générique pour d'autres cas
        dispatch(notifyError(t("Failed to delete the site")));
      }

      // Affichez l'erreur complète pour débogage
      console.error("Delete site error:", errorMessage || error);
    },
  });

  // Confirm the deletion of a site
  const handleConfirmDelete = async () => {
    if (deleteConfirmation.data) {
      console.log("cfcf+++++", deleteConfirmation.data);
      deleteSiteMutation.mutate({
        site_id: deleteConfirmation.data,
      });
      handleCloseConfirmation();
    }
  };

  //Determine the rows count
  const totalItems = SitesData?.total ?? 0;
  const totalPages = SitesData?.has_more
    ? pagination.page + 1
    : pagination.page;
  const isLastPage = SitesData?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // Standard case

  // Columns configuration for DataGrid
  const columns: GridColDef[] = [
    {
      field: "siteIdentifierForArc",
      headerName: t("PROM"),
      flex: 3,
      //maxWidth: 350,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("PROM")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.siteIdentifierForArc}</span>
        </div>
      ),
    },
    {
      field: "site_name",
      headerName: t("siteName"),
      flex: 3,
      //maxWidth: 350,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("siteName")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.site_name}</span>
        </div>
      ),
    },
    {
      field: "user_tag",
      headerName: t("Utilisateur"),
      flex: 3,
      //maxWidth: 350,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.user_tag}</span>
        </div>
      ),
    },
    {
      field: "email",
      headerName: t("Email du client"),
      flex: 3,
      //maxWidth: 350,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Email du client")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.email}</span>
        </div>
      ),
    },
    {
      field: "numberOfCameras",
      headerName: t("Nombre de caméras"),
      flex: 3,
      // maxWidth: 350,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Nombre de caméras")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.nb_cameras}</span>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: t("Actions"),
      // flex: 1,
      maxWidth: 109,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Actions")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          {permissions.includes("deleteSite") && (
            <Button
              variant="outlined"
              sx={{ color: "#5585b5", fontSize: 8 }}
              onMouseEnter={() => setHoveredRowId(params.row.site_id)}
              onMouseLeave={() => setHoveredRowId(null)}
              onClick={(event) => handleDelete(event, params.row.site_id)}
            >
              {hoveredRowId === params.row.site_id ? (
                t("Delete")
              ) : (
                <DeleteIcon />
              )}
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <Box mt={3} sx={{ marginBottom: 10 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 30,
          marginTop: 5,
          width: "calc(100% - 252px)",
          height: 55,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("Liste des sites")}
          </Typography>
          {permissions.includes("addSite") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                ml: "auto",
                margin: { xs: "4px", sm: "8px", md: "12px", lg: "16px" }, // Responsive margins
                position: "relative",
                top: "-5px",
                backgroundColor: "rgb(224, 247, 250)",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                "&:hover": {
                  backgroundColor: "#3498DB",
                  color: "white",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                "&:focus": {
                  backgroundColor: "#3498DB",
                  color: "#393e46",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                color: "#393e46",
                fontSize: { xs: "8px", sm: "10px", md: "12px" }, // Responsive font size
                whiteSpace: "nowrap",
                width: { xs: "auto", sm: "auto" }, // Responsive width
                maxWidth: { xs: "200px", sm: "250px" }, // Max width for larger screens
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Ensure icon color changes with text color on hover
                "& .MuiSvgIcon-root": {
                  color: "inherit",
                },
                // Ensure icon color changes with text color on focus
                "& .MuiSvgIcon-root:focus": {
                  color: "inherit",
                },
              }}
            >
              <AddIcon
                sx={{
                  height: { xs: 12, sm: 15 },
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
              />
              {t("Ajouter un site")}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <SearchInput
          placeHolder={t("Filtrer par PROM")}
          searchLabel={t("Filtrer par PROM")}
          fieldName="prom"
          value={filterData.prom}
          valueChange={changeFilterData}
          size="small"
          width="30%"
        />
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          width="30%"
        />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          marginLeft: "225px",
          width: "85.5%",
          marginBottom: 30,
        }}
      >
        <DataGrid
          columns={columns}
          rows={SitesByUser}
          loading={isLoading}
          rowCount={rowCount}
          getRowId={(row) => row.site_id}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
            // Enregistrer la taille de la page dans localStorage
            localStorage.setItem("pageSize", model.pageSize.toString());
          }}
          className="custom-scrollbar"
          getRowClassName={getRowClassName}
          sx={{
            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            marginLeft: { xs: 0, sm: 1, md: 2 }, 
            height:"calc(100vh - 600px)",
          
            "& .even-row": {
              backgroundColor: "#ffffff",
            },
            "& .odd-row": {
              backgroundColor: "#eff9ff",
            },
            // Masquer les ellipses de tri et de filtre
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              display: "none",
            },
          }}
        />

        <Box sx={{ position: "relative" }}>
          {" "}
          {/* Parent avec position relative */}
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: { xs: 35, sm: 35, md: -50 },
              right: { xs: "2rem", sm: "2rem", md: "3rem" },
              padding: 2,
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" },
              marginRight: { xs: 1, sm: 2, md: 1 },
              marginBottom: { xs: 1, sm: 1, md: 6.5 },
            }}
          >
            {t("Page")} {pagination.page}
          </Typography>
        </Box>
      </Box>

      <CreateSiteByUser
        open={openCreate}
        handleClose={handleCloseCreate}
        onCreateSuccessCB={() => {
          queryClient.invalidateQueries({
            queryKey: ["sitesByUser", sessionId, id],
          });
          setOpenCreate(false);
        }}
      />
      <BasicModal
        open={deleteConfirmation.open}
        handleClose={handleCloseConfirmation}
        fullWidth={true}
        title={t("Confirmation de suppression")}
        maxWidth={"sm"}
        actions={
          <>
            {/* Cancel button */}
            <Button onClick={handleCloseConfirmation} color="primary">
              {t("cancel")}
            </Button>

            {/* Confirm delete button */}
            <Button
              onClick={handleConfirmDelete}
              variant="contained"
              color="primary"
            >
              {t("supprimer")}
            </Button>
          </>
        }
      >
        {t("Êtes vous sûr de vouloir supprimer ce site ?")}
      </BasicModal>
    </Box>
  );
};
export default SiteListByUser;
