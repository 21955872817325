import axios from "axios";
import getConfiguration from "../../config/config";
import {
  AskForResetRequest,
  ChangePasswordRequest,
  CheckResetTokenRequest,
  DeleteTeamMemberRequest,
  LoginRequest,
  ResetPasswordRequest,
  UpdateEmailRequest,
  UpdateTeamMemberRoleRequest,
  UpdateTeamRolePermissionRequest,
} from "../../types/Requests";
import { APIPaths } from "../paths";
import { getUserSession } from "../../utils/auth";




/**
 * Logs in a user based on the provided credentials.
 * @param {LoginRequest} request - LoginRequest specifying the user's login credentials.
 * @returns {Promise} Promise containing user authentication information.
 */
const logIn = (request: LoginRequest) => {
  const configuration = getConfiguration();
  return axios.get(configuration.apiPath + APIPaths.login, {
    // full url
    params: {
      ...request,
    },
  });
};

/**
 * changes the user password.
 * @param {ChangePasswordRequest} request - ChangePasswordRequest cotianing the old and new passwords.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const changePassword = (request: ChangePasswordRequest) => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.changePassword, {
    ...request,
  });
};

/**
 * verfies email.
 * @param {AskForResetRequest} request - CheckResetTokenRequest containing the token and email.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const askForReset = (request: AskForResetRequest) => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.askForReset, {
    ...request,
  });
};

/**
 * verfies token and email.
 * @param {CheckResetTokenRequest} request - CheckResetTokenRequest containing the token&email to be verified.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const checkResetToken = (request: CheckResetTokenRequest) => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.checkResetToken, {
    ...request,
  });
};

/**
 * verfies token and email.
 * @param {ResetPasswordRequest} request - CheckResetTokenRequest containing the token, email and new password.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const resetPassword = (request: ResetPasswordRequest) => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.resetPassword, {
    ...request,
  });
};

/**
 * Retrieves a list of team members.
 * @returns {Promise} Promise containing the list of team members.
 */
const teamList = () => {
  const configuration = getConfiguration();
  return axios.get(configuration.apiPath + APIPaths.installerTeam, {
    params: {
      sessionId: getUserSession(),
    },
  });
};

/**
 * Deletes a specific team member.
 * @param {DeleteTeamMemberRequest} request - DeleteTeamMemberRequest specifying the team member to be deleted.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const deleteTeamMember = (request: DeleteTeamMemberRequest) => {
  const configuration = getConfiguration();

  return axios.post(configuration.apiPath + APIPaths.deleteTeamMember, {
    ...request,
  });
};

/**
 * Retrieves a list of available team roles.
 * @returns {Promise} Promise containing the list of user roles.
 */
const teamRoles = () => {
  const configuration = getConfiguration();
  return axios.get(configuration.apiPath + APIPaths.installerTeamRoles, {
    params: {
      sessionId: getUserSession(),
    },
  });
};

/**
 * Updates the role of a specific team member.
 * @param {UpdateTeamMemberRoleRequest} request - UpdateTeamMemberRoleRequest specifying the team member and new role.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const updateTeamMemberRole = (request: UpdateTeamMemberRoleRequest) => {
  const configuration = getConfiguration();

  return axios.post(
    configuration.apiPath + APIPaths.updateTeamMemberRole,
    request,
    {
      params: {
        sessionId: getUserSession(),
      },
    }
  );
};

/**
 * Updates role permissions for a specific team role.
 * @param {UpdateTeamRolePermissionRequest} request - UpdateTeamRolePermissionRequest specifying the role and permissions.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const updateRolePermissions = (request: UpdateTeamRolePermissionRequest) => {
  const configuration = getConfiguration();

  return axios.post(
    configuration.apiPath + APIPaths.updateTeamRolePermissions,
    {
      ...request,
    }
  );
};

/**
.
 * @returns {Promise} Promise containing the list of user profile.
 */
const userProfile = () => {
  const configuration = getConfiguration();
  return axios.get(configuration.apiPath + APIPaths.userCompanyProfile, {
    params: {
      sessionId: getUserSession(),
    },
  });
};

/**
 * Updates userCompany email.
 * @param {UpdateEmailRequest} request
 * @returns {Promise} Promise indicating the success of the operation.
 */
const updateEmail = (request: UpdateEmailRequest) => {
  const configuration = getConfiguration();

  return axios.post(configuration.apiPath + APIPaths.updateEmail, {
    ...request,
  });
};

export {
  logIn,
  changePassword,
  teamList,
  deleteTeamMember,
  teamRoles,
  updateRolePermissions,
  askForReset,
  updateTeamMemberRole,
  userProfile,
  updateEmail,
  checkResetToken,
  resetPassword
};
