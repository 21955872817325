import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useRef } from "react";
import DialogActions from "@mui/material/DialogActions";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface BasicModalProps {
  children: React.ReactNode;
  title: string;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl";
  open: boolean;
  handleClose: any;
  actions: any;
  actionsLoading?: boolean;
  fullWidth?: boolean;
}
/**
 * Component to customise the Dialog react Mui component
 *
 * @component
 * @param {React.ReactNode} children            the modal content
 * @param {string} title                        the modal title
 * @param {"xs"|"sm"|"md"|"lg"|"xl"} maxWidth   the modal max widtha ccording to the mui modal sizes
 * @param {boolean} open                        the modal open state
 * @param {any} handleClose                     the modal close action controling the open state
 * @param {any} actions                         the modal action section passed as a component
 * @param {any} actionsLoading                  [optional] the modal action section is Loading defaults to false
 * @param {boolean} fullWidth                   [optional] the card image background color (if the image is Text) (0:blue, 1:skyblue, 2:red, 3:green, 4:purple, 5:yellow, 6:orange, 7:brown, -1:random)
 * @example
 * const title = "My Modal";
 * const maxWidth = "md";
 * const [open, setOpen] = useState(false);
 * const handleClose = () => {
 *    setOpen(false)
 * }
 * const actions = <DefaultButton
 *    handleClick={() => {setOpen(false);}}
 *    text="Close"
 *    variant="text"
 *    color={Color.TextPrimary}
 *    backgroundColor={Color.ButtonTextBg}
 *    hoverBgColor={Color.ButtonTextBgHover}
 * />
 * const [actionsLoading, setActionsLoading] = useState(false);
 * const fullWidth = true
 * return (
 *   <BasicModal title maxWidth open handleClose actions actionsLoading fullWidth >
 *    <Typography> Please close this modal </Typography>
 *   </BasicModal>
 * )
 */
export default function BasicModal(props: BasicModalProps) {
  const {
    children,
    handleClose,
    open,
    title,
    maxWidth,
    actions,
    actionsLoading = false,
    fullWidth,
  } = props;
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={actionsLoading ? null : handleClose}
      scroll={"paper"}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle id="scroll-dialog-title"  style={{ backgroundColor: "#395069", color:"white" }}>{title}</DialogTitle>
      <DialogContent dividers={true}>{children}</DialogContent>
      <DialogActions>
        {actionsLoading ? <CircularProgress size={"30px"} /> : actions}
      </DialogActions>
    </Dialog>
  );
}
