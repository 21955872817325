import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Avatar,
  FormControl,
  TextField,
  TableContainer,
} from "@mui/material";
import {
  Person as PersonIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordPage from "./PasswordPage";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateEmail, userProfile } from "../../../services/auth/auth.service";
import HorizontalCard from "../../reusable/cards/HorizontalCard";
import BasicTabs, { TabPanel } from "../../reusable/tables/BasicTabs";
import tabsStyle from "../../../styles/components/tabs/tabs.style";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicModal from "../../reusable/modals/BasicModal";
import { invalidateSession } from "../../../store/reducers/user.slice";
import { ErrorResponse } from "../../../types/Responses";

// Define the profile data interface
interface ProfileData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const tabElements = tabsStyle;

const ProfilPage: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);
  // State for managing active tab
  const [tabValue, setTabValue] = useState(0);

  // Dialog states
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState("");

 
  // const profileQuery = useQuery<ProfileData, Error>({
  //   queryKey: ["profileUser", sessionId],
  //   queryFn: async () => {
  //     if (sessionId) {
  //       const res = await userProfile();
  //       return res.data.data;
  //     }
  //     return Promise.reject("No session ID provided");
  //   },
  // });


   // Fetch profile data using useQuery
  const profileQuery = useQuery<ProfileData, Error>({
    queryKey: ["profileUser", sessionId],
    queryFn: async () => {
      if (sessionId) {
        const res = await userProfile();
        // Vérifiez si la réponse indique que la session est requise
        if (res.data.error === "Session Key Required for this function") {
          // Déclencher une notification ou un message d'erreur
          dispatch(notifyError(t("sessionExpiredMessage")));
          dispatch(invalidateSession());
          return; 
        }
        return res.data.data;
      }
      return Promise.reject(new Error("No session ID provided"));
    },
  });

  // Memorize profile data
  const profiles = useMemo(
    () =>
      profileQuery.data || {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    [profileQuery.data]
  );

  const [profileData, setProfileData] = useState({
    fullName: `${profiles.firstName} ${profiles.lastName}`,
    email: profiles.email,
    phone: profiles.phone,
  });

  // Update profile data state when profileQuery data changes
  useEffect(() => {
    if (profileQuery.data) {
      setProfileData({
        fullName: `${profileQuery.data.firstName} ${profileQuery.data.lastName}`,
        email: profileQuery.data.email,
        phone: profileQuery.data.phone,
      });
      setEmail(profileQuery.data.email);
    }
  }, [profileQuery.data]);

  // Handle tab change and email input change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // Dialog handlers
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Mutation for email update
  // const updateEmailMutation = useMutation({
  //   mutationFn: (newEmail: string) =>
  //     updateEmail({ sessionId, email: newEmail }),
  //   onSuccess: () => {
  //     dispatch(notifySuccess(t("email updated successfully")));
  //     // Invalidate the profile query to refresh data
  //     queryClient.invalidateQueries({ queryKey: ["profileUser", sessionId] });

  //     handleCloseDialog();
  //   },
  //   onError: (error) => {
  //     dispatch(notifyError(t("failed to update email")));
  //     console.error("Error updating email:", error);
  //   },
  // });

  const updateEmailMutation = useMutation({
    mutationFn: (newEmail: string) => {
      return updateEmail({ sessionId, email: newEmail });
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("email updated successfully")));
      // Invalidate the profile query to refresh data
      queryClient.invalidateQueries({ queryKey: ["profileUser", sessionId] });
      handleCloseDialog();
    },
    onError: (error: any) => {
  
      // Vérifiez si l'erreur contient une réponse
      const statusCode = error.response?.status;
      const respText = error.response?.data as ErrorResponse;
      const errorCode = respText?.errors?.code;
  
      if (statusCode === 400) {
        // Gestion des erreurs pour le code d'état 400
        if (errorCode === "-40") {
          dispatch(notifyError(t("email is required"))); 
        } else {
          dispatch(notifyError(t("failed to update email")));
        }
      } else if (statusCode === 406) {
        // Gestion des erreurs spécifiques pour le code d'état 406
        if (errorCode === "-129") {
          dispatch(notifyError(t("invalid email format"))); 
        } else {
          dispatch(notifyError(t("failed to update email"))); 
        }
      } else {
        dispatch(notifyError(t("failed to update email"))); 
      }
    },
  });
  
  
  // Submit updated email
  const handleSubmitEmail = () => {
    // Trigger mutation
    updateEmailMutation.mutate(email);
    setOpenDialog(false);
  };

  // Colored icon components
  const ColoredIcon = () => (
    <Avatar sx={{ bgcolor: "#92a8d1" }}>
      <EmailIcon sx={{ color: "white" }} />
    </Avatar>
  );

  const ColoredPersonIcon = () => (
    <Avatar sx={{ bgcolor: "#618685" }}>
      <PersonIcon sx={{ color: "white" }} />
    </Avatar>
  );

  const ColoredPhoneIcon = () => (
    <Avatar sx={{ bgcolor: "#80ced6" }}>
      <PhoneIcon sx={{ color: "white" }} />
    </Avatar>
  );

  return (
    <Box p={3}>
      {/* Tabs */}
      <Box
        css={tabElements.tabsContainer}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      ></Box>

      {/*<BasicTabs
        tabs={[
          { key: 0, label: t("Profil"), value: 0 },
          { key: 1, label: t("Mot de passe"), value: 1 },
        ]}
        value={tabValue}
        handleChange={handleTabChange}
        valueAccessor="value"
        labelAccessor="label"
        keyAccessor="key"
        fullWidth={true}
      />
      */}
      <BasicTabs
        tabs={[
          { key: 0, label: t("Profil"), value: 0 },
          ...(permissions.includes("password")
            ? [{ key: 1, label: t("Mot de passe"), value: 1 }]
            : []),
        ].filter(Boolean)} // Filter out falsy values
        value={tabValue}
        handleChange={handleTabChange}
        valueAccessor="value"
        labelAccessor="label"
        keyAccessor="key"
        fullWidth={true}
      />
      {permissions.includes("ShowProfile") && (
        <TabPanel value={tabValue} index={0}>
          <Box
            p={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            css={{
              width: "80%",
              maxWidth: 1200,
              marginLeft: "200px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                overflowX: "hidden",
                width: "50%",
                marginLeft: 19,
                backgroundColor: "#e4f1fe",
              }}
            >
              <HorizontalCard
                image={<ColoredPersonIcon />}
                imageIsName={false} //Set to false because the image is a React component
                description={profileData.fullName}
                colorIndex={-1}
              />
              <HorizontalCard
                image={<ColoredIcon />}
                imageIsName={false}
                description={profileData.email}
                colorIndex={-1}
                actions={
                  <>
                    {permissions.includes("changeProfile") && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDialog}
                        sx={{ width: 164 }}
                      >
                        {t("Modifier")}
                      </Button>
                    )}
                  </>
                }
                actionsWidth={210}
              />
              {/* Conditionally render the phone card */}
              {profileData.phone && profileData.phone.trim() !== "" && (
                <HorizontalCard
                  image={<ColoredPhoneIcon />}
                  imageIsName={false}
                  description={profileData.phone}
                  colorIndex={-1}
                />
              )}
            </TableContainer>
          </Box>
        </TabPanel>
      )}
      {permissions.includes("password") && (
        <TabPanel value={tabValue} index={1}>
          {tabValue === 1 && <PasswordPage />}
        </TabPanel>
      )}
      {/* Dialog for email update */}
     
      <BasicModal
        open={openDialog}
        handleClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        title={t("Gérer le profil")}
        actions={
          <>
            <Button onClick={handleCloseDialog} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitEmail}
            >
              {t("save")}
            </Button>
          </>
        }
      >
        <Typography variant="body1" gutterBottom>
          {t("Modifier l'email pour")} {profileData.fullName}
        </Typography>
        <FormControl fullWidth margin="normal">
          <TextField
            label={t("Email")}
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </BasicModal>
    </Box>
  );
};

export default ProfilPage;
