import { Alert } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import React, { useEffect, useState } from "react";

interface NotificationToastProps {
  showing: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error";
  index: number;
  onClose: (index: number) => void;
}

const NotificationToast: React.FC<NotificationToastProps> = ({
  message,
  severity,
  showing,
  onClose,
  index,
}) => {
  const [isShowing, setIsShowing] = useState(showing);

  const handleCloseToast = () => {
    setIsShowing(false);
    onClose(index);
  };


  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    if (showing) {
      setIsShowing(true);
      timeoutId = setTimeout(() => {
        handleCloseToast();
      }, 6000); // Toast closes after 6 seconds
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showing]);

  return (
    <Collapse in={isShowing}>
        <Alert
        sx={{
          width: 300, 
          position: "fixed",
          top: 60,
          right: 25,
          zIndex: 9999,
          borderRadius: "10px", 
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)", 
          backdropFilter: "blur(5px)", 
          transition: "transform 0.3s ease-in-out", 
          transform: isShowing ? "translateY(0)" : "translateY(-10px)", 
        }}
        severity={severity}
        onClose={handleCloseToast}
      >
        {message}
      </Alert> 
      
    </Collapse>
  );
};

export default NotificationToast;
