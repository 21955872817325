import axios from "axios";
import getConfiguration from "../../config/config";
import {
  GeneralSessionsOperatorsRequest,
  GetAlarmListRequest,
  GetVideoAlarmRequest,
  SessionsOperatorsListRequest,
} from "../../types/Requests";
import { APIPaths } from "../paths";
import { getUserSession } from "../../utils/auth";

/**
 * Retrieves a list of alarms
 * @param {GetAlarmListRequest} request
 * @returns {Promise} Promise containing the list of alarms.
 */
const getAlarmsList = (request: GetAlarmListRequest) => {
  const configuration = getConfiguration();
  const url = configuration.apiPath + APIPaths.alarmList;
  return axios
    .post(url, request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error:", error); // Log any errors
      throw error;
    });
};

/**
 * Retrieves a list of sessions for operators based on the provided filter criteria.
 * @param {SessionsOperatorsListRequest} request - Filter criteria for sessions.
 * @returns {Promise} Promise containing the list of sessions.
 */
const getSessionsOperatorsList = (request: SessionsOperatorsListRequest) => {
  const configuration = getConfiguration();

  return axios.post(configuration.apiPath + APIPaths.sessionsOpList, {
    ...request,
  });
};

/**

 * @param {GetVideoAlarmRequest} request - GetAlarmRequest 
 * @returns {Promise} Promise containing url video alarm.
 */
const getVideoAlarm = (request: GetVideoAlarmRequest) => {
  const configuration = getConfiguration();
  return axios.get(configuration.apiPath + APIPaths.videoAlarm, {
    // full url
    params: {
      ...request,
    },
  });
};

/**
 * Retrieves a list of sessions for operators based on the provided filter criteria.
 * @param { GeneralSessionsOperatorsRequest} request - Filter criteria for sessions.
 * @returns {Promise} Promise containing the list of sessions.
 */
const generalSessionsOperatorsList = (
  request: GeneralSessionsOperatorsRequest
) => {
  const configuration = getConfiguration();

  return axios.post(configuration.apiPath + APIPaths.sessionsOpList, {
    ...request,
  });
};

export {
  getAlarmsList,
  getSessionsOperatorsList,
  getVideoAlarm,
  generalSessionsOperatorsList,
};
