import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  logIn,
  isLoggedIn,
  logOut,
  getUserSession,
  savePerms,
} from "../../utils/auth";
import { TeamMember, TeamRole, TeamRoleFunction } from "../../types/Responses";
import { deleteItemFromLocalStorage, getItemFromLocalStorage } from "../../utils/localStorage";

// Définir l'accesseur pour la session utilisateur
const accessor = "user"; 
// Define the shape of the initial state
type InitialState = {
  sessionId: string;
  permissions: string[];
  team: TeamMember[];
  allRoles: TeamRole[];
  allPermissions: TeamRoleFunction[];
  refetchRoles: any;
  refetchTeam: any;
  sessionExpired: boolean;
};

// Set the initial state
const initialState: InitialState = {
  sessionId: "",
  permissions: [],
  team: [],
  allRoles: [],
  allPermissions: [],
  refetchRoles: () => {},
  refetchTeam: () => {},
  sessionExpired: false,
};

// Create a Redux slice
const userSlice = createSlice({
  name: "user", // Slice name
  initialState, // Initial state
  reducers: {
    // Reducer to set the user session
    setSession(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
      logIn(action.payload);//set in the store et saving in localstorage(persist store save in localstorage the session)
    },
  
    // Reducer to set the user perms
    setPermissions(state, action: PayloadAction<string[]>) {
      state.permissions = [...action.payload];
      savePerms(action.payload);
    },
    // Reducer to check the user session
    checkSession(state) {
      if (isLoggedIn()) {
         state.sessionId = getUserSession();
       }
     },
    
  
    // Reducer to invalidate the user session
    invalidateSession(state) {
      state.sessionId = "";
      logOut();
    },

    // Reducer to set the team members
    setTeam(state, action: PayloadAction<TeamMember[]>) {
      state.team = action.payload;
    },
    // Reducer to set the refetch function for team members
    setRefetchTeamMembers(state, action: PayloadAction<any>) {
      state.refetchTeam = action.payload;
    },
    // Reducer to set all roles
    setAllRoles(state, action: PayloadAction<TeamRole[]>) {
      state.allRoles = action.payload;
    },

    // Reducer to reset all roles
    resetAllRoles(state) {
      state.allRoles = [];
    },

    // Reducer to set the refetch function for roles
    setRefetchRoles(state, action: PayloadAction<any>) {
      state.refetchRoles = action.payload;
    },

    // Reducer to set all permissions
    setAllPermissions(state, action: PayloadAction<TeamRoleFunction[]>) {
      state.allPermissions = action.payload;
    },
  },
});
// Export the reducer and actions
export default userSlice.reducer;
export const {
  setSession,
  checkSession,
  invalidateSession,
  setPermissions,
  setTeam,
  setRefetchTeamMembers,
  setAllRoles,
  resetAllRoles,
  setAllPermissions,
  setRefetchRoles,
} = userSlice.actions;

/*import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  logIn,
  isLoggedIn,
  logOut,
  getUserSession,
  savePerms,
} from "../../utils/auth";
import { TeamMember, TeamRole, TeamRoleFunction } from "../../types/Responses";
import { getItemFromLocalStorage } from "../../utils/localStorage";

// Define the shape of the initial state
type InitialState = {
  sessionId: string;
  permissions: string[];
  team: TeamMember[];
  allRoles: TeamRole[];
  allPermissions: TeamRoleFunction[];
  refetchRoles: any;
  refetchTeam: any;
  sessionExpired: boolean; // Ajouté pour suivre l'expiration
};

// Set the initial state
const initialState: InitialState = {
  sessionId: "",
  permissions: [],
  team: [],
  allRoles: [],
  allPermissions: [],
  refetchRoles: () => {},
  refetchTeam: () => {},
  sessionExpired: false, // Initialisé à false
};

// Create a Redux slice
const userSlice = createSlice({
  name: "user", // Slice name
  initialState, // Initial state
  reducers: {
    // Reducer to set the user session
    setSession(state, action: PayloadAction<{ sessionId: string; sessionMaxLifetime: string }>) {
      state.sessionId = action.payload.sessionId;
      logIn(action.payload.sessionId, action.payload.sessionMaxLifetime); // Passer les deux arguments
    },
    
    // Reducer to set the user perms
    setPermissions(state, action: PayloadAction<string[]>) {
      state.permissions = [...action.payload];
      savePerms(action.payload);
    },
    // Reducer to check the user session
    checkSession(state) {
      if (isLoggedIn()) {
        const sessionId = getUserSession();
        const sessionMaxLifetime = parseInt(getItemFromLocalStorage("sessionMaxLifetime") || "0", 10);
        const sessionStartTime = parseInt(getItemFromLocalStorage("sessionStartTime") || "0", 10);
        const sessionExpired = (Date.now() - sessionStartTime) > sessionMaxLifetime;
    
        if (sessionExpired) {
          state.sessionId = "";
          state.sessionExpired = true; // Met à jour l'état pour indiquer l'expiration
        } else {
          state.sessionId = sessionId;
          state.sessionExpired = false; // Réinitialiser l'état d'expiration
        }
      }
    },
    // Reducer to invalidate the user session
    invalidateSession(state) {
      state.sessionId = "";
      logOut();
    },

    // Reducer to set the team members
    setTeam(state, action: PayloadAction<TeamMember[]>) {
      state.team = action.payload;
    },
    // Reducer to set the refetch function for team members
    setRefetchTeamMembers(state, action: PayloadAction<any>) {
      state.refetchTeam = action.payload;
    },
    // Reducer to set all roles
    setAllRoles(state, action: PayloadAction<TeamRole[]>) {
      state.allRoles = action.payload;
    },

    // Reducer to reset all roles
    resetAllRoles(state) {
      state.allRoles = [];
    },

    // Reducer to set the refetch function for roles
    setRefetchRoles(state, action: PayloadAction<any>) {
      state.refetchRoles = action.payload;
    },

    // Reducer to set all permissions
    setAllPermissions(state, action: PayloadAction<TeamRoleFunction[]>) {
      state.allPermissions = action.payload;
    },
  },
});
// Export the reducer and actions
export default userSlice.reducer;
export const {
  setSession,
  checkSession,
  invalidateSession,
  setPermissions,
  setTeam,
  setRefetchTeamMembers,
  setAllRoles,
  resetAllRoles,
  setAllPermissions,
  setRefetchRoles,
} = userSlice.actions;*/

