/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from "@emotion/react";
import { Slide } from "@mui/material";
import { useRef, useState, useMemo, useCallback } from "react";
import { Color } from "../../../constants/color";
import { horizontalCardStyle } from "../../../styles/components/cards/cards.style";

interface HorizontalCardProps {
  image: string | React.ReactNode; // Permettre à image d'être une chaîne ou un élément React
  imageIsName: boolean;
  title?: string;
  description: string;
  note?: string;
  actions?: React.ReactNode; // Utiliser React.ReactNode pour actions, pour les boutons ou autres éléments interactifs
  actionsWidth?: number;
  colorIndex?: number;
}

const backgroundColors = [
  Color.BackgroundBlue,
  Color.BackgroundSkyBlue,
  Color.BackgroundRed,
  Color.BackgroundGreen,
  Color.BackgroundPurple,
  Color.BackgroundYellow,
  Color.BackgroundOrange,
  Color.BackgroundBrown,
];

const cardElements = horizontalCardStyle;

const HorizontalCard = (props: HorizontalCardProps) => {
  const {
    image,
    imageIsName,
    title = "",
    description,
    note = "",
    actions = <></>,
    actionsWidth = 0,
    colorIndex = 0,
  } = props;
  const [mouseOver, setIsMouseOver] = useState(false);
  let nameSlices = [];
  let initials = "";

  if (imageIsName && typeof image === 'string') {
    nameSlices = image.split(" ");
    if (nameSlices.length > 0) {
      initials =
        nameSlices.at(0)!.slice(0, 1) +
        (nameSlices.length > 1 ? nameSlices.at(-1)!.slice(0, 1) : "");
    }
  }

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  const derivedIntFromName = useCallback((name: string, maxIndex: number) => {
    return name.length % (maxIndex + 1);
  }, []);

  const nameBgColor = useMemo(
    () =>
      colorIndex === -1
        ? typeof image === 'string' ? derivedIntFromName(image, backgroundColors.length - 1) : 0
        : colorIndex,
    [derivedIntFromName, image, colorIndex]
  );

  return (
    <div
      css={cardElements.cardContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={containerRef}
    >
      <div
        css={css`
          ${cardElements.cardImageContainer};
          background-color: ${backgroundColors[nameBgColor]};
        `}
      >
        {imageIsName && typeof image === 'string' ? (
          <div css={cardElements.cardImage}>{initials}</div>
        ) : (
          <div css={cardElements.cardImage}>
            {typeof image === 'string' ? <img src={image} alt="" /> : image}
          </div>
        )}
      </div>
      <div css={cardElements.cardContent}>
        {title !== "" && <div css={cardElements.cardTitle}>{title}</div>}
        <div css={cardElements.cardDescription}>{description}</div>
      </div>
      {note !== "" && <div css={cardElements.cardNote}>{note}</div>}
      {actions && mouseOver && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            right: actionsWidth,
            width: 0,
          }}
        >
          <Slide
            direction="left"
            in={mouseOver}
            easing={{
              enter: "linear",
              exit: "linear",
            }}
            timeout={{ enter: 500 }}
            container={containerRef.current}
          >
            <div
              style={{
                height: "100%",
                width: actionsWidth,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {actions}
            </div>
          </Slide>
        </div>
      )}
    </div>
  );
};

export default HorizontalCard;
