import React, { useState, useMemo } from "react";
import {
  Button,
  TextField,
  Grid,
  FormControl,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { addSite, getTimeZonesList } from "../../../services/site/site.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SiteAddRequest } from "../../../types/Requests";
import {
  GetUsersListResponse,
  TimeZonesResponse,
} from "../../../types/Responses";
import { getUsersList } from "../../../services/user/user.service";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicModal from "../../reusable/modals/BasicModal";
import { useParams } from "react-router-dom";

interface CreateSiteProps {
  open: boolean;
  handleClose: () => void;
  onCreateSuccessCB: () => void;
}

const CreateSiteByUser: React.FC<CreateSiteProps> = ({
  open,
  handleClose,
  onCreateSuccessCB,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const { id } = useParams<{ id: string }>();
  console.log("id+++", id);

  // State to manage form validation errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  // Initial state for form fields
  const [siteForm, setSiteForm] = useState<SiteAddRequest>({
    name: "",
    idUsers: id || "",
    timeZoneId: "", // Ensure timeZoneId is included
    siteIdentifierForArc: "",
  });

  // Reset the form fields to their initial state
  const resetForm = () => {
    setSiteForm({
      name: "",
      idUsers: id || "",
      timeZoneId: "",
      siteIdentifierForArc: null,
    });
  };

  // Validate the form inputs
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!siteForm.name) newErrors.name = t("Name is required");
    // if (!siteForm.idUsers) newErrors.idUsers = t("Client is required");
    if (!siteForm.timeZoneId) newErrors.timeZoneId = t("Time zone is required");
    //if (!siteForm.siteIdentifierForArc) newErrors.siteIdentifierForArc = "PROM is required"

    // Add other validations here if necessary

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle input change for form fields
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setSiteForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle change for autocomplete fields
  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    value: any,
    field: string
  ) => {
    let selectedValue = "";
    if (field === "idUsers") {
      selectedValue = value ? value.id : ""; // Assuming id is the correct property for idUsers
    } else if (field === "timeZoneId") {
      selectedValue = value ? value.id : ""; // Assuming id is the correct property for timeZoneId
    } else {
      selectedValue = value || "";
    }

    setSiteForm((prevForm) => ({
      ...prevForm,
      [field]: selectedValue,
    }));
  };

  // Fetch users list using react-query
  const usersListQuery = useQuery<GetUsersListResponse>({
    queryKey: ["users"],
    queryFn: () => getUsersList({ sessionId }).then((res) => res.data),
  });

  // Memoize users list to avoid unnecessary re-renders
  const memoizedUsersList = useMemo(
    () => usersListQuery.data?.users || [],
    [usersListQuery.data]
  );
  console.log("first", memoizedUsersList);

  // Fetch timeZones list using react-query
  const TzListQuery = useQuery<TimeZonesResponse>({
    queryKey: ["time_zones"],
    queryFn: () => getTimeZonesList().then((res) => res.data),
  });

  // Memoize time zones list to avoid unnecessary re-renders
  const timeZonesArray = useMemo(
    () =>
      Object.entries(TzListQuery.data?.data || {}).map(([id, label]) => ({
        id: Number(id),
        label,
      })),
    [TzListQuery.data]
  );

  // UseMutation for adding a site
  const userMutation = useMutation({
    mutationFn: (request: SiteAddRequest) => addSite(request),
    onSuccess: () => {
      onCreateSuccessCB();
      dispatch(notifySuccess("Site added successfully"));
      resetForm();
    },
    onError: (error: any) => {
      if (error?.response?.data?.errors?.message === "Duplicated id site") {
        dispatch(notifyError("Site ID already exists"));
      } else {
        dispatch(notifyError("Failed to add a new site"));
      }
      console.error("Add site mutation error:", error);
    },
  });

  // Handle save site button click
  const handleSaveSite = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      try {
        // Create a partial request object and filter out undefined fields
        const request: SiteAddRequest = {
          name: siteForm.name,
          idUsers: id || "",
          timeZoneId: siteForm.timeZoneId,
          siteIdentifierForArc: siteForm.siteIdentifierForArc || null, // Use empty string if undefined
        };

        await userMutation.mutate(request);
      } catch (error) {
        console.error("Error adding site:", error);
      }
    }
  };

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      fullWidth
      title={t("Create New Site")}
      maxWidth="md"
      actions={
        <>
          <Typography
            variant="body2"
            style={{
              margin: "10px 0",
              fontSize: "0.8rem",
              marginRight: "auto",
              fontWeight: "bold",
            }}
          >
            {t("Fields with (*) are required.")}
          </Typography>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSaveSite} variant="contained" color="primary">
            {t("Save")}
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={t("Site Name")}
            name="name"
            value={siteForm.name}
            onChange={handleInputChange}
            fullWidth
            size="small"
            variant="outlined"
            required
            error={Boolean(errors.name)}
            helperText={errors.name || ""}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Autocomplete
            options={memoizedUsersList}
            getOptionLabel={(option: { userTag?: any; email: any }) => {
              // Ensure userTag and email are properly handled
              const userTag = option.userTag ?? "";
              const email = option.email ?? "";

              // Construct label with userTag and email
              let label = userTag; // Default to userTag if no email
              if (email) {
                // If both userTag and email are present, include both
                label = userTag ? `${userTag}, ${email}` : email;
              }

              return label;
            }}
            value={
              memoizedUsersList.find((user) => user.id === siteForm.idUsers) ||
              null
            }
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, "idUsers")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Select a client for this site")}
                name="idUsers"
                variant="outlined"
                size="small"
                fullWidth
                required
                error={Boolean(errors.idUsers)}
                helperText={errors.idUsers || ""}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" size="small" required>
            <Autocomplete
              options={timeZonesArray}
              getOptionLabel={(option) => option.label}
              value={
                timeZonesArray.find(
                  (tz) => tz.id === Number(siteForm.timeZoneId)
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange(event, value, "timeZoneId")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Please choose an option")}
                  name="timeZone"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  error={Boolean(errors.timeZoneId)}
                  helperText={errors.timeZoneId || ""}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("PROM")}
            name="siteIdentifierForArc"
            value={siteForm.siteIdentifierForArc}
            onChange={handleInputChange}
            fullWidth
            error={Boolean(errors.site_identifier_for_arc)}
            helperText={
              errors.site_identifier_for_arc ||
              t(
                "This parameter is used when connecting this site to an alarm receiving center"
              )
            }
            size="small"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </BasicModal>
  );
};

export default CreateSiteByUser;
