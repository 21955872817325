import axios from "axios";
import getConfiguration from "../../config/config";
import {
  CameraAddRequest,
  CameraDetailsRequest,
  DeleteCameraRequest,
  GetCameraListRequest,
} from "../../types/Requests";
import {
  CameraDetailsResponse,
  ArmScheduleResponse,
} from "../../types/Responses";

import { APIPaths } from "../paths";
import { getUserSession } from "../../utils/auth";

/**
 * Retrieves a list of cameras based on the provided filter criteria.
 * @param {GetCameraListRequest} request - Filter criteria for cameras.
 * @returns {Promise} Promise containing the list of cameras.
 */
const getCamerasList = (request: GetCameraListRequest) => {
  // type
  const configuration = getConfiguration();
  //It retrieves the configuration object using the getConfiguration function.

  return axios.post(configuration.apiPath + APIPaths.cameraList, {
    ...request,
  });
};

/**
 * Retrieves detailed information about a specific camera.
 * @returns {Promise<CameraDetailsResponse>} Promise containing detailed information about the camera.
 */

const getCamerasDetails = async (
  request: CameraDetailsRequest
): Promise<CameraDetailsResponse> => {
  const configuration = getConfiguration(); 
  try {
    const response = await axios.post<CameraDetailsResponse>(
      `${configuration.apiPath}${APIPaths.cameraDetails}`,
      request
    );
    console.log("Camera details:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching camera details:", error);
    throw error;
  }
};

/**
 * Retrieves the arm schedule details for a specific camera.
 * @param {string} cameraId - The ID of the camera for which to fetch the arm schedule.
 * @param {string} sessionId - The session ID for authentication purposes.
 * @returns {Promise<ArmScheduleResponse>} Promise containing the arm schedule details.
 */
const getArmScheduleForCamera = async (
  cameraId: string,
  sessionId: string
): Promise<ArmScheduleResponse> => {
  const configuration = getConfiguration();

  try {
    const response = await axios.get<ArmScheduleResponse>(
      `${configuration.apiPath}${APIPaths.armSchedule}`,
      {
        params: {
          cameraId,
          sessionId,
        },
      }
    );
    console.log("Arm schedule:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching arm schedule:", error);
    throw error;
  }
};

/**

 * @param {CameraAddRequest} request 
 * @returns {Promise} Promise containing the added camera.
 */
const addCamera = (request: CameraAddRequest) => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.cameraAdding, {
    ...request,
  });
};

/**
 * @param {DeleteCameraRequest} request - Delete Camera request data.
 * @returns {Promise} Promise indicating the success of the operation.
 */
const deleteCamera = (request: DeleteCameraRequest) => {
  const configuration = getConfiguration();

  return axios.post(
    configuration.apiPath + APIPaths.cameraDelete,
    request,  // Le corps de la requête contient les données nécessaires à la suppression
    {
      params: {
        sessionId: getUserSession(),  // sessionId passé comme paramètre dans l'URL
      },
      headers: {
        'Content-Type': 'application/json',  // Spécifie le type de contenu envoyé
        'Accept': 'application/json',  // Spécifie le format de réponse attendu
      },
    }
  );
};


export {
  getCamerasDetails,
  getArmScheduleForCamera,
  getCamerasList,
  addCamera,
  deleteCamera,
};
