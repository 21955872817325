import React from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { GetUsersListResponse } from "../../../types/Responses";
import { getUsersList } from "../../../services/user/user.service";
import ClientSection from "./ClientSection/ClientSection";
import CameraListByUser from "./CameraListByUser/CameraListByUser";
import { useAppSelector } from "../../../store/hooks";
import SiteListByUser from "./SiteListByUser/SiteListByUser";

function ClientDetailsPage() {
  // Extract the client ID from the URL parameters
  const { id } = useParams<{ id: string }>();
  const sessionId = useAppSelector((state) => state.user.sessionId);

  //  Query to fetch the list of users
  const usersListQuery = useQuery<GetUsersListResponse>({
    queryKey: ["users", id], // Query key for identification in the cache
    queryFn: () => getUsersList({ sessionId }).then((res) => res.data),
  });

  // // Memoize the users list to avoid unnecessary recalculations
  const memoizedUsersList = React.useMemo(
    () => usersListQuery.data?.users || [],
    [usersListQuery.data] // Recalculate only when query data changes
  );

  return (
    <Box mt={3} marginTop={15}>
      <ClientSection memoizedUsersList={memoizedUsersList} />
    
 

  <SiteListByUser />

      <CameraListByUser
        usersList={usersListQuery.data}
        memoizedUsersList={memoizedUsersList}
      />
    </Box>
  );
}

export default ClientDetailsPage;
