import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/hooks";
import { TimeZonesResponse } from "../../../types/Responses";
import {
  getTimeZonesList,
  updateSite,
} from "../../../services/site/site.service";
import { SiteUpdateRequest } from "../../../types/Requests";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicModal from "../../reusable/modals/BasicModal";

interface EditSiteProps {
  open: boolean;
  handleClose: () => void;

  onCreateSuccessCB: () => void; // Callback for successful update
  siteData: {
    site_name: string;
    siteIdentifierForArc: string | null;
    timeZoneId: number;
    text: string;
    address: string;
  };
  sessionId: string;
  siteId: string;
  timeZoneId: number;
}
const EditSite: React.FC<EditSiteProps> = ({
  open,
  handleClose,
  onCreateSuccessCB,
  siteData,
  siteId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // State to manage dialog and user data
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: siteData,
    errors: {
      timeZoneId: false,
    },
  });

  // Update state with the received userData whenever it changes
  useEffect(() => {
    setEditDialog((prevState) => ({
      ...prevState,
      //data: siteData,
    }));
  }, [siteData]);

  // Handle input changes for the form
  const onEditSiteFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditDialog((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  // Fetch timeZones list using react-query
  const TzListQuery = useQuery<TimeZonesResponse>({
    queryKey: ["time_zones"],
    queryFn: () => getTimeZonesList().then((res) => res.data),
  });

  // Memoize time zones list to avoid unnecessary re-renders
  const timeZonesArray = useMemo(
    () =>
      Object.entries(TzListQuery.data?.data || {}).map(([id, label]) => ({
        id: Number(id),
        label,
      })),
    [TzListQuery.data]
  );

  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    value: { id: number; label: string } | null,
    field: string
  ) => {
    setEditDialog((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [field]: value ? value.id : "",
      },
      errors: {
        ...prev.errors,
        [field]: !value,
      },
    }));
  };

  // Function for updating the user
  const handleUpdateSite = async () => {
    const request: SiteUpdateRequest = {
      idSite: siteId,
      name: editDialog.data.site_name,
      siteIdentifierForArc: editDialog.data.siteIdentifierForArc|| null,
      text: editDialog.data.text,
      address: editDialog.data.address,
      timeZoneId: editDialog.data.timeZoneId,
    };

    try {
      await updateSite(request);
      onCreateSuccessCB();
      dispatch(notifySuccess(t("Site updated successfully")));
      handleClose();
    } catch (error: any) {
      console.error("Full error object:", error);
      dispatch(notifyError(t("Error when updating site")));
    }
  };

  useEffect(() => {
    setEditDialog((prevState) => ({
      ...prevState,
      data: siteData,
      errorMessage: "",
    }));
  }, [siteData, open]);

  return (
    <BasicModal
      title={t("Edit Site")}
      maxWidth="md"
      open={open}
      fullWidth
      handleClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleUpdateSite}
            variant="contained"
            color="primary"
          >
            {t("Save")}
          </Button>{" "}
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={`${t("siteName")} `}
            name="site_name"
            value={editDialog.data.site_name}
            onChange={onEditSiteFormChange}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={`${t("prom")} `}
            name="siteIdentifierForArc"
            value={editDialog.data.siteIdentifierForArc || ""}
            onChange={onEditSiteFormChange}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" size="small" required>
            <Autocomplete
              options={timeZonesArray}
              getOptionLabel={(option) => option.label}
              value={
                timeZonesArray.find(
                  (tz) => tz.id === Number(editDialog.data.timeZoneId)
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange(event, value, "timeZoneId")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Please choose an option")}
                  name="timeZone"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  error={editDialog.errors.timeZoneId} // Affiche l'erreur si true
                  helperText={
                    editDialog.errors.timeZoneId
                      ? t("Timezone is required")
                      : ""
                  }
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Texte Personnalise")}
            name="text"
            value={editDialog.data.text}
            onChange={onEditSiteFormChange}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("adressSite")}
            name="address"
            value={editDialog.data.address}
            onChange={onEditSiteFormChange}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </BasicModal>
  );
};

export default EditSite;
