import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  Typography,
  Switch,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { UserAddRequest } from "../../../types/Requests";
import { Autocomplete } from "@mui/material";
import { addUser } from "../../../services/user/user.service";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/hooks";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicModal from "../../reusable/modals/BasicModal";

const countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

interface CreateClientProps {
  open: boolean;
  handleClose: () => void;
  onCreateSuccessCB: () => void;
}

const CreateClient: React.FC<CreateClientProps> = ({
  open,
  handleClose,
  onCreateSuccessCB,
}) => {
  // Translation function from react-i18next
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  // State to manage form validation errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // State to manage the client form inputs
  const [clientForm, setClientForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    mobile: "",
    address: "",
    address2: "",
    city: "",
    postalCode: "",
    country: "France",
    isCompany: false,
    companyName: "",
    companyIdentification: "",
    vatNumber: "",
  });

  // Reset the form fields to their initial state
  const resetForm = () => {
    setClientForm({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      mobile: "",
      address: "",
      address2: "",
      city: "",
      postalCode: "",
      country: "France",
      isCompany: false,
      companyName: "",
      companyIdentification: "",
      vatNumber: "",
    });
  };

  // Validate the form inputs
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!clientForm.firstName)
      newErrors.firstName = t("First name is required");
    if (!clientForm.lastName) newErrors.lastName = t("Last name is required");
    if (!clientForm.email) newErrors.email = t("Email is required");
    if (!clientForm.country) newErrors.country = t("Country is required");

    // Add other validations here if necessary

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle change for text inputs
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setClientForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle change for checkbox inputs
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setClientForm((prevForm) => ({
      ...prevForm,
      [name]: checked,
    }));
  };

  // Handle change for the country selection field
  const handleCountryChange = (event: any, value: string | null) => {
    setClientForm((prevForm) => ({
      ...prevForm,
      country: value || "",
    }));
  };

  // useMutation hook for adding a new user
  // const userMutation = useMutation({
  //   mutationFn: (request: UserAddRequest) => {
  //     return addUser(request);
  //   },
  //   onSuccess: () => {
  //     onCreateSuccessCB();
  //     resetForm();
  //     dispatch(notifySuccess("client added successfully"));
  //   },
  //   onError: (error) => {
  //     dispatch(notifyError("failed to add a new client"));
  //     console.error("Add user mutation error:", error);
  //   },
  // });

  const userMutation = useMutation({
    mutationFn: (request: UserAddRequest) => {
      return addUser(request);
    },
    onSuccess: (data) => {
      // Vérifiez si la réponse contient une erreur, même si le statut est OK
      if (data?.error === "Login already exists.") {
        // Afficher une notification d'erreur et stopper le processus
        dispatch(notifyError(t("Login already exists")));
      } else {
        // Si aucune erreur, procéder au succès
        onCreateSuccessCB();
        resetForm();
        dispatch(notifySuccess(t("Client added successfully")));
      }
    },
    onError: (error) => {
      // Gérer les autres erreurs qui peuvent provenir du réseau, etc.
      dispatch(notifyError(t("Failed to add a new client")));
      console.error("Add user mutation error:", error);
    },
  });

  // Handle saving the client
  const handleSaveClient = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      try {
        await userMutation.mutate(clientForm);
        console.log("zzz", clientForm);

        handleClose();
      } catch (error) {
        console.error("Error adding client:", error);
      }
    }
  };

  return (
    <BasicModal
      title={t("Create New Client")}
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      fullWidth
      actions={
        <>
          <Typography
            variant="body2"
            style={{
              margin: "10px 0",
              fontSize: "0.8rem",
              marginRight: "auto",
              fontWeight: "bold",
            }}
          >
            {t("Fields with (*) are required.")}
          </Typography>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleSaveClient}
            variant="contained"
            color="primary"
          >
            {t("Save")}
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t("firstName")} `}
            name="firstName"
            value={clientForm.firstName}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
            required
            error={Boolean(errors.firstName)}
            helperText={errors.firstName || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t("lastName")} `}
            name="lastName"
            value={clientForm.lastName}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
            required
            error={Boolean(errors.lastName)}
            helperText={errors.lastName || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t("email")} `}
            name="email"
            value={clientForm.email}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
            type="email"
            required
            error={Boolean(errors.email)}
            helperText={errors.email || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("phone")}
            name="phone"
            value={clientForm.phone}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("mobile")}
            name="mobile"
            value={clientForm.mobile}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("address")}
            name="address"
            InputLabelProps={{ shrink: true }}
            value={clientForm.address}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("address2")}
            name="address2"
            InputLabelProps={{ shrink: true }}
            value={clientForm.address2}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("city")}
            name="city"
            InputLabelProps={{ shrink: true }}
            value={clientForm.city}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("postalCode")}
            name="postalCode"
            InputLabelProps={{ shrink: true }}
            value={clientForm.postalCode}
            onChange={handleChange}
            fullWidth
            size="small"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={countries}
            value={clientForm.country}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("country")}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>

        {/* Affichage du champ "isCompany" à côté du label */}
        {/* <Grid container spacing={2} sx={{ marginTop: 0.5, marginLeft:2}}>
  {/* Ligne du Switch */}
        {/* <Grid item xs={12} sm={6} display="flex" alignItems="center" sx={{marginLeft:-1}}>
    <FormLabel component="legend" style={{ marginRight: "10px", fontSize:12 }}>
      {t("Client est une société? (Les sociétés sont facturées hors TVA)")}
    </FormLabel>
    <Switch
      {...label}
      checked={clientForm.isCompany}
      onChange={handleCheckboxChange}
      name="isCompany"
    />
  </Grid>

  {/* Champs affichés conditionnellement en dessous */}
        {/* {clientForm.isCompany && (
    <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t("companyName")}
          name="companyName"
          value={clientForm.companyName}
          onChange={handleChange}
          fullWidth
          size="small"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t("companyIdentification")}
          name="companyIdentification"
          value={clientForm.companyIdentification}
          onChange={handleChange}
          fullWidth
          size="small"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t("vatNumber")}
          name="vatNumber"
          value={clientForm.vatNumber}
          onChange={handleChange}
          fullWidth
          size="small"
          variant="outlined"
        />
      </Grid>
    </Grid>
  )} */}
        {/* </Grid>  */}
      </Grid>
    </BasicModal>
  );
};

export default CreateClient;
