import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import paths from "../../routes/Paths";
import { matchRoutes } from "react-router-dom";

// Styles for the links in the sidebar
const linkStyle = {
  marginTop: "10px",
  height: "40px",
  cursor: "pointer",
  borderRadius: "0 10px 10px 0",
  fontWeight: 400,
  fontSize: "1rem",
  //borderRadius: "10px",
  backgroundColor: "#34495e ",
  display: "flex",
  alignItems: "center",
  color: "white",
  textDecoration: "none",
  transition: "background-color 0.3s ease, transform 0.3s ease",
};

// Styles for active links
const activeLinkStyle = {
  ...linkStyle,
  backgroundColor: "#3498DB" ,
};

// Styles for hovered links
const hoverStyle = {
  backgroundColor: "#3498DB",
};

// Styles for hovered active links
const activeHoverStyle = {
  backgroundColor: "#3498DB",
};
const Sidebar: React.FC = () => {
  //Hooks to get the current location and for translation
  const location = useLocation();
  const { t } = useTranslation();

  // State to manage sidebar open/close
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  // Match the current route with the defined paths
  const matches = matchRoutes(paths, location.pathname) || [];
  // State to manage the hovered link
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);

  // Handle mouse enter event to set & unset the hovered link
  const handleMouseEnter = (path: string) => {
    setHoveredLink(path);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  // Toggle sidebar open/close state
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box
      bgcolor="#395069"
      p={2}
      position="fixed"
      left={0}
      top={65}
      height="100%"
      width={isSidebarOpen ? 185 : 60}
      style={{ transition: "width 0.3s ease" }}
    >
      {isSidebarOpen && (
        <Typography
          variant="h6"
          sx={{
            color: "#E0F7FA",
            marginBottom: "37px", 
            marginTop: "20px", 
            fontSize: "1.25rem", 
            fontWeight: 549,
            textAlign: "center", 
            letterSpacing: "0.5px", 
            textTransform: "uppercase"
          }}
        >
          IMA PROTECT
        </Typography>
      )}

      {paths
        .filter((path) => path.navVisible)
        .map((path) => {
          const isActive = matches.some(
            (match) => match.route.selectedRoute === path.path
          );

          return (
            <Link
              key={path.path}
              to={path.path}
              style={{
                ...linkStyle,
                ...(isActive ? activeLinkStyle : {}),
                ...(hoveredLink === path.path && !isActive ? hoverStyle : {}),
                ...(hoveredLink === path.path && isActive
                  ? activeHoverStyle
                  : {}),
                justifyContent: isSidebarOpen ? "flex-start" : "center",
              }}
              onMouseEnter={() => handleMouseEnter(path.path)}
              onMouseLeave={handleMouseLeave}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                marginLeft={isSidebarOpen ? 1 : 0}
              >
                {path.icon}
                {isSidebarOpen && (
                  <Typography style={{ fontSize: "15.3px" }}>
                    {t(path.name)}
                  </Typography>
                )}
              </Stack>
            </Link>
          );
        })}

      <IconButton
        onClick={toggleSidebar}
        style={{
          position: "absolute",
          top: "54%",
          left: isSidebarOpen ? "calc(100% - 30px)" : "100%",
          transform: isSidebarOpen
            ? "translateY(-50%)"
            : "translate(-50%, -50%)",
          backgroundColor: "#2C3E50",
          color: "#ECF0F1",
          borderRadius: "50%",
          width: "35px",
          height: "35px",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
          transition: "left 0.3s ease, transform 0.3s ease",
        }}
      >
        {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
    </Box>
  );
};

export default Sidebar;
/*
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import paths from "../../routes/Paths";
import { matchRoutes } from "react-router-dom";


// Define props for the Sidebar
interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}
// Styles for the links in the sidebar
const linkStyle = {
  marginTop: "10px",
  height: "40px",
  cursor: "pointer",
  borderRadius: "0 10px 10px 0",
  fontWeight: 400,
  fontSize: "1rem",
  //borderRadius: "10px",
  backgroundColor: "#34495e ",
  display: "flex",
  alignItems: "center",
  color: "white",
  textDecoration: "none",
  transition: "background-color 0.3s ease, transform 0.3s ease",
};

// Styles for active links
const activeLinkStyle = {
  ...linkStyle,
  backgroundColor: "#3498DB" /*"#2C3E50",
};

// Styles for hovered links
const hoverStyle = {
  backgroundColor: "#3498DB",
};

// Styles for hovered active links
const activeHoverStyle = {
  backgroundColor: "#3498DB",
};
const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, toggleSidebar }) => {
  //Hooks to get the current location and for translation
  const location = useLocation();
  const { t } = useTranslation();

  // State to manage sidebar open/close

  // Match the current route with the defined paths
  const matches = matchRoutes(paths, location.pathname) || [];
  // State to manage the hovered link
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);

  // Handle mouse enter event to set & unset the hovered link
  const handleMouseEnter = (path: string) => {
    setHoveredLink(path);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  
  return (
    <Box
      bgcolor="#395069"
      p={2}
      position="fixed"
      left={0}
      top={64.7}
      height="100%"
      width={isSidebarOpen ? 185 : 60}
      style={{ transition: "width 0.3s ease" }}
    >
      {isSidebarOpen && (
        <Typography
          variant="h6"
          style={{ color: "#E0F7FA", marginBottom: "33px", fontSize: 16 }}
        >
          IMAPROTECT
        </Typography>
      )}

      {paths
        .filter((path) => path.navVisible)
        .map((path) => {
          const isActive = matches.some(
            (match) => match.route.selectedRoute === path.path
          );

          return (
            <Link
              key={path.path}
              to={path.path}
              style={{
                ...linkStyle,
                ...(isActive ? activeLinkStyle : {}),
                ...(hoveredLink === path.path && !isActive ? hoverStyle : {}),
                ...(hoveredLink === path.path && isActive
                  ? activeHoverStyle
                  : {}),
                justifyContent: isSidebarOpen ? "flex-start" : "center",
              }}
              onMouseEnter={() => handleMouseEnter(path.path)}
              onMouseLeave={handleMouseLeave}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                marginLeft={isSidebarOpen ? 1 : 0}
              >
                {path.icon}
                {isSidebarOpen && (
                  <Typography style={{ fontSize: "15.3px" }}>
                    {t(path.name)}
                  </Typography>
                )}
              </Stack>
            </Link>
          );
        })}

      <IconButton
        onClick={toggleSidebar}
        style={{
          position: "absolute",
          top: "54%",
          left: isSidebarOpen ? "calc(100% - 30px)" : "50%",
          transform: isSidebarOpen
            ? "translateY(-50%)"
            : "translate(-50%, -50%)",
          backgroundColor: "#2C3E50",
          color: "#ECF0F1",
          borderRadius: "50%",
          width: "35px",
          height: "35px",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
          transition: "left 0.3s ease, transform 0.3s ease",
        }}
      >
        {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
    </Box>
  );
};

export default Sidebar;*/

