/**
 * Enum defining API paths for various  endpoints.
 */
export enum APIPaths {
  // Camera-related paths
  cameraDetails = "/SDK_V2/cameraGetSettings",
  armSchedule = "/armschedule",
  cameraList = "/SDK_SGBD_V3/getCameraListV2",
  cameraAdding = "/SDK_V2/addCamera",
  cameraDelete = "/SDK_SGBD_V3/unregisterCamera",
  cameraUpdate = "/SDK_V2/updateCamera",

  // User authentication and team management paths
  login = "/SDK_SGBD_V3/openUserCompanySession",
  changePassword = "/SDK_SGBD_V3/updateInstallerTeamMemberAccess",
  installerTeam = "/SDK_SGBD_V3/getInstallerTeam",
  deleteTeamMember = "/SDK_SGBD_V3/deleteInstallerTeamMember",
  installerTeamRoles = "/SDK_SGBD_V3/getInstallerTeamRoles",
  updateTeamRolePermissions = "/SDK_SGBD_V3/editInstallerTeamRolePermissions",
  updateTeamMemberRole = "/SDK_SGBD_V3/editInstallerTeamMemberRole",
  askForReset = "/SDK_SGBD_V3/askForPasswordResetV2",
  userCompanyProfile = "/SDK_SGBD_V3/getUserCompanyProfile",
  updateEmail = "/SDK_SGBD_V3/updateUserCompanyCredentials",
  checkResetToken = "/SDK_SGBD_V3/checkResetToken",
  resetPassword = "/SDK_SGBD_V3/resetPasswordV2",

  //Client-related paths
  usersList = "/users",
  userAdd = "/users",
  userDetails = "/CameraUsers/GetCameraListByUser",
  userUpdate = "/SDK_V2/setUser",

  // Site-related paths
  siteAdd = "/SDK_V2/addSite",
  timeZoneList = "/SDK_SGBD_V3/getTimeZoneName",
  siteList = "/SDK_SGBD_V3/getSiteList",
  siteDelete = "/SDK_SGBD_V3/deleteSite",
  camerasBySite = "/SDK_SGBD_V3/getCameraListV2",
  siteUpdate = "/SDK_V2/AcobaSgbdP/updateSite",

  //Alarm-related paths
  alarmList = "/SDK_SGBD_V3/getAlarmList",
  sessionsOpList = "/SDK_SGBD_V3/getOperatorsSessionList",
  videoAlarm = "/SDK_SGBD_V3/getVideoAlarm",
}
