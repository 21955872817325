import React, { useState, useMemo, useEffect } from "react";
import { Box, AppBar, Toolbar, Typography, Button, Grid } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteCamera,
  getCamerasList,
} from "../../../services/camera/camera.service";
import { CamerasListResponse } from "../../../types/Responses";
import { getUsersList } from "../../../services/user/user.service";
import { GetUsersListResponse } from "../../../types/Responses";
import SearchInput from "../../reusable/inputs/SearchInput";
import {
  DeleteCameraRequest,
  GetCameraListRequest,
} from "../../../types/Requests";
import { WifiTethering } from "@mui/icons-material";
import PortableWifiOffIcon from "@mui/icons-material/PortableWifiOff";
import "./MesCameras.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import CustomPagination from "../../reusable/tables/CustomPagination";
import BasicModal from "../../reusable/modals/BasicModal";
import { invalidateSession } from "../../../store/reducers/user.slice";

interface CameraData {
  camera_id: string;
  subscription_id: string;
  mac_address: string;
  serial_number: string;
  camera_model: string;
  status_connected: string;
  camera_name: string;
  time_zone_id: string;
  status_record: number;
  prom: string;
  last_connection_time?: string; // Propriété optionnelle
  last_restart_time?: string;
  user_tag?: string;
}

type FilterData = {
  NumeroDeSerie: string;
  Utilisateur: string;
  prom: string;
};

const MesCamerasPage: React.FC = () => {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);
  const dispatch = useAppDispatch();

  // initial state
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const [optionalColumns, setOptionalColumns] = useState({
    fabricantCamera: false,
    versionPlugin: false,
    numeroSerie: false,
  });
 
  // État pour la confirmation de suppression
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    data: DeleteCameraRequest | null; // Utiliser le type DeleteCameraRequest ici
  }>({
    open: false,
    data: null, // Initialiser avec null ou un objet par défaut
  });

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  //displayed filter GUI control
  const [filterData, setFilterData] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
  });
  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
  });

   // useEffect pour récupérer la taille de page depuis localStorage
   useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPagination((prev) => ({ ...prev, pageSize: Number(storedPageSize) }));
    }
  }, []); 

  //GUI filter change
  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };
  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };
  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.NumeroDeSerie !== next.NumeroDeSerie ||
      current.Utilisateur !== next.Utilisateur ||
      current.prom !== next.prom
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  // const { data: camerasData, isLoading } = useQuery<CamerasListResponse>({
  //   queryKey: ["cameras", sessionId, searchFilter, pagination],
  //   queryFn: async () => {
  //     const filterApplied: GetCameraListRequest = {
  //       sessionId,
  //       page_number: pagination.page, // this aligns with your API's page numbering
  //       max_results: pagination.pageSize,
  //     };

  //     // Add filters only if they are not empty
  //     if (searchFilter.NumeroDeSerie !== "") {
  //       filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
  //     }
  //     if (searchFilter.Utilisateur !== "") {
  //       filterApplied.user_tag_like = searchFilter.Utilisateur;
  //     }
  //     if (searchFilter.prom !== "") {
  //       filterApplied.site_identifier_for_arc_like = searchFilter.prom;
  //     }

  //     // Call the getCamerasList function with the filters applied
  //     return getCamerasList(filterApplied).then((response) => {
  //       return response.data;
  //     });
  //   },
  // });


  const { data: camerasData, isLoading } = useQuery<CamerasListResponse>({
    queryKey: ["cameras", sessionId, searchFilter, pagination],
    queryFn: async () => {
      const filterApplied: GetCameraListRequest = {
        sessionId,
        page_number: pagination.page, // this aligns with your API's page numbering
        max_results: pagination.pageSize,
      };

      // Add filters only if they are not empty
      if (searchFilter.NumeroDeSerie !== "") {
        filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
      }
      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }
      if (searchFilter.prom !== "") {
        filterApplied.site_identifier_for_arc_like = searchFilter.prom;
      }
      try {
        const response = await getCamerasList(filterApplied);
  
        // Vérifiez si la réponse contient une erreur
        if (response.data.errors) {
          const { code, message } = response.data.errors;
  
          // Si le code d'erreur est -37, traiter comme une session expirée
          if (code === "-37") {
            dispatch(notifyError(t("sessionExpiredMessage")));
            dispatch(invalidateSession());
            return;
          }
  
          // Autres types d'erreurs peuvent être traités ici
          throw new Error(`Erreur API : ${message}`);
        }
        return response.data; // Retourner les données de l'API si aucune erreur
      } catch (err) {
        console.error("API Request Error:", err);
        throw err; 
      }
    },
  });
  

  // Meorizing camera list
  const camerasList = useMemo(() => camerasData?.data || [], [camerasData]);

  //Get users list
  const usersListQuery = useQuery<GetUsersListResponse>({
    queryKey: ["users"],
    queryFn: () => getUsersList({ sessionId }).then((res) => res.data),
  });

  // Memoize users list
  const memoizedUsersList = React.useMemo(
    () => usersListQuery.data?.users || [],
    [usersListQuery.data]
  );
  console.log("memorr", memoizedUsersList);

  // Handles a click event on a row in the data grid.
  const handleRowClick = (params: { row: CameraData }): void => {
    const cameraId = params.row?.camera_id;
    if (cameraId) {
      navigate(`/camera/single/details/${cameraId}`); // Naviguer vers la page des détails du client
    }
  };

  //Determines the CSS class name to apply to a row based on its index.
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  // Function to find the selected camera
  const findSelectedCamera = (cameraId: string): CameraData | null => {
    console.log("Searching for camera with ID:", cameraId);
    const camera = camerasList.find((camera) => camera.camera_id === cameraId);
    console.log("Found camera:", camera);
    return camera || null;
  };


  // Fonction pour ouvrir la boîte de dialogue de confirmation avec les données nécessaires
  const handleDelete = (event: React.MouseEvent, cameraId: string) => {
    event.stopPropagation();
    const selectedCamera = camerasList.find(
      (camera) => camera.camera_id === cameraId
    );

    if (selectedCamera) {
      // Remplir les données de confirmation avec les informations requises
      setDeleteConfirmation({
        open: true,
        data: {
          subscription_id: selectedCamera.subscription_id || "", // Assurez-vous que cela est bien défini
          camera_identification: cameraId, // Utiliser cameraId pour identification
        },
      });
    }
  };
  
  //Determine the row count
  const totalItems = camerasData?.total ?? 0;
  const totalPages = camerasData?.has_more
    ? pagination.page + 1
    : pagination.page;
  const isLastPage = camerasData?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // Standard case

  // Define base columns
  const dynamicColumns: GridColDef[] = [
    {
      field: "serial_number",
      headerName: t("numeroSerie"),
      flex: 1.7,
      sortable:false,
      disableColumnMenu: true,
      // minWidth: 140,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("numeroSerie")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.serial_number}</span>
        </div>
      ),
    },
    {
      field: "mac_address",
      headerName: t("adresseMac"),
      flex: 1.62,
      sortable:false,
      disableColumnMenu: true,
      //minWidth: 160,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("adresseMac")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.mac_address}</span>
        </div>
      ),
    },
    {
      field: "camera_model",
      headerName: t("modeleCamera"),
      flex: 2.4,
      sortable:false,
      disableColumnMenu: true,
      // minWidth: 200,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("modeleCamera")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.camera_model}</span>
        </div>
      ),
    },
    {
      field: "camera_name",
      headerName: t("nomCamera"),
      flex: 2,
      sortable:false,
      disableColumnMenu: true,
      // minWidth: 200,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("nomCamera")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.camera_name}</span>
        </div>
      ),
    },
    {
      field: "prom",
      headerName: t("Prom"),
      flex: 1.4,
      sortable:false,
      disableColumnMenu: true,
      // minWidth: 200,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Prom")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.prom}</span>
        </div>
      ),
    },
    {
      field: "user_tag",
      headerName: t("Utilisateur"),
      flex: 1.7,
      sortable:false,
      disableColumnMenu: true,
      //minWidth: 200,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Utilisateur")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.user_tag}</span>
        </div>
      ),
    },
    {
      field: "date_of_creation",
      headerName: t("dateInstallation"),
      flex: 1.9,
      sortable:false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("dateInstallation")}
        </span>
      ),
      renderCell: (params) => {
        const rawDate = params.row?.date_of_creation;
    
        if (!rawDate) return <span style={{ fontSize: "12px" }}>-</span>;
    
        const utcDate = new Date(rawDate + " UTC"); // Convertir en UTC
        const localDate = utcDate.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
    
        return <span style={{ fontSize: "12px" }}>{localDate}</span>;
      },
    },
    {
      field: "plugin_version",
      headerName: t("plugin_version"),
      flex: 1.6,
      sortable:false,
      disableColumnMenu: true,
      //minWidth: 170,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("plugin_version")}
        </span>
      ),

      renderCell: (params) => (
        <div>
          <span>{params.row?.plugin_version}</span>
        </div>
      ),
    },
    {
      field: "last_Cnx_Tmp",
      headerName: t("lastConnection"),
      flex: 2,
      sortable:false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("lastConnection")}
        </span>
      ),
      renderCell: (params) => {
        const rawDate = params.row?.last_Cnx_Tmp;
    
        if (!rawDate) return <span style={{ fontSize: "12px" }}>-</span>; 
    
        const utcDate = new Date(rawDate + " UTC"); // Convertir en UTC
        const localDate = utcDate.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
    
        return <span style={{ fontSize: "12px" }}>{localDate}</span>;
      },
    },
    {
      field: "last_Updated_Time",
      headerName: t("lastUpdated_Time"),
      flex: 2.1,
      sortable:false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("lastUpdated_Time")}
        </span>
      ),
      renderCell: (params) => {
        const rawDate = params.row?.last_Updated_Time;
    
        if (!rawDate) return <span style={{ fontSize: "12px" }}>-</span>;
    
        const utcDate = new Date(rawDate + " UTC"); 
        const localDate = utcDate.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
    
        return <span style={{ fontSize: "12px" }}>{localDate}</span>;
      },
    }
    ,
    {
      field: "status_connected",
      headerName: t("statutConnecte"),
      sortable:false,
      disableColumnMenu: true,
      //minWidth: 100,
      flex: 1,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("statutConnecte")}
        </span>
      ),

      renderCell: (params) => (
        <Box
          sx={{
            color:
              params.row?.status_connected === "1"
                ? "rgb(40, 167, 69)"
                : "rgba(255, 0, 0, 0.5)", // Couleur plus douce
            textAlign: "center",
          }}
        >
          {params.row?.status_connected === "1" ? (
            <WifiTethering sx={{ fontSize: 16 }} />
          ) : (
            <PortableWifiOffIcon sx={{ fontSize: 16 }} />
          )}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: t("Actions"),
      flex: 0.9,
      minWidth: 90,
      headerClassName: "custom-header",
      cellClassName: "custom-cell",
      renderHeader: () => (
        <span style={{ fontWeight: "bold", color: "#395069" }}>
          {t("Actions")}
        </span>
      ),
      renderCell: (params) => (
        <strong>
          {permissions.includes("deleteCamera") && (
            <Button
              variant="outlined"
              sx={{ color: "#5585b5", fontSize: 8, width: 20}}
              onMouseEnter={() => setHoveredRowId(params.row?.camera_id)}
              onMouseLeave={() => setHoveredRowId(null)}
              onClick={(event) => handleDelete(event, params.row.camera_id)}
            >
              {hoveredRowId === params.row?.camera_id ? (
                t("Delete")
              ) : (
                <DeleteIcon />
              )}
            </Button>
          )}
        </strong>
      ),
    },
  ];

  //Mutation to delete a camera
  const deleteCameraMutation = useMutation({
    mutationFn: (request: DeleteCameraRequest) => {
      return deleteCamera(request);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("Camera deleted successfully")));
      queryClient.invalidateQueries({ queryKey: ["cameras"] });
    },
    onError: (error) => {
      dispatch(notifyError(t("Failed to delete the camera")));
      console.error(error);
    },
  });

 
  const handleConfirmDelete = async () => {
    if (deleteConfirmation.data) {
      // Appel de la mutation pour supprimer la caméra
      deleteCameraMutation.mutate(deleteConfirmation.data);

      // Fermer la boîte de confirmation
      handleCloseConfirmation();
    }
  };

  const handleCloseConfirmation = () => {
    setDeleteConfirmation({ open: false, data: null }); // Réinitialiser l'état
  };



  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 29.5,
          marginTop: 12,
          width: "calc(100% - 252px)",
          height: 55,
          borderRadius: "4px",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("myCameras")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <SearchInput
          placeHolder={t("Filtrer par numéro de série")}
          searchLabel={t("Filtrer par numéro de série")}
          fieldName="NumeroDeSerie"
          value={filterData.NumeroDeSerie}
          valueChange={changeFilterData}
          size="small"
          width="85%"
        />
        <SearchInput
          placeHolder={t("Filtrer par PROM")}
          searchLabel={t("Filtrer par PROM")}
          fieldName="prom"
          value={filterData.prom}
          valueChange={changeFilterData}
          size="small"
          width="85%"
        />
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          width="85%"
        />
      </Box>
      <Grid
        sx={{
          flexGrow: 1,
          marginLeft: "235px",
          width: "85.5%",
          marginBottom: 30,
        }}
      >
        <DataGrid
          //autoHeight
          columns={dynamicColumns}
          rows={camerasList}
          loading={isLoading}
          rowCount={rowCount}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          getRowId={(row: CameraData) => row.camera_id}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
            // Enregistrer la taille de la page dans localStorage
            localStorage.setItem("pageSize", model.pageSize.toString());
          }}
          //className={classes.root}
          getRowClassName={getRowClassName}
          onRowClick={handleRowClick}
          className="custom-scrollbar"
          sx={{
            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            marginBottom: 10,
            height: "calc(100vh - 300px)",

            "& .even-row": {
              backgroundColor: "#ffffff",
            },
            "& .odd-row": {
              backgroundColor: "#eff9ff",
            },
            // Masquer les ellipses de tri et de filtre

            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              display: "none",
            },
            
          }}
        />
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: 2,
              right: { xs: "calc(2rem)", sm: "calc(2rem)", md: "calc(3rem)" }, // Further adjusted right position to move left
              padding: 2, // Optional padding for spacing
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" }, // Responsive font size
              marginRight: { xs: 1, sm: 2, md: 1 }, // Responsive margin
              marginBottom: { xs: 1, sm: 1, md: 9.5 }, // Responsive margin
            }}
          >
            {t("Page")} {pagination.page}
          </Typography>
        </Box>
      </Grid>
      <BasicModal
        open={deleteConfirmation.open}
        handleClose={handleCloseConfirmation}
        fullWidth={true} // Ensures the modal takes up the full width of the screen
        title={t("Confirmation de suppression")}
        maxWidth={"sm"}
        actions={
          <>
            {/* Cancel button */}
            <Button onClick={handleCloseConfirmation} color="primary">
              {t("cancel")}
            </Button>

            {/* Confirm delete button */}
            <Button
              onClick={handleConfirmDelete}
              variant="contained"
              color="primary"
            >
              {t("supprimer")}
            </Button>
          </>
        }
      >
        {t("Êtes vous sûr de vouloir supprimer cette Camera ?")}
      </BasicModal>
    </Box>
  );
};

export default MesCamerasPage;
