import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/hooks";
import { AxiosError } from "axios";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import { useMutation } from "@tanstack/react-query";
import { getUserSession } from "../../../utils/auth";
import SearchInput from "../../reusable/inputs/SearchInput";
import { ChangePasswordRequest } from "../../../types/Requests";
import { changePassword } from "../../../services/auth/auth.service";
import { ErrorResponse } from "../../../types/Responses";
var md5 = require("md5");

const PasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const sessionId = getUserSession();
  const dispatch = useAppDispatch();

  // State to manage password fields
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // State to manage password validation errors
  const [passwordErrors, setPasswordErrors] = useState<{
    showing: boolean;
    errors: string[];
  }>({ showing: false, errors: [] });

  // State to manage confirm password validation errors
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<{
    showing: boolean;
    errors: string[];
  }>({ showing: false, errors: [] });

  // State to manage empty field error message
  const [fieldEmptyError, setFieldEmptyError] = useState<string>("");

  // Function to handle changes in old password field
  const handleOldPassword = (field: string, value: string) => {
    if (field === "oldPassword") {
      setOldPassword(value);
    }
  };

  // Function to handle changes in new password field
  const handleNewPassword = (field: string, value: string) => {
    if (field === "newPassword") {
      setNewPassword(value);
    }
  };

  // Effect to validate new password when it changes
  useEffect(() => {
    if (newPassword === "") {
      setPasswordErrors({ showing: false, errors: [] });
      return;
    }

    // Define password validation criteria
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const passwordLength = { min: 10, max: 30 };

    const errors: string[] = [];
    let passCount = 4;
    if (!specialCharRegex.test(newPassword)) {
      errors.push(t("passwordSpecialCharError"));
      passCount--;
    }
    if (!uppercaseRegex.test(newPassword)) {
      errors.push(t("passwordUppercaseError"));
      passCount--;
    }
    if (!lowercaseRegex.test(newPassword)) {
      errors.push(t("passwordLowercaseError"));
      passCount--;
    }
    if (!digitRegex.test(newPassword)) {
      errors.push(t("passwordDigitError"));
      passCount--;
    }
    let passwordLengthPass = true;
    if (
      newPassword.length < passwordLength.min ||
      newPassword.length > passwordLength.max
    ) {
      passwordLengthPass = false;
      errors.push(
        t("passwordLengthError", {
          min: passwordLength.min,
          max: passwordLength.max,
        })
      );
    }

    // Update password validation errors
    if (errors.length > 0) {
      setPasswordErrors({
        showing: passCount < 3 || !passwordLengthPass,
        errors: [...errors],
      });
    } else {
      setPasswordErrors({ showing: false, errors: [] });
    }
    // Check if passwords do not match
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordErrors({
        showing: true,
        errors: [t("passwordsDoNotMatchError")],
      });
    } else {
      setConfirmPasswordErrors({ showing: false, errors: [] });
    }
  }, [newPassword, confirmPassword]);

  // Function to handle changes in confirm password field
  const handleConfirmPasswordChange = (field: string, value: string) => {
    if (field === "confirmPassword") {
      setConfirmPassword(value);

      // Check if new password is not empty and does not match confirm password
      if (newPassword && newPassword !== value) {
        setConfirmPasswordErrors({
          showing: true,
          errors: ["Passwords do not match"],
        });
      } else {
        setConfirmPasswordErrors({ showing: false, errors: [] });
      }
    }
  };

  // Mutation for changing the password
  const changePasswordMutation = useMutation({
    mutationFn: (request: ChangePasswordRequest) => {
      return changePassword(request);
    },
    // Reset password fields and show success notification
    onSuccess: () => {
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      dispatch(notifySuccess(t("Password changed successfully")));
    },
    // Show error notification on failure
    onError: (error: AxiosError<ErrorResponse>) => {
      dispatch(notifyError(t("Failed to change password")));
      if (error.response && error.response.data.errors.code === "-106") {
        dispatch(notifyError(t("Incorrect Old Password")));
      }
      console.error("Error:", error);
    },
  });

  // This memorized value `formInvalid` determines whether the password form is valid or not.
  const formInvalid = useMemo(() => {
    if (
      oldPassword.length === 0 ||
      newPassword.length === 0 ||
      confirmPassword.length === 0 ||
      passwordErrors.showing ||
      confirmPasswordErrors.showing
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    oldPassword,
    newPassword,
    confirmPassword,
    passwordErrors,
    confirmPasswordErrors,
  ]);

  // handle Submit the password
  const handleSubmitChangePwd = () => {
    // Check empty field errors and show error notification
    if (formInvalid) {
      dispatch(notifyError(t("Invalid Form")));
    } else {
      setFieldEmptyError("");
      const hashedOldPassword = md5(oldPassword);
      const hashedNewPassword = md5(newPassword);

      // Prepare request for changing password
      const request: ChangePasswordRequest = {
        old_password: hashedOldPassword,
        new_password: hashedNewPassword,
        sessionId: sessionId,
      };
      // Call mutation to change password
      changePasswordMutation.mutate(request);
    }
  };

  return (
    <Box p={3} sx={{ marginLeft: 82 }}>
      <br />

      <Box sx={{ maxWidth: 650 }}>
        <SearchInput
          fullWidth={true}
          placeHolder={t("oldPasswordPlaceholder")}
          searchLabel={t("oldPasswordLabel")}
          fieldName="oldPassword"
          value={oldPassword}
          valueChange={handleOldPassword}
          size="small"
          inputType="password"
          showPasswordToggle={true}
        />
      </Box>

      <Box sx={{ maxWidth: 650 }}>
        <br />
        <SearchInput
          fullWidth
          placeHolder={t("New password")}
          searchLabel={t("New password")}
          fieldName="newPassword"
          value={newPassword}
          valueChange={handleNewPassword}
          error={Boolean(passwordErrors.showing)}
          size="small"
          inputType="password"
          showPasswordToggle={true} // Enable display of visibility icon
        />
        {!newPassword && (
          <Typography
            style={{
              color: "red",
              fontSize: 12,
              alignItems: "start",
              display: "flex",
              textAlign: "left",
              marginLeft: "46px",
            }}
          >
            {t("new password is required")}
          </Typography>
        )}
        {passwordErrors.showing && (
          <Box>
            {passwordErrors.errors.map((error, index) => (
              <Typography key={index} color="red" fontSize={13}>
                {error}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      <br />
      <Box sx={{ maxWidth: 650 }}>
        <SearchInput
          fullWidth={true}
          placeHolder={t("confirm password")}
          searchLabel={t("confirm password")}
          fieldName="confirmPassword"
          value={confirmPassword}
          valueChange={handleConfirmPasswordChange}
          size="small"
          inputType="password"
          error={Boolean(confirmPasswordErrors.showing)}
          showPasswordToggle={true}
        />
        <Box>
          {!confirmPassword && (
            <Typography
              style={{
                color: "red",
                fontSize: 12,
                alignItems: "start",
                display: "flex",
                textAlign: "left",
                marginLeft: "46px",
              }}
            >
              {t("confirm password is required")}
            </Typography>
          )}
          {confirmPasswordErrors.showing &&
            confirmPasswordErrors.errors.map((errorMessage) => {
              return (
                <Typography
                  style={{
                    color: "red",
                    fontSize: 12,
                    alignItems: "start",
                    display: "flex",
                    textAlign: "left",
                    marginLeft: "46px",
                  }}
                >
                  {errorMessage}
                </Typography>
              );
            })}
        </Box>
        <br />
        <Button
          variant="contained"
          onClick={handleSubmitChangePwd}
          sx={{
            backgroundColor: formInvalid ? "gray" : "primary.main", // Set button color based on form validity
            "&:hover": {
              backgroundColor: formInvalid ? "gray" : "primary.dark", // Adjust hover color similarly
            },
          }}
        >
          {t("Change Password")}
        </Button>
      </Box>
    </Box>
  );
};

export default PasswordPage;
