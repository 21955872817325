/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx } from "@emotion/react";
import { Box, Container, Grid, Typography } from "@mui/material";
//import { Color } from "../../constants/color";
import { footerStyle } from "../../styles/layout/layout.style";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const footerElements = footerStyle;

const Footer = () => {
  const { t } = useTranslation();
  //const versionDeploy = "1.1.0"
  return (
    <Box css={footerElements.container}>
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography  variant="body1">
              {t("Copyright © ")}
              <Link
                color="inherit"
                target="_blank"
                to={"https://www.acoba.com/"}
              >
                ACOBA
              </Link>{" "}
              {new Date().getFullYear()}
             
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
