import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { useAppDispatch } from "../../../store/hooks";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import {
  CheckResetTokenRequest,
  ResetPasswordRequest,
} from "../../../types/Requests";
import { CheckTokenResponse } from "../../../types/Responses";
import {
  checkResetToken,
  resetPassword,
} from "../../../services/auth/auth.service";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import SearchInput from "../../reusable/inputs/SearchInput";
var md5 = require("md5");

const ResetPassword = () => {
  // Hook to manage query parameters from the URL
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // State to manage password and confirm password inputs

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  // State to manage validation errors for password and confirm password
  const [passwordErrors, setPasswordErrors] = useState<{
    showing: boolean;
    errors: string[];
  }>({ showing: false, errors: [] });

  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<{
    showing: boolean;
    errors: string[];
  }>({ showing: false, errors: [] });

  // Extract email and token from query parameters
  const paramsEmail = useMemo(
    () => searchParams.get("email") || "",
    [searchParams]
  );
  const paramsToken = useMemo(
    () => searchParams.get("tkt") || "",
    [searchParams]
  );

  // Query to check if the reset token is valid
  const { data, error } = useQuery<
    AxiosResponse<CheckTokenResponse>,
    AxiosError
  >({
    queryKey: ["checkResetToken", paramsEmail, paramsToken],
    queryFn: () => {
      const request: CheckResetTokenRequest = {
        reset_token: paramsToken,
        email: paramsEmail,
      };
      return checkResetToken(request);
    },
  });

  // Memoized value
  const verifiedToken = useMemo(() => data?.data.data.message || false, [data]);

  // Memoized value to check if both email and token are present
  const validParams = useMemo(
    () => !!paramsEmail && !!paramsToken,
    [paramsEmail, paramsToken]
  );

  // Mutation to handle password reset request
  const resetPasswordMutation = useMutation({
    mutationFn: (request: ResetPasswordRequest) => resetPassword(request),
    onSuccess: () => {
      dispatch(notifySuccess(t("Password reset successfully")));
      setNewPassword("");
      setConfirmPassword("");
      navigate("/login");
    },
    onError: () => {
      dispatch(notifyError(t("Failed to reset password")));
    },
  });

  // Regular expressions for password validation
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /\d/;
  const passwordLength = { min: 10, max: 30 };

  // Effect to validate password and confirm password fields
  useEffect(() => {
    if (newPassword === "") {
      setPasswordErrors({ showing: false, errors: [] });
      return;
    }
    const errors: string[] = [];
    let passCount = 4;
    if (!specialCharRegex.test(newPassword)) {
      errors.push(t("passwordSpecialCharError"));
      passCount--;
    }
    if (!uppercaseRegex.test(newPassword)) {
      errors.push(t("passwordUppercaseError"));
      passCount--;
    }
    if (!lowercaseRegex.test(newPassword)) {
      errors.push(t("passwordLowercaseError"));
      passCount--;
    }
    if (!digitRegex.test(newPassword)) {
      errors.push(t("passwordDigitError"));
      passCount--;
    }
    let passwordLengthPass = true;
    if (
      newPassword.length < passwordLength.min ||
      newPassword.length > passwordLength.max
    ) {
      passwordLengthPass = false;
      errors.push(
        t("passwordLengthError", { min: passwordLength.min, max: passwordLength.max })
      );
    }

    // Update password validation errors
    if (errors.length > 0) {
      setPasswordErrors({
        showing: passCount < 3 || !passwordLengthPass,
        errors: [...errors],
      });
    } else {
      setPasswordErrors({ showing: false, errors: [] });
    }
    // Check if passwords do not match
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordErrors({
        showing: true,
        errors: [t("passwordsDoNotMatchError")],
      });
    } else {
      setConfirmPasswordErrors({ showing: false, errors: [] });
    }
  }, [newPassword, confirmPassword]);

  const formInvalid = useMemo(() => {
    if (
      newPassword.length === 0 ||
      confirmPassword.length === 0 ||
      passwordErrors.showing ||
      confirmPasswordErrors.showing
    ) {
      return true;
    } else {
      return false;
    }
  }, [newPassword, confirmPassword, passwordErrors, confirmPasswordErrors]);

  // Handler for password reset button click
  const handleResetPasswordClick = () => {
    if (!formInvalid) {
      const hashedPassword = md5(newPassword);
      const request: ResetPasswordRequest = {
        new_password: hashedPassword,
        email: paramsEmail,
        reset_token: paramsToken,
      };
      resetPasswordMutation.mutate(request);
    } else {
      dispatch(notifyError(t("Invalid Form")));
    }
  };

  if (!validParams) {
    return (
      <div>
        <Typography>{"Wrong Link"}</Typography>
      </div>
    );
  }

  if (!verifiedToken) {
    return (
      <div>
        <Typography>{"Invalid Token"}</Typography>
      </div>
    );
  }

  // Function to handle changes in new password field
  const handleNewPassword = (field: string, value: string) => {
    if (field === "newPassword") {
      setNewPassword(value);
    }
  };

  // Function to handle changes in confirm password field
  const handleConfirmPasswordChange = (field: string, value: string) => {
    if (field === "confirmPassword") {
      setConfirmPassword(value);

      // Check if new password is not empty and does not match confirm password
      if (newPassword && newPassword !== value) {
        setConfirmPasswordErrors({
          showing: true,
          errors: ["Passwords do not match"],
        });
      } else {
        setConfirmPasswordErrors({ showing: false, errors: [] });
      }
    }
  };

  return (
    <Box
      p={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        marginTop: { xs: 24, sm: 26, md: 28 },
        backgroundColor: "#e4f1fe",
        borderRadius: 2,
        maxWidth: "100%",
        width: { xs: "90%", sm: "80%", md: "500px" },
        height: "auto",
      }}
    >
      <br />
      <Box sx={{ width: "100%", mb: 2 }}>
        <SearchInput
          fullWidth={true}
          placeHolder={t("New password")}
          searchLabel={t("New password")}
          fieldName="newPassword"
          value={newPassword}
          valueChange={handleNewPassword}
          error={Boolean(passwordErrors.showing)}
          size="small"
          inputType="password"
          showPasswordToggle={true} // Enable display of visibility icon
          autoComplete="new-password"
        />
        {!newPassword && (
          <Typography color="red" fontSize={12} textAlign="left" ml={5}>
            {t("new password is required")}
          </Typography>
        )}

        {passwordErrors.showing && (
          <Box>
            {passwordErrors.errors.map((error, index) => (
              <Typography key={index} color="red" fontSize={13}>
                {error}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
      <br />
      <Box sx={{ width: "100%", mb: 2 }}>
        <SearchInput
          fullWidth={true}
          placeHolder={t("confirm password")}
          searchLabel={t("confirm password")}
          fieldName="confirmPassword"
          value={confirmPassword}
          valueChange={handleConfirmPasswordChange}
          size="small"
          inputType="password"
          error={Boolean(confirmPasswordErrors.showing)}
          showPasswordToggle={true}
        />
        <Box>
          {!confirmPassword && (
            <Typography
              sx={{
                color: "red",
                fontSize: 12,
                display: "flex",
                alignItems: "flex-start",
                textAlign: "left",
                marginLeft: "40px",
              }}
            >
              {t("confirm password is required")}
            </Typography>
          )}
          {confirmPasswordErrors.showing &&
            confirmPasswordErrors.errors.map((errorMessage) => {
              return (
                <Typography
                  style={{
                    color: "red",
                    fontSize: 12,
                    alignItems: "start",
                    display: "flex",
                    textAlign: "left",
                    marginLeft: "46px",
                  }}
                >
                  {errorMessage}
                </Typography>
              );
            })}
        </Box>
        <br />
        <Button
          variant="contained"
          onClick={handleResetPasswordClick}
          sx={{
            backgroundColor: formInvalid ? "gray" : "primary.main", // Set button color based on form validity
            "&:hover": {
              backgroundColor: formInvalid ? "gray" : "primary.dark", // Adjust hover color similarly
            },
          }}
        >
          {t("resetPassword")}
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPassword;
