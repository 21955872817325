import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  teamRoles,
  updateRolePermissions,
} from "../../../services/auth/auth.service";
import { AxiosError } from "axios";
import {
  invalidateSession,
  setAllPermissions,
  setAllRoles,
  setRefetchRoles,
} from "../../../store/reducers/user.slice";
import { TeamRole, TeamRoleFunction } from "../../../types/Responses";
import HorizontalCard from "../../reusable/cards/HorizontalCard";
import { UpdateTeamRolePermissionRequest } from "../../../types/Requests";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import BasicModal from "../../reusable/modals/BasicModal";
import DefaultSwitch from "../../reusable/buttons/DefaultSwitch";
import { useTranslation } from "react-i18next";

function ProfilesPage() {
  // State to manage the selected profile
  const [selectedProfile, setSelectedProfile] = useState<any>(null);
  // State to manage the profile permissions
  const [profilePermissions, setProfilePermissions] = useState<any[]>([]);

  // Get session ID, roles, permissions, and refetchRoles  from Redux store
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const allRoles = useAppSelector((state) => state.user.allRoles);
   const permissions = useAppSelector((state) => state.user.permissions);
  const allPermissions = useAppSelector((state) => state.user.allPermissions);
  const refetchRoles = useAppSelector((state) => state.user.refetchRoles);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const canUpdatePermissions = permissions.includes("managePermissions");

  const handleModalClose = () => {
    setSelectedProfile(null);
  };

  // Fetch roles using react-query
  const rolesQuery = useQuery({
    queryKey: ["teamRoles", sessionId],
    queryFn: () => {
      if (sessionId) {
        return teamRoles().then((res) => res.data);
      }
    },
  });

  /* useEffect(() => {
    if (rolesQuery.data) {
      console.log("Roles Query Data:", rolesQuery.data);
    }
  }, [rolesQuery.data]);*/

  // Update roles and permissions in Redux store when rolesQuery.data changes
  useEffect(() => {
    if (rolesQuery.data) {
      dispatch(setRefetchRoles(rolesQuery.refetch));
      if (rolesQuery.data.data?.roles?.length > 0) {
        const allRoles: TeamRole[] = [...rolesQuery.data.data.roles];
        dispatch(setAllRoles(allRoles));
      }
      if (rolesQuery.data.data?.functions?.length > 0) {
        const allPermissions: TeamRoleFunction[] = [
          ...rolesQuery.data.data.functions,
        ];
        dispatch(setAllPermissions(allPermissions));
      }
    }
  }, [dispatch, rolesQuery.data, rolesQuery.refetch]);

  // Mutation to update role permissions
  const updateRolePermissionMutation = useMutation({
    mutationFn: (changes: UpdateTeamRolePermissionRequest) => {
      return updateRolePermissions(changes);
    },
    onSuccess: () => {
      dispatch(notifySuccess(t("Permissions changed successfully")));
      handleModalClose();
      refetchRoles();
    },
    onError: (error: AxiosError) => {
      dispatch(notifyError(t("Failed to update permissions")));
      handleModalClose();
      if (
        error.hasOwnProperty("response") &&
        error.response!.hasOwnProperty("status")
      ) {
        if (error.response!.status === 401) {
          dispatch(invalidateSession());
        }
      }
    },
  });

  // Update profile permissions when selectedProfile or allPermissions changes
  useEffect(() => {
    if (selectedProfile) {
      const allPerm = allPermissions.map(
        (perm: TeamRoleFunction, index: number) => {
          return {
            key: perm.id,
            name: perm.name,
            selected: selectedProfile.functions.includes(perm.id),
          };
        }
      );
      setProfilePermissions([...allPerm]);
    } else {
      setProfilePermissions([]);
    }
  }, [selectedProfile, allPermissions]);

  // Handle change of permission selection
  const handleManageAccessClick = (key: any) => {
    // when clicking we have the permissions of the desired profile
    const desiredProfile = allRoles.filter((profile) => profile.id === key);
    setSelectedProfile(desiredProfile[0]);
  };

  // Handle change of permission selection
  const handleChangePermission = (permissionIndex: number) => {
    // Create a copy of the profilePermissions array
    const permissionsCp = [...profilePermissions];
    // Get the specific permission object at the given index
    const desiredPermission = permissionsCp[permissionIndex];
    desiredPermission.selected = !desiredPermission.selected; // inverse la valeur actuelle de la propriété selected.
    // Replace the old permission object with the modified one in the copied array
    permissionsCp.splice(permissionIndex, 1, desiredPermission);
    setProfilePermissions([...permissionsCp]);
  };

  // Calculate differences between original and modified permissions
  const calculateDifference = (
    originalPermissions: any,
    modifiedPermissions: any
  ) => {
    let result = {
      allowed_functions: [],
      not_allowed_functions: [],
    };
    let changes = modifiedPermissions.filter(
      (perm: any, index: number) =>
        perm.selected !== originalPermissions[index].selected
    );
    result.allowed_functions = changes
      .filter((change: any) => change.selected === true)
      .map((perm: any) => perm.key);
    result.not_allowed_functions = changes
      .filter((change: any) => change.selected === false)
      .map((perm: any) => perm.key);
    return result;
  };

  // Handle confirmation click to update role permissions
  const handleConfirmClick = () => {
    const originalPermissions = allPermissions.map(
      (perm: TeamRoleFunction, index: number) => {
        return {
          key: perm.id,
          name: perm.name,
          selected: selectedProfile.functions.includes(perm.id),
        };
      }
    );
    const modifiedPermissions = [...profilePermissions];
    let modifications = calculateDifference(
      originalPermissions,
      modifiedPermissions
    );
    const request: UpdateTeamRolePermissionRequest = {
      // update permission
      ...modifications,
      role_id: selectedProfile.id,
      sessionId,
    };
    updateRolePermissionMutation.mutate(request);
  };

  const handleSelectAll = () => {
    const allSelected = profilePermissions.every((perm) => perm.selected);
    const toggledPermissions = profilePermissions.map((perm) => ({
      ...perm,
      selected: !allSelected,
    }));
    setProfilePermissions([...toggledPermissions]);
  };

  return (
    <div>
      <br />
      <TableContainer
        component={Paper}
        sx={{
          overflowX: "hidden",
          width: "57%",
          marginLeft: 57,
          backgroundColor: "#e4f1fe",
        }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {allRoles &&
              allRoles.length > 0 &&
              allRoles.map((profile) => (
                <HorizontalCard
                  key={"team-member-" + profile.id}
                  image={profile.name}
                  description={profile.name}
                  imageIsName={true}
                  actions={
                    <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleManageAccessClick(profile.id)}
                        disabled={!canUpdatePermissions}
                        sx={{ width: 164, backgroundColor: !canUpdatePermissions
                          ? "#4f4f4f"
                          : "primary",
                        color: !canUpdatePermissions ? "#b0b0b0" : "white",
                        "&:hover": {
                          backgroundColor: !canUpdatePermissions
                            ? "#4f4f4f"
                            : "#1565C0",
                        }, }}
                      >
                         {t("Manage Access")}
                      </Button>
                    </>
                  }
                  actionsWidth={210}
                />
              ))}
            <BasicModal
              open={selectedProfile !== null}
              handleClose={() => {
                handleModalClose();
              }}
              fullWidth={true}
              title={t("Manage Access")}
              maxWidth={"md"}
              actions={
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleSelectAll}
                    sx={{
                      color: "#1E88E5",
                      borderColor: "#1E88E5",
                      "&.MuiButton-root": {
                        fontSize: "0.8rem",
                        padding: "2px 6px",
                        minWidth: "auto",
                        display: "flex",
                        justifyContent: "flex-start",
                        marginRight: "auto",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    {t("Select All")}
                  </Button>
                  <Button onClick={handleModalClose} color="primary">
                    {t("cancel")}
                  </Button>
                  <Button
                    onClick={handleConfirmClick}
                    variant="contained"
                    color="primary"
                  >
                    {t("save")}
                  </Button>
                </>
              }
            >
              <div style={{ height: "clamp(200px, 45dvh, 500px)" }}>
                {profilePermissions && profilePermissions.length > 0 && (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Grid container columnSpacing={5}>
                      {profilePermissions &&
                        profilePermissions.length > 0 &&
                        profilePermissions.map(
                          (permission: any, index: number) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                key={"permission-" + permission.key}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    height: 50,
                                  }}
                                >
                                  {/*<Typography>{permission.name}</Typography>*/}
                                  <Typography>{t(`permissions.${permission.key}`)}</Typography>
                                  <DefaultSwitch
                                    value={permission.selected}
                                    handleClick={() => {
                                      handleChangePermission(index);
                                    }}
                                  />
                                </div>
                              </Grid>
                            );
                          }
                        )}
                      <Grid item xs={12} sm={6}></Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </BasicModal>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ProfilesPage;
