/*import React from "react";
import { Box, Container } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import Main from "./main";
import layoutStyle from "../styles/layout/layout.style";

const layoutElements = layoutStyle;

const Layout = () => {
  return (
    <Box
    css={layoutElements.container}
    >
      <Header />
      <Main />
      <Footer />
    </Box>
  );
};

export default Layout;
*/
import React from "react";
import { Box } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import Main from "./main";
import layoutStyle from "../styles/layout/layout.style";
import { useLocation, useParams } from "react-router-dom";

const layoutElements = layoutStyle;

const Layout = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isResetPassword = location.pathname ==='/reset';
  //const isHomePage = location.pathname === '/';

    // Determine whether to show the sidebar
    const showSidebar = !(isLoginPage || isResetPassword);
  return (
    <Box css={layoutElements.container}>
      <Header />
      <Main showSidebar={showSidebar}  />
      <Footer />
    </Box>
  );
};

export default Layout;
/*const Layout = () => {
  const location = useLocation();
  const { lang } = useParams<{ lang: string }>();
  console.log("Current language in Layout++++:", lang);

  // Default language if not present in URL
  const currentLang = lang || 'fr';

  // Determine if the current page is the login page or reset password page
  const isLoginPage = location.pathname.startsWith(`/${currentLang}/login`);
  const isResetPassword = location.pathname.startsWith(`/${currentLang}/reset`);

  // Determine whether to show the sidebar
  const showSidebar = !(isLoginPage || isResetPassword);

  return (
    <Box css={layoutElements.container}>
      <Header />
      <Main showSidebar={showSidebar} />
      <Footer />
    </Box>
  );
};

export default Layout;*/
