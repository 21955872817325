import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MesClientsPage from "../../components/pageComponents/MesClientsPage";
import ClientDetailsPage from "../../components/pageComponents/ClientDetailsPage/ClientDetailsPage";
import MesSitesPage from "../../components/pageComponents/MesSitesPage";
import MesCamerasPage from "../../components/pageComponents/MesCamerasPage";
import ListeAlarmesPage from "../../components/pageComponents/ListeAlarmesPage";
import SessionsOperators from "../../components/pageComponents/SessionsOperators";
import CameraDetailsPage from "../../components/pageComponents/CameraDetailsPage";
import ProfilPage from "../../components/pageComponents/ComptePage/ProfilPage";
import EquipePage from "../../components/pageComponents/EquipePage/EquipePage";
import LoginForm from "../../components/pageComponents/LoginPage/LogInContent";
import SiteDetailsPage from "../../components/pageComponents/SiteDetailsPage";
import TotalSessionOperators from "../../components/pageComponents/SessionsOperators/TotalSessionOperators";
import getConfiguration from "../../config/config";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Error404Page from "../../pages/Error404";
import ResetPassword from "../../components/pageComponents/ResetPwPage/ResetPasswordContent";
import { Box } from "@mui/material";
import Error403Page from "../../pages/Error403";

const Content: React.FC = () => {
  const configuration = getConfiguration();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);

  return (
    <Box>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        {/*Default redirection based on authentication */}
        <Route
          path="/"
          element={
            sessionId ? (
              <Navigate replace to={`/mes_clients`} />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />

        {/*Routes for authenticated users */}
        {sessionId ? (
          <>
            {permissions?.includes("showCustomers") ? (
              <Route path="/mes_clients" element={<MesClientsPage />} />
            ) : (
              <Route path="/mes_clients" element={<Error403Page />} />
            )}
            {permissions?.includes("showAlarmsList") ? (
              <Route path="/listalarms" element={<ListeAlarmesPage />} />
            ) : (
              <Route path="/listalarms" element={<Error403Page />} />
            )}
            {permissions?.includes("showSites") ? (
              <Route path="/site" element={<MesSitesPage />} />
            ) : (
              <Route path="/site" element={<Error403Page />} />
            )}
            {permissions?.includes("showCameras") ? (
              <Route path="/cameras" element={<MesCamerasPage />} />
            ) : (
              <Route path="/cameras" element={<Error403Page />} />
            )}
            {permissions?.includes("showSessionsOperators") ? (
              <Route path="/records/:id" element={<SessionsOperators />} />
            ) : (
              <Route path="/records/:id" element={<Error403Page />} />
            )}
            {permissions?.includes("showSessionsOperators") ? (
              <Route path="/records" element={<TotalSessionOperators />} />
            ) : (
              <Route path="/records" element={<Error403Page />} />
            )}

            <Route path="/details/:id" element={<ClientDetailsPage />} />
            <Route
              path="/details/:id/camerasByClient"
              element={<CameraDetailsPage />}
            />
            <Route path="/mon_profil" element={<ProfilPage />} />

            <Route path="/mon_equipe" element={<EquipePage />} />

            <Route
              path="/camera/single/details/:id"
              element={<CameraDetailsPage />}
            />
            <Route path="/details_site/:id" element={<SiteDetailsPage />} />
            <Route
              path="/details_site/:id/camerasBySite"
              element={<CameraDetailsPage />}
            />

            <Route path="*" element={<Error404Page />} />
          </>
        ) : (
          // Routes for unauthenticated users
          <>
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </>
        )}
      </Routes>
    </Box>
  );
};

export default Content;
