import "./App.css";
import Layout from "./layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Ensure i18n is correctly configured and imported
import { LanguageProvider} from "./components/common/LanguageContext";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { useEffect } from "react";
import { checkSession, invalidateSession } from "./store/reducers/user.slice";
import { getUserSession } from "./utils/auth";




const queryClient = new QueryClient({

  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
console.log("QueryClient:", queryClient);



function App() {


  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
            <LanguageProvider>
              <Router>
         
                <Layout />
              </Router>
              </LanguageProvider>
            </I18nextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
