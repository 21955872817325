import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { askForReset, logIn } from "../../../services/auth/auth.service";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff, ArrowBack } from "@mui/icons-material";
import {
  setSession,
  setPermissions,
  invalidateSession,
} from "../../../store/reducers/user.slice";
import { useAppDispatch } from "../../../store/hooks";
import { AskForResetRequest, LoginRequest } from "../../../types/Requests";
import { LoginResponse, ErrorResponse } from "../../../types/Responses";
import { useTranslation } from "react-i18next";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import "./Login.css";
import { AxiosError } from "axios";

var md5 = require("md5");

type Credential = {
  login: string;
  password: string;
};

const LoginForm: React.FC = () => {
  // State variables
  const [credential, setCredential] = useState<Credential>({
    login: "",
    password: "",
  });
  const [resetEmail, setResetEmail] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [mode, setMode] = useState<"login" | "reset">("login");

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Handle login input change
  const handleLoginChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCredential((prev) => ({ ...prev, login: e.target.value }));
  };

  // Handle password input change
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCredential((prev) => ({ ...prev, password: e.target.value }));
  };

  // Handle reset email input change
  const handleResetEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setResetEmail(e.target.value);
  };

  // Toggle between login and reset mode
  const toggleMode = (): void => {
    setMode((prevMode) => {
      if (prevMode === "login") {
        // Lors du passage en mode réinitialisation, copiez l'email de connexion dans le champ de réinitialisation
        setResetEmail(credential.login);
        return "reset";
      } else {
        return "login";
      }
    });
  };

  // Function to show/hide password
  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword);
  };

  // Login mutation
  const logInMutation = useMutation({
    mutationFn: (credentials: LoginRequest) => logIn(credentials),
    onSuccess: (data) => {
      const response: LoginResponse = data.data;
      const sessionId = response.data.session_id;
      const userRole = response.data.role_id;
      dispatch(setSession(sessionId));

      if (userRole && userRole.functions) {
        dispatch(setPermissions(userRole.functions));
      }
      dispatch(notifySuccess(t("User logged in successfully")));

      navigate(`/mes_clients`);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error.response) {
        const { status, data } = error.response;
  
        // Handle the case 406 with the specific error code
        if (status === 406 && data.errors.code === "-137") {
          dispatch(notifyError(t("Please check your credentials")));
        } else {
          // Handling other errors
          dispatch(notifyError(t("Failed to log in")));
        }
      } else {
        // Log the error in case of a network issue
        console.error("Login failed:", error.message);
      }
    },
  });

  // Handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (mode === "login") {
      handleLogInClick();
    } else if (mode === "reset") {
      handleResetPasswordClick();
    }
  };

  // Handle login button click
  const handleLogInClick = () => {
    const passwordHash = md5(credential.password);
    const sessionMaxLifetime = "20";
    const request: LoginRequest = {
      email: credential.login,
      password: passwordHash,
      session_maxlifetime: sessionMaxLifetime,
    };
    logInMutation.mutate(request);
  };

  // Password reset mutation
  const resetPasswordMutation = useMutation({
    mutationFn: (request: AskForResetRequest) => askForReset(request),
    onSuccess: () => {
      dispatch(notifyInfo(t("A reset link has been sent to your email box")));
      setResetEmail("");
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      dispatch(notifyError(t("Failed to send a reset link")));
      if (error.response?.data.errors.code === "-18") {
        dispatch(notifyError(t("No account with such email")));
      }
    },
  });

  const handleResetPasswordClick = () => {
    resetPasswordMutation.mutate({ email: resetEmail });
  };

  return (
    <div>
      <div className="container">
        <div className="box">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4">
            <img
              src="acoba.png"
              alt=""
              style={{
                maxWidth: "100px",
                marginBottom: "20px",
                marginLeft: "120px",
              }}
            />
            {mode === "login" && (
              <form className="mb-3" onSubmit={handleSubmit}>
                <div className="form-group">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: " #06648c",
                      marginTop: -5,
                      fontSize: "20px",
                      fontWeight: 600,
                      fontFamily:
                        "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                    }}
                  >
                    {t("Welcome to IMA Dashboard")}
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    label={t("login.email")}
                    fullWidth
                    size="small"
                    value={credential.login}
                    onChange={handleLoginChange}
                    placeholder="example@email.com"
                    sx={{
                      marginTop: 5,
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#f9f9f9",
                    }}
                  />
                </div>
                <div className="form-group">
                  <Stack>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      label={t("login.password")}
                      fullWidth
                      size="small"
                      value={credential.password}
                      onChange={handlePasswordChange}
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                          borderRadius: "5px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                              style={{ color: "#5b6e83" }}
                            >
                              {showPassword ? (
                                <Visibility fontSize="small" />
                              ) : (
                                <VisibilityOff fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <p
                    onClick={toggleMode}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: " #06648c",
                      marginTop: -18,
                      marginBottom: 26,
                      marginLeft: 3,
                      fontSize: 15,
                    }}
                  >
                    {t("login.forgotPassword")}
                  </p>
                </div>
                <button type="submit" className="btn">
                  {t("login.loginButton")}
                </button>
              </form>
            )}
            {mode === "reset" && (
              <form className="mb-3" onSubmit={handleSubmit}>
                <div className="form-group">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: " rgba(58, 53, 65, 0.6)",
                      fontSize: 14,
                      textAlign: "left",
                      marginBottom: 3,
                    }}
                  >
                    {t(
                      "Nous vous enverrons un lien de réinitialisation à cette adresse"
                    )}
                  </Typography>
                  <TextField
                    type="email"
                    variant="outlined"
                    label={t("login.resetEmail")}
                    fullWidth
                    placeholder="example@email.com"
                    size="small"
                    value={resetEmail}
                    onChange={handleResetEmailChange}
                    sx={{ backgroundColor: "#fff" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <p
                    onClick={toggleMode}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: " #06648c",
                      marginTop: -18,
                      marginBottom: 26,
                      marginLeft: 3,
                      fontSize: 15,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {t("login.backToLogin")}?
                  </p>
                </div>
                <button
                  type="button"
                  className="styled-button"
                  onClick={handleResetPasswordClick}
                >
                  {t("login.resetPassword")}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
