import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  AppBar,
  Toolbar,
  Button,
  LinearProgress,
} from "@mui/material";
import { GetUsersListResponse } from "../../../../types/Responses";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import EditClient from "../../EditClient";
import moment from "moment";
import { useAppSelector } from "../../../../store/hooks";
import { useTranslation } from "react-i18next";

// Define the props interface for the component
interface ClientSectionProps {
  memoizedUsersList: GetUsersListResponse["users"];
}

// Component for displaying client details
const ClientSection: React.FC<ClientSectionProps> = ({ memoizedUsersList }) => {
  // Hooks
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const permissions = useAppSelector((state) => state.user.permissions);
  // State for managing the create/edit dialog
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  // State to control the open/close status of the edit dialog
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: {
      firstName: "",
      lastName: "",
      user_email: "",
      phone: "",
      mobile: "",
      address: "",
      address2: "",
      city: "",
      postCode: "",
      country: "France",
      isCompany: false,
      companyName: "",
      idCompany: "",
      VAT: "",
    },
  });

  // Find the selected user by ID from the memorized users list
  const selectedUser = memoizedUsersList.find((user) => user.id === id);

  useEffect(() => {
    if (selectedUser) {
      setLoading(false); // Stop loading once the user is found
    }
  }, [selectedUser]);

  // Format the creation date of the selected user
  const formattedCreationDate = selectedUser
    ? moment(selectedUser.creationDate).format("DD-MM-YYYY HH:mm:ss")
    : "";

  // Function to open the edit dialog
  const handleClickOpen = (): void => {
    setEditDialog({
      open: true,
      data: {
        firstName: selectedUser?.firstName || "",
        lastName: selectedUser?.lastName || "",
        user_email: selectedUser?.email || "",
        phone: selectedUser?.phone || "",
        mobile: selectedUser?.mobile || "",
        address: selectedUser?.address || "",
        address2: selectedUser?.address2 || "",
        city: selectedUser?.city || "",
        postCode: selectedUser?.postCode || "",
        country: selectedUser?.country || "",
        isCompany: false,
        companyName: selectedUser?.CompanyName || "",
        idCompany: selectedUser?.idCompany || "",
        VAT: selectedUser?.VAT || "",
      },
    });
    setOpenCreate(true); // this handles opening the dialog
  };

  // Function to close the create/edit dialog
  const handleCloseCreate = (): void => {
    setEditDialog({
      open: false, // Set open to false to close the dialog
      data: {
        firstName: "",
        lastName: "",
        user_email: "",
        phone: "",
        mobile: "",
        address: "",
        address2: "",
        city: "",
        postCode: "",
        country: "France",
        isCompany: false,
        companyName: "",
        idCompany: "",
        VAT: "",
      },
    });
    setOpenCreate(false); // Close the create dialog
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 30,
          marginTop: 12,
          width: "calc(100% - 262px)",
          height: 55,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white", whiteSpace: "nowrap" }}
          >
            {t("Client Details")}
          </Typography>
          {permissions.includes("updateCustomer") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                ml: "auto",
                margin: { xs: "4px", sm: "8px", md: "12px", lg: "16px" }, // Responsive margins
                position: "relative",
                top: "-5px",
                backgroundColor: "rgb(224, 247, 250)",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                "&:hover": {
                  backgroundColor: "#3498DB",
                  color: "white",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                "&:focus": {
                  backgroundColor: "#3498DB",
                  color: "#393e46",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                },
                color: "#393e46",
                fontSize: { xs: "8px", sm: "10px", md: "12px" }, // Responsive font size
                whiteSpace: "nowrap",
                width: { xs: "auto", sm: "auto" }, // Responsive width
                maxWidth: { xs: "200px", sm: "250px" }, // Max width for larger screens
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // Ensure icon color changes with text color on hover
                "& .MuiSvgIcon-root": {
                  color: "inherit",
                },
                // Ensure icon color changes with text color on focus
                "& .MuiSvgIcon-root:focus": {
                  color: "inherit",
                },
              }}
            >
              {t("Update Client")}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <LinearProgress
            sx={{
              height: 10,
              borderRadius: 1,
              width: "60%",
              backgroundColor: "#34495e",
            }}
          />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ width: "calc(100% - 262px)", marginLeft: 30 }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {" "}
                  <strong>ID:</strong>
                </TableCell>
                <TableCell>{selectedUser?.id || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {" "}
                  <strong>{t("Last Name")}:</strong>
                </TableCell>
                <TableCell>{selectedUser?.lastName || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("First Name")}:</strong>
                </TableCell>
                <TableCell>{selectedUser?.firstName || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("Email")}:</strong>
                </TableCell>
                <TableCell>{selectedUser?.email || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("City")}:</strong>
                </TableCell>
                <TableCell>{selectedUser?.city || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("Country")}:</strong>
                </TableCell>
                <TableCell>{selectedUser?.country || ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{t("Created On")}:</strong>
                </TableCell>
                <TableCell>{formattedCreationDate}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <EditClient
        open={editDialog.open}
        handleClose={handleCloseCreate}
        onCreateSuccessCB={() => {
          queryClient.invalidateQueries({ queryKey: ["users", sessionId, id] });
          setOpenCreate(false);
        }}
        userData={editDialog.data} 
        sessionId={sessionId} 
        userId={selectedUser?.id || ""} 
      />
    </Box>
  );
};

export default ClientSection;
