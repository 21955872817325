import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface SearchProps {
  value: string;
  valueChange: any;
  placeHolder: string;
  searchLabel: string;
  fieldName: string;
  size: "small" | "medium" | string;
  fullWidth?: boolean;
  disabled?: boolean;
  inputType?: string;
  readOnly?: boolean;
  onFocus?: any;
  onBlur?: any;
  error?: boolean;
  showPasswordToggle?: boolean;
  width?: string | number;
  autoComplete?: string;
}

/**
 * Component for customising react mui textField component
 * @component
 * @param {string} value                        the textField value
 * @param {any} valueChange                     the textField value control function
 * @param {string} placeHolder                  the textField placeholder text
 * @param {string} searchLabel                  the textField label text
 * @param {string} fieldName                    the relative fieldname to this field inside the form object
 * @param {"small" | "medium" | string} size    the textField size can be either "small", "medium" or "..px"
 * @param {boolean} fullWidth                   [optional] specifies if the textField should take the full width of its container
 * @param {boolean} disabled                    [optional] specifies if the textField is disabled
 * @param {string} inputType                    [optional] specifies the textField field type including "number", "month", "year", etc
 * @param {boolean} readOnly                    [optional] specifies if the password field is modifiable or not
 * @param {any} onFocus                         [optional] the onTextFieldFocus control function can be used for exemple in the isDirty of a form field
 * @param {any} onBlur                          [optional] the onTextFieldBlur control function can be used for exemple in the hasErrors of a form field
 * @param {boolean} error                       [optional] specifies if the textField has an error
 * @example
 * const [form, setForm] = useState({fullName:"", age:18})
 * const [formErrors, setFormErrors] = useState({fullName:false, age:false})
 * const [formDirty, setFormDirty] = useState({fullName:false, age:false})
 * const hasErrors(value:any, fieldName: string){
 *    switch fieldName {
 *      case "fullName":
 *        // do necessary checks and return either true (in case the test fails) or false
 *      case "age":
 *        // do necessary checks and return either true (in case the test fails) or false
 *      default:
 *        return false
 *    }
 * }
 * const formChange = (fieldKey, fieldValue) {
 *  setForm((oldForm)=>{return{...oldForm, [fieldKey]: fieldValue}})
 * }
 * const onFieldFocus = (e: React.FocusEvent, fieldName: string) => {
 *    setFormDirty((oldDirtyState) => {return {...oldDirtyState, [fieldName]: true}})
 * }
 * const onFieldBlur = (e: React.FocusEvent, fieldName: string) => {
 *    setFormDirty((oldErrorState) => {return {...oldDirtyState, [fieldName]: hasErrors(e.target.value, fieldName)}})
 * }
 * const size= "small";
 *
 * return
 *  <SomeForm>
 *    <SearchInput fullWidth={true} placeholder={"full name"} searchLabel={"Full Name"} fieldName={"fullName"} value={form.fullName} valueChange={formChange} size inputType={"text"} onFocus={onFieldFocus} onBlur={onFieldBlur}/>
 *    <SearchInput fullWidth={true} placeholder={"age"} searchLabel={"Age"} fieldName={"age"} value={form.age} valueChange={formChange} size inputType={"number"} onFocus={onFieldFocus} onBlur={onFieldBlur}/>
 *  </SomeForm>
 */

export default function SearchInput(props: SearchProps) {
  const {
    placeHolder,
    searchLabel,
    value,
    valueChange,
    fieldName,
    size,
    fullWidth,
    disabled,
    inputType = "text",
    readOnly = false,
    onFocus = undefined,
    onBlur = undefined,
    error = false,
    showPasswordToggle = false,
    width,
    autoComplete,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      value={value}
      onChange={(e) => {
        valueChange(fieldName, e.target.value);
      }}
      label={searchLabel}
      placeholder={placeHolder}
      size={size === "medium" ? size : "small"}
      InputProps={{
        style: {
          width: "90%",
          fontSize: size !== "small" && size !== "medium" ? size : "",
          backgroundColor: "#fff",
        },
        readOnly: readOnly,
        endAdornment: showPasswordToggle && ( // Afficher l'icône de visibilité seulement si showPasswordToggle est vrai
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? (
                <Visibility
                  fontSize={
                    size === "small"
                      ? "small"
                      : size === "medium"
                      ? "medium"
                      : "inherit"
                  }
                />
              ) : (
                <VisibilityOff
                  fontSize={
                    size === "small"
                      ? "small"
                      : size === "medium"
                      ? "medium"
                      : "inherit"
                  }
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        style: {
          fontSize: size !== "small" && size !== "medium" ? size : "",
        },
      }}
      style={{ padding: 0 }}
      type={showPassword && showPasswordToggle ? "text" : inputType}
      onFocus={(e: React.FocusEvent) => {
        if (onFocus !== undefined) {
          onBlur(e, fieldName);
        }
      }}
      onBlur={(e: React.FocusEvent) => {
        if (onBlur !== undefined) {
          onBlur(e, fieldName);
        }
      }}
      sx={{ width: width || "90%", marginLeft: 3 }}
      autoComplete={autoComplete}
    />
  );
}
