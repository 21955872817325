import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import SearchInput from "../../reusable/inputs/SearchInput";
import SelectInput from "../../reusable/inputs/SelectInput";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SessionsOperatorsResponse } from "../../../types/Responses";
import { getSessionsOperatorsList } from "../../../services/alarm/alarm.service";
import { SessionsOperatorsListRequest } from "../../../types/Requests";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VideoDialog from "./VideoDialog";
import CustomPagination from "../../reusable/tables/CustomPagination";
import "./Sessions.css";
import { notifyError } from "../../../store/reducers/notification.slice";

interface SessionOperatorData {
  id_session: string;
  start_date: string;
  end_date: string;
  id_users: string;
  file_name: string;
  site_identifier_for_arc: string;
  user_Tag: string;
  user_name: string | null;
  serial_number: string;
  alarmVideoClip: string;
}

type FilterData = {
  NumeroDeSerie: string;
  Utilisateur: string;
  prom: string;
  startDate: string;
};

const SessionsOperators: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector((state) => state.user.sessionId);

 
  //displayed filter GUI control
  const [filterData, setFilterData] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
    startDate: "",
  });

  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
    startDate: "",
  });

  const [open, setOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  const [sortBy, setSortBy] = useState<string>("start_date");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined | null>(
    "ASC"
  );

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    // Vérifier si le modèle de tri contient des éléments
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0];
      // Convertir la valeur de 'sort' en "ASC" ou "DESC"
      const normalizedSort: "ASC" | "DESC" | null = sort
        ? (sort.toUpperCase() as "ASC" | "DESC")
        : null;
      setSortBy(field);
      setSortOrder(normalizedSort);
    } else {
      setSortBy("");
      setSortOrder(null);
    }
  };

  // Formate une date UTC en date locale avec le fuseau horaire de l'utilisateur 
const formatDate = (dateString: string | null) => {
  if (!dateString) return "-";

  const utcDate = new Date(dateString + " UTC"); // Convertir en UTC
  return utcDate.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

  // useEffect pour récupérer la taille de page depuis localStorage
  useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPagination((prev) => ({ ...prev, pageSize: Number(storedPageSize) }));
    }
  }, []);

  // Open & close the dialog
  const handleOpenDialog = (videoUrl: string) => {
    setSelectedVideoUrl(videoUrl);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedVideoUrl(null);
  };

  //GUI filter change
  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };
  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };
  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.NumeroDeSerie !== next.NumeroDeSerie ||
      current.Utilisateur !== next.Utilisateur ||
      current.prom !== next.prom ||
      current.startDate !== next.startDate
    );
  };

  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  // Fetch data from API

  const { data, isLoading } = useQuery<SessionsOperatorsResponse>({
    queryKey: [
      "sessions",
      sessionId,
      searchFilter,
      id,
      sortBy,
      sortOrder,
      pagination,
    ],
    queryFn: async () => {
      if (!id) {
        throw new Error("Alarm ID is required but was not provided.");
      }

      const filterApplied: SessionsOperatorsListRequest = {
        sessionId,
        alarm_id: id,
        page_number: pagination.page, // this aligns with your API's page numbering
        max_results: pagination.pageSize,
        sort_by: sortBy,
        sort_order: sortOrder ?? "ASC",
      };

      if (searchFilter.prom !== "") {
        filterApplied.site_identifier_for_arc_like = searchFilter.prom;
      }
      // Ajout des filtres uniquement s'ils ne sont pas vides
      if (searchFilter.NumeroDeSerie !== "") {
        filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
      }
      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }

      // Define constants for time calculations in milliseconds
      const last48HoursInMS = 48 * 60 * 60 * 1000; // Last 48 hours
      const last24HoursInMS = 24 * 60 * 60 * 1000; // Last 24 hours
      const lastHourInMS = 60 * 60 * 1000; // Last hour
      if (searchFilter.startDate !== "all") {
        const now = new Date(); // Current date
        let desiredTime = 0; // Initialize desired time variable

        if (searchFilter.startDate === "last48") {
          desiredTime = now.getTime() - last48HoursInMS;
        } else if (searchFilter.startDate === "last24") {
          desiredTime = now.getTime() - last24HoursInMS; // Calculate timestamp for last 24 hours
        } else if (searchFilter.startDate === "lastHour") {
          desiredTime = now.getTime() - lastHourInMS; // Calculate timestamp for last hour
        }

        // Assign the computed timestamp to the filter if it's a valid date range
        if (desiredTime > 0) {
          const startDate = Math.floor(desiredTime / 1000);
          filterApplied.start_date = startDate; // Convert milliseconds to seconds
        }
      }
      console.log("Request Parameters:", filterApplied);
      try {
        const response = await getSessionsOperatorsList(filterApplied);

        // Vérifiez si la réponse contient une erreur
        if (response.data.errors) {
          const { code, message } = response.data.errors;

          // Si le code d'erreur est -37, traiter comme une session expirée
          if (code === "-37") {
            dispatch(
              notifyError("Session expirée, veuillez vous reconnecter.")
            );
            navigate("/login");
            return;
          }
          // Autres types d'erreurs peuvent être traités ici
          throw new Error(`Erreur API : ${message}`);
        }

        return response.data; // Retourner les données de l'API si aucune erreur
      } catch (err) {
        console.error("API Request Error:", err);
        throw err;
      }
    },
  });
  // const { data, isLoading } = useQuery<SessionsOperatorsResponse>({
  //   queryKey: ["sessions", sessionId, searchFilter, id, pagination],
  //   queryFn: async () => {
  //     if (!id) {
  //       throw new Error("Alarm ID is required but was not provided.");
  //     }

  //     const filterApplied: SessionsOperatorsListRequest = {
  //       sessionId,
  //       alarm_id: id,
  //       page_number: pagination.page, // this aligns with your API's page numbering
  //       max_results: pagination.pageSize,
  //     };

  //     if (searchFilter.prom !== "") {
  //       filterApplied.site_identifier_for_arc_like = searchFilter.prom;
  //     }
  //     // Ajout des filtres uniquement s'ils ne sont pas vides
  //     if (searchFilter.NumeroDeSerie !== "") {
  //       filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
  //     }
  //     if (searchFilter.Utilisateur !== "") {
  //       filterApplied.user_tag_like = searchFilter.Utilisateur;
  //     }

  //     // Define constants for time calculations in milliseconds
  //     const last48HoursInMS = 48 * 60 * 60 * 1000; // Last 48 hours
  //     const last24HoursInMS = 24 * 60 * 60 * 1000; // Last 24 hours
  //     const lastHourInMS = 60 * 60 * 1000; // Last hour
  //     if (searchFilter.startDate !== "all") {
  //       const now = new Date(); // Current date
  //       let desiredTime = 0; // Initialize desired time variable

  //       if (searchFilter.startDate === "last48") {
  //         desiredTime = now.getTime() - last48HoursInMS;
  //       } else if (searchFilter.startDate === "last24") {
  //         desiredTime = now.getTime() - last24HoursInMS; // Calculate timestamp for last 24 hours
  //       } else if (searchFilter.startDate === "lastHour") {
  //         desiredTime = now.getTime() - lastHourInMS; // Calculate timestamp for last hour
  //       }

  //       // Assign the computed timestamp to the filter if it's a valid date range
  //       if (desiredTime > 0) {
  //         const startDate = Math.floor(desiredTime / 1000);
  //         filterApplied.start_date = startDate; // Convert milliseconds to seconds
  //       }
  //     }
  //     console.log("Request Parameters:", filterApplied);
  //     try {
  //       const response = await getSessionsOperatorsList(filterApplied);
  //       console.log("Response Data:", response.data);
  //       return response.data;
  //     } catch (err) {
  //       console.error("API Request Error:", err);
  //       throw err;
  //     }
  //   },
  // });

  // Memorizing sessions  for an id alarm
  const memoizedSessionsList = useMemo(() => data?.data || [], [data]);
  console.log("memoizedSessionsList", memoizedSessionsList);

  const dateFilterOptions = [
    { key: 0, label: t("Filtrer par date"), value: "all" },
    { key: 1, label: t("Les dernières 48h"), value: "last48" },
    { key: 2, label: t("Les dernières 24h"), value: "last24" },
    { key: 3, label: t("La dernière heure"), value: "lastHour" },
  ];

  // Determine the CSS class for a row based on whether its index is even or odd
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  //row count
  const totalItems = data?.total ?? 0;
  const totalPages = data?.has_more ? pagination.page + 1 : pagination.page;
  const isLastPage = data?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // Standard case

  //Columns
  const columns: GridColDef[] = [
    {
      field: "id_users",
      headerName: t("Id Utilisateur"),
      flex: 3,
      minWidth: 350,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          Id {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <div className="custom-cell">
          <span>{params.row && params.row.id_users}</span>
        </div>
      ),
    },
    {
      field: "user_Tag",
      headerName: "Utilisateur",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: false,
      disableColumnMenu: true,
      flex: 1.7,
      minWidth: 170,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <div className="custom-cell">
          <span>{params.row && params.row.user_Tag}</span>
        </div>
      ),
    },
    {
      field: "serial_number",
      headerName: t("numeroSerie"),
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: false,
      disableColumnMenu: true,
      flex: 1.5,
      minWidth: 150,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("numeroSerie")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.serial_number}</span>
        </div>
      ),
    },
    {
      field: "site_identifier_for_arc",
      headerName: "PROM",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {"PROM"}
        </span>
      ),
      renderCell: (params) => (
        <div className="custom-cell">
          <span>{params.row && params.row.site_identifier_for_arc}</span>
        </div>
      ),
    },
    {
      field: "start_date",
      headerName: t("Date d'installation"),
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: true,
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("dateInstallation")}
        </span>
      ),
      renderCell: (params) => (
        <div className="custom-cell">
          <span>{formatDate(params.row && params.row.start_date)}</span>
        </div>
      ),
    },
    {
      field: "end_date",
      headerName: t("dateFin"),
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: true,
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("dateFin")}
        </span>
      ),
      renderCell: (params) => (
        <div className="custom-cell">
          <span>{formatDate(params.row && params.row.end_date)}</span>
        </div>
      ),
    },

    {
      field: "actions",
      flex: 0.2,
      minWidth: 100,
      headerName: t("Actions"),
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Actions")}
        </span>
      ),
      renderCell: (params) => (
        <strong>
          <IconButton
            onClick={() => handleOpenDialog(params.row.alarmVideoClip)}
            style={{ color: "grey" }}
          >
            <NotificationsActiveIcon sx={{ color: "#5585b5" }} />
          </IconButton>
        </strong>
      ),
    },
  ];

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 29.5,
          marginTop: 12,
          width: "calc(100% - 252px)",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white" }}
          >
            {t("Sessions Operators")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        {/*<Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>*/}
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          fullWidth
        />
        <SearchInput
          placeHolder={t("Filtrer par numéro de série")}
          searchLabel={t("Filtrer par numéro de série")}
          fieldName="NumeroDeSerie"
          value={filterData.NumeroDeSerie}
          valueChange={changeFilterData}
          size="small"
          fullWidth
        />
        <SearchInput
          placeHolder={t("Filtrer par PROM")}
          searchLabel={t("Filtrer par PROM")}
          fieldName="prom"
          value={filterData.prom}
          valueChange={changeFilterData}
          size="small"
          width="90%"
        />

        <SelectInput
          value={filterData.startDate}
          valueChange={changeFilterData}
          placeHolder={t("Filtrer par date")}
          searchLabel={t("Filtrer par date")}
          fieldName="startDate"
          options={dateFilterOptions}
          size="small"
          fullWidth
        />
      </Box>
      <Box
        sx={{ flexGrow: 1, marginLeft: "222px", width: "calc(100% - 250px)" }}
      >
        <DataGrid
          rows={memoizedSessionsList}
          columns={columns}
          rowCount={rowCount}
          loading={isLoading}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
            // Enregistrer la taille de la page dans localStorage
            localStorage.setItem("pageSize", model.pageSize.toString());
          }}
          getRowId={(row) => row.id_session}
          getRowClassName={getRowClassName}
          onSortModelChange={handleSortModelChange}
          className="custom-scrollbar"
          sx={{
            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            height: "calc(100vh - 300px)",
            marginLeft: 2,
            "& .even-row": {
              backgroundColor: "#ffffff",
            },
            "& .odd-row": {
              backgroundColor: "#eff9ff",
              // Masquer les ellipses de tri et de filtre
              "& .MuiDataGrid-menuIcon": {
                display: "none",
              },
              "& .MuiDataGrid-sortIcon": {
                display: "none",
              },
            },
          }}
        />
        <Box sx={{ position: "relative" }}>
          {" "}
          {/* Parent avec position relative */}
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: { xs: 35, sm: 35, md: -50 },
              right: { xs: "2rem", sm: "2rem", md: "3rem" },
              padding: 2,
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" },
              marginRight: { xs: 1, sm: 2, md: 1 },
              marginBottom: { xs: 1, sm: 1, md: 6.5 },
            }}
          >
            {t("Page")} {pagination.page}
          </Typography>
        </Box>
      </Box>
      <VideoDialog
        open={open}
        onClose={handleCloseDialog}
        videoUrl={selectedVideoUrl}
      />
    </Box>
  );
};

export default SessionsOperators;
