/*import React, { useEffect } from "react";
import i18n from "../../i18n";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import logo from "../../assets/images/image.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FranceFlag from "../../assets/images/france-flag.png";
import UKFlag from "../../assets/images/uk-flag.png";
import PortugalFlag from "../../assets/images/portugal-flag.png";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch } from "../../store/hooks";
import { invalidateSession } from "../../store/reducers/user.slice";

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lang } = useParams<{ lang: string }>();
  console.log("Current language from URL:", lang);
  

  // Set the default language to 'fr' if no language is specified
  const defaultLanguage = "fr";
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(lang || defaultLanguage);

  // Function to update the URL with the new language
  /*const updateUrlLanguage = (newLang: string) => {
    const path = location.pathname.replace(`/${lang}`, `/${newLang}`);
    navigate(path, { replace: true });
  };*/

/*
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLang = event.target.value;
    i18n.changeLanguage(newLang);
    updateUrlLanguage(newLang);
    setSelectedLanguage(newLang);
  };
 useEffect(() => {
    // Synchronize selectedLanguage with lang from URL
    if (lang && lang !== selectedLanguage) {
      setSelectedLanguage(lang);
      i18n.changeLanguage(lang); // Update i18n language when lang changes
    }
  }, [lang, selectedLanguage]);

  const updateUrlLanguage = (newLang: string) => {

    // Remove existing language prefix from current path
    const pathWithoutLang = location.pathname.replace(/^\/[a-z]{2}/, '');
  
    // Add new language prefix to path
    const newPathname = `/${newLang}${pathWithoutLang}`;
    console.log("new",newPathname )
  
    navigate(newPathname, { replace: true });
  };

  

  const handleLogout = (): void => {
    dispatch(invalidateSession());
    navigate(`/${selectedLanguage}/login`); // Redirect to login page
  };

  const isLoginPage = location.pathname.includes("/login");

  return (
    <div>
      <AppBar position="fixed" color="inherit" sx={{backgroundColor:'rgb(244, 245, 250)'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={logo}
                alt="ACOBA"
                style={{ width: 100, height: "auto" }}
              />
              {!isLoginPage && (
                <Typography
                  variant="h6"
                  sx={{ marginLeft: 12, color: "rgb(82, 116, 150)" }}
                >
                  {t("dashboard")}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  aria-label="Language select"
                  sx={{ color: "black" }}
                >
                  <MenuItem value="fr">
                    <img
                      src={FranceFlag}
                      alt="French"
                      style={{ width: 20, height: 15, marginRight: 5 }}
                    />
                    Français
                  </MenuItem>
                  <MenuItem value="en">
                    <img
                      src={UKFlag}
                      alt="English"
                      style={{ width: 20, height: 15, marginRight: 5 }}
                    />
                    English (US)
                  </MenuItem>
                  <MenuItem value="pt">
                    <img
                      src={PortugalFlag}
                      alt="Portuguese"
                      style={{ width: 20, height: 15, marginRight: 5 }}
                    />
                    Português
                  </MenuItem>
                </Select>
              </FormControl>
              {!isLoginPage && (
                <IconButton
                  size="large"
                  aria-label="logout"
                  onClick={handleLogout}
                  color="inherit"
                >
                  <LogoutIcon />
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Header;*/
import React, { useState } from "react";
import i18n from "../../i18n";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import logo from "../../assets/images/image.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FranceFlag from "../../assets/images/france-flag.png";
import UKFlag from "../../assets/images/uk-flag.png";
import PortugalFlag from "../../assets/images/portugal-flag.png";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch } from "../../store/hooks";
import { invalidateSession } from "../../store/reducers/user.slice";
import { useLanguage } from "../../components/common/LanguageContext";

const Header = () => {
  // Initialize translation function
  const { t } = useTranslation();
  // Get current route location
  const location = useLocation();
  // Hook for navigation
  const navigate = useNavigate();
  // Hook to dispatch actions
  const dispatch = useAppDispatch();

  // State for selected language
  const { selectedLanguage, setSelectedLanguage } = useLanguage();

  // const updateUrlLanguage = (newLang: string) => {
  //   const currentPath = location.pathname.split('/').slice(2).join('/');
  //   navigate(`/${newLang}/${currentPath}`);
  // };

  // Handle language change
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLang = event.target.value;
    setSelectedLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  // Handle logout
  const handleLogout = (): void => {
    dispatch(invalidateSession());
    navigate(`/login`); // Redirect to login page
  };

  // Determine if it's the login page
  const isLoginPage = location.pathname.includes("/login");

  return (
    <div>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ backgroundColor: "rgb(244, 245, 250)" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={logo}
                alt="ACOBA"
                style={{ width: 110, height: "auto" }}
              />
              {!isLoginPage && (
                <Typography
                  variant="h6"
                  sx={{ marginLeft: 12, color: "rgb(82, 116, 150)" }}
                >
                  {t("dashboard")}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  aria-label="Language select"
                  sx={{ color: "black" }}
                >
                  <MenuItem value="fr">
                    <img
                      src={FranceFlag}
                      alt="French"
                      style={{ width: 20, height: 15, marginRight: 5 }}
                    />
                    Français
                  </MenuItem>
                  <MenuItem value="en">
                    <img
                      src={UKFlag}
                      alt="English"
                      style={{ width: 20, height: 15, marginRight: 5 }}
                    />
                    English (US)
                  </MenuItem>
                  <MenuItem value="pt">
                    <img
                      src={PortugalFlag}
                      alt="Portuguese"
                      style={{ width: 20, height: 15, marginRight: 5 }}
                    />
                    Português
                  </MenuItem>
                </Select>
              </FormControl>
              {/* Icône pour changer de thème */}

              {!isLoginPage && (
                <IconButton
                  size="large"
                  aria-label="logout"
                  onClick={handleLogout}
                  color="inherit"
                >
                  <LogoutIcon />
                  {/* <Typography sx={{ marginLeft: 1, color: "black" }}>
                      {t("logout")}
                 </Typography> */}
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Header;
