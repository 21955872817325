import axios from "axios";
import getConfiguration from "../../config/config";
import { GetUsersListRequest } from "../../types/Requests";
import { APIPaths } from "../paths";
import { getUserSession } from "../../utils/auth";
import { UserAddRequest } from "../../types/Requests";
import { UserDetailsRequest } from "../../types/Requests";
import { UserUpdateRequest } from "../../types/Requests";

/**
 * Get users list
 * @param {GetUsersListRequest} request
 * @returns {Promise} Promise containing the list of users.
 */
const getUsersList = (request: GetUsersListRequest) => {
  const configuration = getConfiguration();
  return axios.get(configuration.apiPath + APIPaths.usersList, {
    // full url
    params: {
      ...request,
    },
  });
};

/**
 * Adds a new user.
 * @param request The user data to be added.
 * @returns {Promise<AddUserResponse>} Promise containing the response data of the added user.
 */

const addUser = async (request: UserAddRequest) => {
  try {
    const sessionId = getUserSession();
    if (!sessionId) {
      throw new Error("Session ID is required"); // Vérification de l'ID de session
    }
    const configuration = getConfiguration();
    const url = `${configuration.apiPath}${APIPaths.userAdd}?sessionId=${sessionId}`;
    const response = await axios.post(url, request, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
    });
    return response.data;
  } catch (error) {
    // Gestion des erreurs
    console.error("Error adding user:", error);
    throw error;
  }
};

/**
 * Updates the details of a specific user.
 * @param {UserUpdateRequest} request
 * @returns {Promise<UpdateUserResponse >} Promise indicating the success of the operation.
 */
const updateUser = async (request: UserUpdateRequest) => {
  try {
    const configuration = getConfiguration();
    const url = `${configuration.apiPath}${APIPaths.userUpdate}`;
    const response = await axios.post(url, {
      ...request,
    });
    return response.data;
  } catch (error) {
    // Gestion des erreurs
    console.error("Error adding user:", error);
    throw error;
  }
};

// User Details
/**
 * Retrieves detailed information about a specific user.
 * @param {UserDetailsRequest} request - UserDetailsRequest specifying the user details to be retrieved.
 * @returns {Promise<any>} Promise containing detailed information about the camera.
 */
const getUsersDetails = (request: UserDetailsRequest): Promise<any> => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.userDetails, {
    ...request, // sessionId is already included in request
  });
};

export { getUsersList, addUser, getUsersDetails, updateUser };
