import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Définir la forme de l'état initial avec les nouvelles propriétés
type PaginationState = {
  page: number;
  pageSize: number;

};

// Définir l'état initial
const initialState: PaginationState = {
  page: 0,
  pageSize: 10,
 
};

// Créer un slice Redux pour la pagination
const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    // Reducer pour définir la page actuelle
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    // Reducer pour définir la taille de la page
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
   
  },
});

// Exporter le reducer et les actions
export default paginationSlice.reducer;
export const { setPage, setPageSize } = paginationSlice.actions;
