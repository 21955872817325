import React, { useEffect, useMemo, useState } from "react";
import { GeneralSessionsOperatorsResponse } from "../../../types/Responses";
import { getUserSession } from "../../../utils/auth";
import { useQuery } from "@tanstack/react-query";
import { generalSessionsOperatorsList } from "../../../services/alarm/alarm.service";
import { GeneralSessionsOperatorsRequest } from "../../../types/Requests";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LongMenu from "../../common/LongMenu";
import SearchInput from "../../reusable/inputs/SearchInput";
import SelectInput from "../../reusable/inputs/SelectInput";
import VideoDialog from "./VideoDialog";
import CustomPagination from "../../reusable/tables/CustomPagination";
import "./Sessions.css";
import { useAppDispatch } from "../../../store/hooks";
import { notifyError } from "../../../store/reducers/notification.slice";

interface SessionData {
  id_session: string;
  id_users: string;
  start_date: string;
  end_date: string;
  file_name: string;
  user_Tag: string;
  site_identifier_for_arc: string;
  user_tag: string;
  serial_number: string;
  alarmVideoClip: string;
}

type FilterData = {
  NumeroDeSerie: string;
  Utilisateur: string;
  prom: string;
  startDate: string;
};
function TotalSessionOperators() {
  const [dateFilter, setDateFilter] = useState("all");

  const sessionId = getUserSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dateFilterOptions = [
    { key: 0, label: t("Filtrer par date"), value: "all" },
    { key: 1, label: t("Les dernières 48h"), value: "last48" },
    { key: 2, label: t("Les dernières 24h"), value: "last24" },
    { key: 3, label: t("La dernière heure"), value: "lastHour" },
  ];

  //displayed filter GUI control
  const [filterData, setFilterData] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
    startDate: "",
  });

  //actual filter applied on data
  const [searchFilter, setSearchFilter] = useState<FilterData>({
    NumeroDeSerie: "",
    Utilisateur: "",
    prom: "",
    startDate: "",
  });

  const [sortBy, setSortBy] = useState<string>("start_date");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined | null>(
    "ASC"
  );
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  // useEffect pour récupérer la taille de page depuis localStorage
  useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPagination((prev) => ({ ...prev, pageSize: Number(storedPageSize) }));
    }
  }, []);

  //GUI filter change
  const changeFilterData = (field: string, data: string) => {
    // call filter API
    setFilterData((oldFilter) => {
      return { ...oldFilter, [field]: data };
    });
  };
  //applied filter change
  const changeSearchFilterData = (data: FilterData) => {
    setSearchFilter({ ...data });
  };
  //debounce time
  const waitTime = 2000;
  //debounce state (note: needs to be a state so that the GUI effect catches the change and applies the filter right after it gets set to true)
  const [waited, setWaited] = useState<boolean>(true);

  const unequalFilters = (current: FilterData, next: FilterData) => {
    return (
      current.NumeroDeSerie !== next.NumeroDeSerie ||
      current.Utilisateur !== next.Utilisateur ||
      current.prom !== next.prom ||
      current.startDate !== next.startDate
    );
  };
  // effect to match GUI filter to applied filter in an interval of the specified debounce time
  useEffect(() => {
    if (waited && unequalFilters(searchFilter, filterData)) {
      setWaited(false);
      changeSearchFilterData({ ...filterData });
      setTimeout(() => {
        setWaited(true);
      }, waitTime);
    }
    // eslint-disable-next-line
  }, [filterData, waited]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    // Vérifier si le modèle de tri contient des éléments
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0];
      // Convertir la valeur de 'sort' en "ASC" ou "DESC"
      const normalizedSort: "ASC" | "DESC" | null = sort
        ? (sort.toUpperCase() as "ASC" | "DESC")
        : null;
      setSortBy(field);
      setSortOrder(normalizedSort);
    } else {
      setSortBy("");
      setSortOrder(null);
    }
  };

  // const { data, isLoading } = useQuery<GeneralSessionsOperatorsResponse>({
  //   queryKey: ["sessionsOp", sessionId, searchFilter, pagination],
  //   queryFn: async () => {
  //     const filterApplied: GeneralSessionsOperatorsRequest = {
  //       sessionId,
  //       page_number: pagination.page, // this aligns with your API's page numbering
  //       max_results: pagination.pageSize,
  //     };

  //     // Ajout des filtres uniquement s'ils ne sont pas vides
  //     if (searchFilter.NumeroDeSerie !== "") {
  //       filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
  //     }
  //     if (searchFilter.Utilisateur !== "") {
  //       filterApplied.user_tag_like = searchFilter.Utilisateur;
  //     }
  //     if (searchFilter.prom !== "") {
  //       filterApplied.site_identifier_for_arc_like = searchFilter.prom;
  //     }

  //     // Define constants for time calculations in milliseconds
  //     const last48HoursInMS = 48 * 60 * 60 * 1000; // Last 48 hours
  //     const last24HoursInMS = 24 * 60 * 60 * 1000; // Last 24 hours
  //     const lastHourInMS = 60 * 60 * 1000; // Last hour
  //     if (searchFilter.startDate !== "all") {
  //       const now = new Date(); // Current date
  //       let desiredTime = 0; // Initialize desired time variable

  //       if (searchFilter.startDate === "last48") {
  //         desiredTime = now.getTime() - last48HoursInMS;
  //       } else if (searchFilter.startDate === "last24") {
  //         desiredTime = now.getTime() - last24HoursInMS; // Calculate timestamp for last 24 hours
  //       } else if (searchFilter.startDate === "lastHour") {
  //         desiredTime = now.getTime() - lastHourInMS; // Calculate timestamp for last hour
  //       }

  //       // Assign the computed timestamp to the filter if it's a valid date range
  //       if (desiredTime > 0) {
  //         const startDate = Math.floor(desiredTime / 1000);
  //         filterApplied.start_date = startDate; // Convert milliseconds to seconds
  //       }
  //     }
  //     // Appel de la fonction generalSessionsOperatorsList avec les filtres appliqués
  //     return generalSessionsOperatorsList(filterApplied).then((response) => {
  //       console.log("Response3333:", response.data);
  //       return response.data; // Extraire les données de la réponse Axios
  //     });
  //   },
  // });

  const { data, isLoading } = useQuery<GeneralSessionsOperatorsResponse>({
    queryKey: [
      "sessionsOp",
      sessionId,
      searchFilter,
      sortBy,
      sortOrder,
      pagination,
    ],
    queryFn: async () => {
      const filterApplied: GeneralSessionsOperatorsRequest = {
        sessionId,
        page_number: pagination.page, // this aligns with your API's page numbering
        max_results: pagination.pageSize,
        sort_by: sortBy,
        sort_order: sortOrder ?? "ASC",
      };

      // Ajout des filtres uniquement s'ils ne sont pas vides
      if (searchFilter.NumeroDeSerie !== "") {
        filterApplied.serial_number_like = searchFilter.NumeroDeSerie;
      }
      if (searchFilter.Utilisateur !== "") {
        filterApplied.user_tag_like = searchFilter.Utilisateur;
      }
      if (searchFilter.prom !== "") {
        filterApplied.site_identifier_for_arc_like = searchFilter.prom;
      }

      // Define constants for time calculations in milliseconds
      const last48HoursInMS = 48 * 60 * 60 * 1000; // Last 48 hours
      const last24HoursInMS = 24 * 60 * 60 * 1000; // Last 24 hours
      const lastHourInMS = 60 * 60 * 1000; // Last hour
      if (searchFilter.startDate !== "all") {
        const now = new Date(); // Current date
        let desiredTime = 0; // Initialize desired time variable

        if (searchFilter.startDate === "last48") {
          desiredTime = now.getTime() - last48HoursInMS;
        } else if (searchFilter.startDate === "last24") {
          desiredTime = now.getTime() - last24HoursInMS; // Calculate timestamp for last 24 hours
        } else if (searchFilter.startDate === "lastHour") {
          desiredTime = now.getTime() - lastHourInMS; // Calculate timestamp for last hour
        }

        // Assign the computed timestamp to the filter if it's a valid date range
        if (desiredTime > 0) {
          const startDate = Math.floor(desiredTime / 1000);
          filterApplied.start_date = startDate; // Convert milliseconds to seconds
        }
      }
      try {
        const response = await generalSessionsOperatorsList(filterApplied);

        // Vérifiez si la réponse contient une erreur
        if (response.data.errors) {
          const { code, message } = response.data.errors;

          // Si le code d'erreur est -37, traiter comme une session expirée
          if (code === "-37") {
            dispatch(
              notifyError("Session expirée, veuillez vous reconnecter.")
            );
            navigate("/login");
            return;
          }

          // Autres types d'erreurs peuvent être traités ici
          throw new Error(`Erreur API : ${message}`);
        }

        return response.data; // Retourner les données de l'API si aucune erreur
      } catch (err) {
        console.error("API Request Error:", err);
        throw err; // Rejeter l'erreur pour une gestion ultérieure
      }
    },
  });

  // Memorizing sessions
  const sessions = useMemo(() => data?.data || [], [data]);

  // The filter function
  // const filterTotalSessionsByDate = (
  //   totalSessions: SessionData[],
  //   dateFilter: string
  // ) => {
  //   const now = new Date().getTime();
  //   return totalSessions.filter((session) => {
  //     const startDate = new Date(session.start_date).getTime();
  //     const timeDifference = now - startDate;

  //     console.log(
  //       `Now: ${now}, Start Date: ${startDate}, Difference: ${timeDifference}`
  //     );

  //     switch (dateFilter) {
  //       case "last48":
  //         return timeDifference <= 48 * 60 * 60 * 1000; // Last 48 hours
  //       case "last24":
  //         return timeDifference <= 24 * 60 * 60 * 1000; // Last 24 hours
  //       case "lastHour":
  //         return timeDifference <= 60 * 60 * 1000; // Last 1 hour
  //       default:
  //         return true; // No filter
  //     }
  //   });
  // };

  // Appliquer le filtrage
  // const filteredSessions = useMemo(
  //   () => filterTotalSessionsByDate(sessions, dateFilter),
  //   [sessions, dateFilter]
  // );

  // Formate une date UTC en date locale avec le fuseau horaire de l'utilisateur

  const formatDate = (dateString: string | null) => {
    if (!dateString || dateString === "0000-00-00 00:00:00") return "-";

    const utcDate = new Date(dateString + " UTC");

    if (isNaN(utcDate.getTime())) return "-"; // Vérifie si la date est invalide

    return utcDate.toLocaleString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const menuItems = [
    /*{
          id: "1",
          label: "Vidéo clip d'alarme",
          color: "black",
          handleClick: async (row: AlarmData) => {
            console.log("Alarm data clicked:", row);
            try {
              // Set the selected alarm ID
              setSelectedAlarmId(row.id_alarm);
              // Fetch video URL
              const videoUrl = await getVideoAlarm({ sessionId, alarm_id: row.id_alarm }).then(res => res.data.videoUrl);
              setSelectedVideoUrl(videoUrl);
              setOpenDialog(true);
            } catch (error) {
              console.error("Failed to fetch video URL", error);
            }
          },
        },*/
    {
      id: "2",
      label: t("Vidéo clip de record"),
      color: "black",
      handleClick: async (row: SessionData) => {
        // Set the selected video URL
        setSelectedVideoUrl(row.alarmVideoClip);
        // Open the dialog
        setOpenDialog(true);
      },
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id_users",
      headerName: "Id Utilisateur",
      flex: 2.5,
      minWidth: 250,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          Id {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.id_users}</span>
        </div>
      ),
    },
    {
      field: "user_Tag",
      headerName: "Utilisateur",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: false,
      disableColumnMenu: true,
      flex: 1.7,
      minWidth: 170,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("Utilisateur")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.user_Tag}</span>
        </div>
      ),
    },

    {
      field: "serial_number",
      headerName: t("numeroSerie"),
      headerClassName: "custom-header",
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "custom-cel",
      flex: 1.2,
      minWidth: 120,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("numeroSerie")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{params.row && params.row.serial_number}</span>
        </div>
      ),
    },
    {
      field: "site_identifier_for_arc",
      headerName: "PROM",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {"PROM"}
        </span>
      ),
      renderCell: (params) => {
        console.log("paramsrow:", params.row);
        return (
          <div>
            <span>{params.row && params.row.site_identifier_for_arc}</span>
          </div>
        );
      },
    },

    {
      field: "start_date",
      headerName: "Date d'installation",
      sortable: true,
      flex: 1.2,
      minWidth: 160,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("dateInstallation")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{formatDate(params.row && params.row.start_date)}</span>
        </div>
      ),
    },
    {
      field: "end_date",
      headerName: "Date de fin",
      sortable: true,
      flex: 1.2,
      minWidth: 160,
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {t("dateFin")}
        </span>
      ),
      renderCell: (params) => (
        <div>
          <span>{formatDate(params.row && params.row.end_date)}</span>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "custom-header",
      cellClassName: "custom-cel",
      flex: 0.2,
      minWidth: 100,
      renderHeader: () => (
        <span
          className="header-name"
          style={{ fontWeight: "bold", color: "#395069" }}
        >
          {"Actions"}
        </span>
      ),
      renderCell: (params) => (
        <strong>
          <LongMenu menuItems={menuItems} row={params.row} />
        </strong>
      ),
    },
  ];

  // Determine the CSS class for a row based on whether its index is even or odd
  const getRowClassName = (params: GridRowParams<any>): string => {
    const index = (params as any).indexRelativeToCurrentPage;
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  // row count
  const totalItems = data?.total ?? 0;
  const totalPages = data?.has_more ? pagination.page + 1 : pagination.page;
  const isLastPage = data?.has_more === false;

  const rowCount = isLastPage
    ? (totalPages - 1) * pagination.pageSize + totalItems // Last page contains fewer items
    : totalPages * pagination.pageSize; // Standard case

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#395069",
          marginLeft: 29.5,
          marginTop: 12,
          width: "calc(100% - 252px)",
          height: 55,
          borderRadius: "4px",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, color: "white" }}
          >
            {t("Sessions opérateurs")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <SearchInput
          placeHolder={t("Filtrer par utilisateur")}
          searchLabel={t("Filtrer par utilisateur")}
          fieldName="Utilisateur"
          value={filterData.Utilisateur}
          valueChange={changeFilterData}
          size="small"
          fullWidth
        />
        <SearchInput
          placeHolder={t("Filtrer par numéro de série")}
          searchLabel={t("Filtrer par numéro de série")}
          fieldName="NumeroDeSerie"
          value={filterData.NumeroDeSerie}
          valueChange={changeFilterData}
          size="small"
          fullWidth
        />

        <SearchInput
          placeHolder={t("Filtrer par PROM")}
          searchLabel={t("Filtrer par PROM")}
          fieldName="prom"
          value={filterData.prom}
          valueChange={changeFilterData}
          size="small"
          fullWidth
        />
        <SelectInput
          value={filterData.startDate}
          valueChange={changeFilterData}
          placeHolder={t("Filtrer par date")}
          searchLabel={t("Filtrer par date")}
          fieldName="startDate"
          options={dateFilterOptions}
          size="small"
          fullWidth
        />
      </Box>
      <Box
        sx={{ flexGrow: 1, marginLeft: "225px", width: "calc(100% - 250px)" }}
      >
        <DataGrid
          rows={sessions}
          columns={columns}
          loading={isLoading}
          rowCount={rowCount}
          paginationMode="server"
          slots={{
            pagination: CustomPagination,
          }}
          pageSizeOptions={[5, 10, 15]}
          paginationModel={{
            //This prop is used to control the pagination state.
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPagination((prev) => ({
              ...prev,
              page: model.page + 1,
              pageSize: model.pageSize,
            }));
            // Enregistrer la taille de la page dans localStorage
            localStorage.setItem("pageSize", model.pageSize.toString());
          }}
          className="custom-scrollbar"
          getRowClassName={getRowClassName}
          getRowId={(row) => row.id_session}
          onSortModelChange={handleSortModelChange}
          sx={{
            "& .MuiTablePagination-actions button": {
              color: "black",
              width: "90px",
            },
            "& .MuiTablePagination-actions button svg": {
              fontSize: "25px",
            },
            height: "calc(100vh - 300px)",
            marginLeft: 2,

            "& .even-row": {
              backgroundColor: "#ffffff",
            },
            "& .odd-row": {
              backgroundColor: "#eff9ff",
            },
            // Masquer les ellipses de tri et de filtre
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
          }}
        />
        <Box sx={{ position: "relative" }}>
          {" "}
          {/* Parent avec position relative */}
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              bottom: { xs: 35, sm: 35, md: -50 }, // Décalage plus bas
              right: { xs: "2rem", sm: "2rem", md: "3rem" }, // Position de droite, responsive
              padding: 2, // Padding optionnel
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" }, // Taille de police responsive
              marginRight: { xs: 1, sm: 2, md: 1 }, // Marge droite responsive
              marginBottom: { xs: 1, sm: 1, md: 6.5 }, // Marge basse responsive
            }}
          >
            {t("Page")} {pagination.page}
          </Typography>
        </Box>
      </Box>
      <VideoDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        videoUrl={selectedVideoUrl}
      />
    </Box>
  );
}

export default TotalSessionOperators;
