import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../../services/user/user.service";
import { UserUpdateRequest } from "../../../types/Requests";
import { useQueryClient } from "@tanstack/react-query";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import { useAppDispatch } from "../../../store/hooks";
import { AxiosError } from "axios";

const countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

interface EditClientProps {
  open: boolean;
  handleClose: () => void;
  onCreateSuccessCB: () => void; // Callback for successful update
  userData: {
    firstName: string;
    lastName: string;
    user_email: string;
    phone: string;
    mobile: string;
    address: string;
    address2: string;
    city: string;
    postCode: string;
    country: string;
    isCompany: boolean;
    companyName: string;
    idCompany: string;
    VAT: string;
  };
  sessionId: string;
  userId: string;
}

const EditClient: React.FC<EditClientProps> = ({
  open,
  handleClose,
  onCreateSuccessCB,
  userData,
  sessionId,
  userId,
}) => {
  //Hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  // State to manage dialog and user data
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: userData,
  });

  // Update state with the received userData whenever it changes
  useEffect(() => {
    setEditDialog((prevState) => ({
      ...prevState,
      data: userData,
    }));
  }, [userData]);

  // Handle input changes for the form
  const onEditClientFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setEditDialog((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  // Handle checkbox changes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setEditDialog((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: checked,
      },
    }));
  };

  const handleCountryChange = (event: any, value: string | null) => {
    setEditDialog((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        country: value || "",
      },
    }));
  };

  // Function for updating the user
  const handleUpdateUser = async () => {
    const request: UserUpdateRequest = {
      ...editDialog.data,
      sessionId: sessionId,
      user_id: userId,
    };

    try {
      // Attempt to update the user
      await updateUser(request);

      // Only dispatch success notification if update is successful
      onCreateSuccessCB();
      queryClient.invalidateQueries({ queryKey: ["users", userId] }); // Invalidate the query to refresh the data
      dispatch(notifySuccess(t("Client updated successfully")));
      handleClose(); // Close the edit dialog
    } catch (error: any) {
      // Log the full error object for debugging
      console.log("Full error object:", error);

      // Check if error.response is defined and log it
      if (error.response) {
        console.log("Error response:", error.response);
        console.log("Error response data:", error.response.data);
        console.log("Error response status:", error.response.status);
      }

      // Extract error details from response
      const errorResponse = error.response?.data;
      if (errorResponse && errorResponse.errors) {
        // Handle specific error codes
        const errorCode = errorResponse.errors.code;
        const errorMessage = errorResponse.errors.message;
        if (errorCode === "-20" && errorMessage === "Login already exists") {
          // Handle duplicate login case
          console.log(
            "Displaying error notification for 'Login already exists'"
          );
          setEditDialog((prevState) => ({
            ...prevState,
            errorMessage: t("Email already exists"), 
          }));
          dispatch(notifyError(t("Email already exists"))); 
        } else if (
          errorCode === "-21" &&
          errorMessage === "Not a valid Email ID"
        ) {
          // Handle invalid email case
          console.log(
            "Displaying error notification for 'Not a valid Email ID"
          );
          setEditDialog((prevState) => ({
            ...prevState,
            errorMessage: t("Not a valid Email ID"), 
          }));
          dispatch(notifyError(t("Not a valid Email")));
        } else {
          // General error handling
          console.log("Displaying general error notification");
          dispatch(notifyError(t("Error when updating client"))); 
        }
      }
    }
  };

  /* useEffect(() => {
    setEditDialog((prevState) => ({
      ...prevState,
      data: userData,
      errorMessage: "", // Clear error message when userData changes
    }));
  }, [userData, open]);*/

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        className="DialogTitle"
        sx={{ backgroundColor: "#395069", color: "white" }}
      >
        {t("Edit Client")}
      </DialogTitle>
      <br />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={`${t("firstName")} `}
              name="firstName"
              value={editDialog.data.firstName}
              onChange={onEditClientFormChange}
              size="small"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
              disabled 
              //required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={`${t("lastName")} `}
              name="lastName"
              value={editDialog.data.lastName}
              onChange={onEditClientFormChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
              disabled 
              //required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={`${t("email")} `}
              name="user_email"
              value={editDialog.data.user_email}
              onChange={onEditClientFormChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("phone")}
              name="phone"
              value={editDialog.data.phone}
              onChange={onEditClientFormChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              disabled 
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("mobile")}
              name="mobile"
              value={editDialog.data.mobile}
              onChange={onEditClientFormChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              disabled 
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("adress")}
              name={t("adress")}
              value={editDialog.data.address}
              onChange={onEditClientFormChange}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("adress2")}
              name={t("address2")}
              value={editDialog.data.address2}
              onChange={onEditClientFormChange}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("city")}
              name="city"
              value={editDialog.data.city}
              onChange={onEditClientFormChange}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("postCode")}
              name="postCode"
              value={editDialog.data.postCode}
              onChange={onEditClientFormChange}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={countries}
              value={editDialog.data.country}
              onChange={handleCountryChange}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("country")}
                  variant="outlined"
                  fullWidth
                />
              )}
              disabled
            />
          </Grid>
          {/*<Grid item xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                {t("isCompanyDescription")}
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editDialog.data.isCompany}
                      onChange={handleCheckboxChange}
                      size="small"
                      name="isCompany"
                    />
                  }
                  label={t("yes")}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          {editDialog.data.isCompany && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("companyName")}
                  name="companyName"
                  value={editDialog.data.companyName}
                  onChange={onEditClientFormChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("idCompany")}
                  name="idCompany"
                  value={editDialog.data.idCompany}
                  onChange={onEditClientFormChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("VAT")}
                  name="VAT"
                  value={editDialog.data.VAT}
                  onChange={onEditClientFormChange}
                  size="small"
                />
              </Grid>
            </>
          )}*/}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleUpdateUser} variant="contained" color="primary">
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClient;
