import { css, SerializedStyles } from "@emotion/react";
import { Color } from "../../../constants/color";

// Type definition for the Basic Card styles
type BasicCardStyles = {
  cardContainer: SerializedStyles;
  cardTitle: SerializedStyles;
  cardDescription: SerializedStyles;
  [key: string]: SerializedStyles; //signature for dynamic keys
};

// Type definition for the Horizontal Card styles
type HorizontalCardStyles = {
  cardContainer: SerializedStyles;
  cardImageContainer: SerializedStyles;
  cardImage: SerializedStyles;
  cardContent: SerializedStyles;
  cardTitle: SerializedStyles;
  cardDescription: SerializedStyles;
  cardNote: SerializedStyles;
  [key: string]: SerializedStyles; //signature for dynamic keys
};

// Type definition for the Image Card styles
type ImageCardStyles = {
  cardContainer: SerializedStyles;
  cardImageContainer: SerializedStyles;
  cardImage: SerializedStyles;
  cardContent: SerializedStyles;
  cardTitle: SerializedStyles;
  cardDescription: SerializedStyles;
  cardActions: SerializedStyles;
  [key: string]: SerializedStyles; //signature for dynamic keys
};

// Styles for the Basic Card component
const basicCardStyle: BasicCardStyles = {
  // Styles for the container of the basic card
  cardContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 30px)",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: Color.AssetCardBg,
    borderRadius: 5,
    padding: 15,
    cursor: "pointer",
  }),

  // Styles for the title of the basic card
  cardTitle: css({
    fontWeight: "bold",
    textTransform: "uppercase",
    fontStyle: "italic",
  }),

  // Styles for the description of the basic card
  cardDescription: css({
    fontWeight: "lighter",
    fontStyle: "italic",
    display: "flex",
    textAlign: "left",
  }),
};

// Styles for the Horizontal Card component
export const horizontalCardStyle: HorizontalCardStyles = {
  // Styles for the container of the horizontal card
  cardContainer: css({
    width: "100%",
    minHeight: "70px",
    display: "flex",
    alignItems: "center",
    borderBottomWidth: 0.2,
    borderBottomStyle: "solid",
    borderBottomColor: Color.BorderGreyLight,
    position: "relative",
    overflowX: "hidden",
    cursor: "pointer",
     justifyContent:"center",
     marginLeft:"20px",

    
  }),

  // Styles for the image container of the horizontal card
  cardImageContainer: css({
    width: 40,
    height: 40,
    borderRadius: "50%",
    color: Color.TextWhite,
    backgroundColor: Color.TextPrimary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "clamp(0px, 5vw, 10px)"
  }),

  // Styles for the image of the horizontal card
  cardImage: css({
    width: 40,
    height: 40,
    borderRadius: "50%",
    color: Color.TextWhite,
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "16px",
  }),

  // Styles for the content of the horizontal card
  cardContent: css({
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    //justifyContent: "flex-start",
    justifyContent: "center", // Center the content vertically
    alignItems: "start", 
  }),

  // Styles for the title of the horizontal card
  cardTitle: css({
    textAlign: "left",
    color: Color.TextGrey,
  }),

  // Styles for the description of the horizontal card
  cardDescription: css({
    textAlign: "left",
  }),

  // Styles for the note of the horizontal card
  cardNote: css({
    position: "relative",
    right: '3rem',
    color: Color.TextPrimary,
  }),
};

// Styles for the Image Card component
export const imageCardStyle: ImageCardStyles = {
  // Styles for the container of the image card
  cardContainer: css({
    width: "250px",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: 0.2,
    borderStyle: "solid",
    borderColor: Color.BorderGreyLight,
    borderRadius: 5,
    position: "relative",
    overflowX: "hidden",
    cursor: "pointer",
    boxShadow: Color.BluredDivBg + " 1.95px 1.95px 2.6px",
  }),

  // Styles for the image container of the image card
  cardImageContainer: css({
    width: "100%",
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),

  // Styles for the image of the image card
  cardImage: css({
    height: 150,
    borderRadius: "50%",
    color: Color.TextWhite,
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "22px",
  }),

  // Styles for the content of the image card
  cardContent: css({
    display: "flex",
    height: "calc(100px - 10px)",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 10,
  }),

  // Styles for the title of the image card
  cardTitle: css({
    textAlign: "left",
    color: Color.TextBlack,
    fontWeight: "600",
  }),

  // Styles for the description of the image card
  cardDescription: css({
    textAlign: "left",
    color: Color.TextBlack,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "3",
    whiteSpace: "pre-wrap",
  }),

  // Styles for the actions container of the image card
  cardActions: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 30,
    width: "calc(100% - 20px)",
    padding: 10,
  }),
};

// Default export for the basic card styles
export default basicCardStyle;
