/**
 * Sets an item in local storage with the specified key and value.
 * @param {string} key - The local storage key that will later identify the stored value.
 * @param {any} value - The value to be stored in the localstorage under its relative key.
 */
export function setItemInLocalStorage(key: string, value: any) {
    // Check if the value is a string.
    if (typeof value === "string") {
      // Set the string value in local storage.
      window.localStorage.setItem(key, value);
    } else if (value !== undefined && value !== null) {
      // Stringify and set the non-string value in local storage.
      let stringifiedValue = JSON.stringify(value);
      if (typeof stringifiedValue === "string") {
        window.localStorage.setItem(key, stringifiedValue);
      } else {
        // Log an error if the value cannot be stringified.
        console.log("You passed a value that cannot be stringified.");
      }
    }
  }
  
  /**
   * Retrieves an item from local storage with the specified key.
   * @param {string} key - The local storage key that identifies the stored value.
   * @returns The stored value if it exists or null.
   */
  export function getItemFromLocalStorage(key: string) {
    // Get all keys from local storage.
    let keys = getKeysFromLocalStorage();
  
    // Check if the specified key is present in local storage.
    if (keys.includes(key)) {
      // Retrieve and return the item corresponding to the key.
      const item = window.localStorage.getItem(key);
      return item ? item : null;
    } else {
      // Return null if the specified key is not present in local storage.
      return null;
    }
  }
  
  /**
   * Retrieves an object from local storage with the specified key.
   * @param {string} key - The local storage key that identifies the stored value.
   * @returns The stored object if it exists or null.
   */
  export function getObjectFromLocalStorage(key: string) {
    // Get all keys from local storage.
    let keys = getKeysFromLocalStorage();
  
    // Check if the specified key is present in local storage.
    if (keys.includes(key)) {
      // Retrieve the item corresponding to the key.
      const item = window.localStorage.getItem(key);
      if (item) {
        // Parse and return the item as an object.
        const object = JSON.parse(item);
        return object;
      } else {
        // Return null if the item is not present in local storage.
        return null;
      }
    } else {
      // Return null if the specified key is not present in local storage.
      return null;
    }
  }
  
  /**
   * Deletes an item from local storage with the specified key.
   * @param {string} key - The local storage key that identifies the stored value.
   */
  export function deleteItemFromLocalStorage(key: string) {
    // Get all keys from local storage.
    let keys = getKeysFromLocalStorage();
  
    // Check if the specified key is present in local storage.
    if (keys.includes(key)) {
      // Remove the item corresponding to the key from local storage.
      window.localStorage.removeItem(key);
    }
  }
  
  /**
   * Retrieves the key at the specified index from local storage.
   * @param {string} key - The local storage key that identifies the stored value.
   * @returns The key it exists or null.
   */
  export function getKeyAtIndexFromLocalStorage(index: number) {
    try {
      // Get and return the key at the specified index.
      const key = window.localStorage.key(index);
      return key;
    } catch {
      // Return null if an error occurs while retrieving the key.
      return null;
    }
  }
  
  /**
   * Retrieves all keys from local storage.
   * @returns All the keys in the local storage.
   */
  export function getKeysFromLocalStorage() {
    // Initialize an array to store all keys.
    let keys = [];
  
    // Iterate through all keys in local storage and add them to the array.
    for (var i = 0, len = window.localStorage.length; i < len; ++i) {
      keys.push(window.localStorage.key(i));
    }
  
    // Return the array of keys.
    return keys;
  }
  
  /**
   * Retrieves all items from local storage.
   * @returns All the items as strings in the local storage.
   */
  export function getItemsFromLocalStorage() {
    // Initialize an array to store all items.
    let items = [];
  
    // Iterate through all keys in local storage and retrieve their corresponding items.
    for (var i = 0, len = window.localStorage.length; i < len; ++i) {
      items.push(
        window.localStorage.getItem(window.localStorage.key(i) as string)
      );
    }
  
    // Return the array of items.
    return items;
  }
  