// import React from 'react';
// import { Stack, Typography } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const Error403Page = () => {
//   const { t } = useTranslation();
//   return (
//     <Stack sx={{
//         height: '100vh',
//         justifyContent: 'center',
//         alignItems: 'center',
//         marginTop: '-150px' 
//     }}>
//       <Typography component='h4' sx={{
//           fontSize: "100px",
//           fontWeight: 'bold',
//           textAlign: 'center' 
//       }}>
//         {'403'}
//       </Typography>
//       <Typography sx={{textAlign: 'center'}}>
//         {t('Unauthorized Access')}
//       </Typography>
//     </Stack>
//   );
// };

// export default Error403Page;
import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; 


const Error403Page = () => {
  const { t } = useTranslation();

  return (
    <Stack
    sx={{
      height: '100vh',
      justifyContent: 'flex-start', 
      alignItems: 'center',
      backgroundColor: '#f7f8fc',
      padding: '0 20px',
      paddingTop: '190px', 
      paddingLeft: '50px'
    }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 120, color: '#FF6B6B', mb: 2 }} /> {/* Icone d'avertissement */}
      <Typography
        component='h4'
        sx={{
          fontSize: '120px',
          fontWeight: 'bold',
          color: '#2C3E50',
          textAlign: 'center',
          mb: 2, // Espace entre le titre et le texte
        }}
      >
         {'403'}
      </Typography>
      <Typography
        variant='h6'
        sx={{
          color: '#6C757D', 
          textAlign: 'center',
          mb: 4, 
        }}
      >
        {t('Unauthorized Access')}
      </Typography>
  
    </Stack>
  );
};

export default Error403Page;
