/*import React from 'react';
import { GridPagination } from '@mui/x-data-grid';

const CustomPagination: React.FC<any> = (props) => {
  return (
    <GridPagination
      {...props}
      labelDisplayedRows={({ from, to, page }) => `${from}-${to} | Page ${page + 1}`}
    />
  );
};

export default CustomPagination;*/

import React from 'react';
import { GridPagination } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const CustomPagination: React.FC<any> = (props) => {
  const { t } = useTranslation();
  return (
    <GridPagination
      {...props}
      labelRowsPerPage={t('rowsPerPage')} 
      labelDisplayedRows={({ from, to, page }) => `${from}-${to}`}
    />
  );
};

export default CustomPagination;




