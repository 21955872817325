/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { css, jsx } from "@emotion/react";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import tabsStyle from "../../../styles/components/tabs/tabs.style";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  
}

const tabsElements = tabsStyle;

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "calc(100% - 70px)", overflowY: "auto" }}
      css={tabsElements.tabPanel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface BasicTabProps {
  tabs: any[];
  value: number;
  handleChange: any;
  valueAccessor: string;
  labelAccessor: string;
  keyAccessor: string;
  fullWidth: boolean;
}
/**
 * Component to customise the Tabsreact Mui component
 *
 * @component
 * @param {any[]} tabs              all the tabs a tab should have a key, a label and a value
 * @param {number} value            the currently selected tab
 * @param {any} handleChange        the current tab change callback function
 * @param {string} valueAccessor    the attribute of the tab object designated as the value
 * @param {string} labelAccessor    the attribute of the tab object designated as the label
 * @param {string} keyAccessor      the attribute of the tab object designated as the key
 * @param {boolean} fullWidth       specifies whether the tabs should expand to full width or just match the content
 * @example
 * const tabs = [{ key: 0, label: "My Team", value: 0 },{ key: 1, label: "User Profiles", value: 1 }];
 * const message = "You have been warned";
 * const severity = "warning";
 * return (
 *   <BasicTabs showing message severity/>
 * )
 */
export default function BasicTabs(props: BasicTabProps) {
  const {
    tabs,
    value,
    handleChange,
    valueAccessor,
    labelAccessor,
    keyAccessor,
    fullWidth,
  } = props;

  /*
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  */

  return (
    <Box sx={{ width: "60%",marginTop:10, marginLeft:55}}>
      <Box
        css={tabsElements.tabsContainer}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={fullWidth ? "fullWidth" : "scrollable"}
        >
          {tabs.map((tab) => {
            return (
              <Tab
                key={"tab-" + tab[keyAccessor]}
                label={tab[labelAccessor]}
                icon={tab.action}
                iconPosition="end"
                {...a11yProps(tab[valueAccessor])}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
}
