// store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.slice";
import notificationReducer from "./reducers/notification.slice";
import paginationReducer from "./reducers/preference.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

// Redux Persist configuration
const persistConfig = {
  key: "root", // Key for persisting the entire Redux store
  storage: storage, // Storage method (e.g., local storage)
};

// Persisted Preferences Reducer using redux-persist
const persistedUserReducer = persistReducer(persistConfig, userReducer);

// Combined Root Reducer
const rootReducer = combineReducers({
  user: persistedUserReducer,
  notification: notificationReducer, // Include the notification reducer here
  pagination: paginationReducer,

});

// Redux Store Configuration
const store = configureStore({
  reducer: rootReducer,
});

// Export the Redux store
export default store;

// Export the Redux persistor for state persistence
export const persistor = persistStore(store);

// Export types for type-checking in components
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
