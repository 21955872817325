import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CameraAddRequest } from "../../../types/Requests";
import { addCamera } from "../../../services/camera/camera.service";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  notifyError,
  notifySuccess,
} from "../../../store/reducers/notification.slice";
import { useTranslation } from "react-i18next";

type CreateCameraToSiteProps = {
  open: boolean;
  handleClose: () => void;
  onCreateSuccessCB: () => void;
  timeZoneId: number;
  siteId: string;
  userId: string;
};

const CreateCameraToSite: React.FC<CreateCameraToSiteProps> = ({
  open,
  handleClose,
  onCreateSuccessCB,
  timeZoneId,
  siteId,
  userId,
}) => {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({ cameraName: "", macAddress: "" });
  
  const sessionId = useAppSelector((state) => state.user.sessionId);
  const queryClient = useQueryClient();
  const RegExMac = /^([0-9a-f]{2}([:-]|$)){6}$|([0-9a-f]{4}([.]|$)){3}$/i;
  const RegExCameraName = /^([a-zA-Z0-9 _-]+)$/;

  // Add camera form
  const [cameraForm, setCameraForm] = useState({
    cameraName: "",
    macAddress: "",
  });

  //Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleNext = () => {
    setStep(2);
  };

// These functions handle validation for the camera form fields
  const validateCameraName = (name: string): string => {
    if (!name) return t("Camera name is required.");
    return RegExCameraName.test(name) ? "" : t("Invalid camera name.");
  };
  
  const validateMacAddress = (mac: string): string => {
    if (!mac) return t("MAC address is required.");
    return RegExMac.test(mac) ? "" : t("Invalid MAC address format.");
  };

  // Mutation for adding a new camera
const userMutation = useMutation({
  mutationFn: (request: CameraAddRequest) => {
    console.log("Attempting to add camera with request:", request);
    return addCamera(request);
  },
  onSuccess: () => {
    console.log("Camera added successfully");
    onCreateSuccessCB();
    dispatch(notifySuccess(t("Camera added successfully")));
    handleClose();
    // queryClient.invalidateQueries({ queryKey: ["cameras", siteId] });
  },
  onError: (error: any) => {
    console.log("Error encountered:", error);

    const statusCode = error.response?.status;
    const respText = error.response?.data;
    const errorCode = respText?.errors?.code;

    if (statusCode === 403) {
      console.log("Displaying 403 error notification");
      dispatch(
        notifyError(t("Preregistration is not yet valid (allow delay 30 min)"))
      );
    } else if (statusCode === 506) {
      if (respText && respText.includes("-29")) {
        console.log("Displaying error notification for code 506 with '-29'");
        dispatch(
          notifyError(t("This camera is already associated with another subscription"))
        );
      } else {
        console.log("Displaying general error notification for code 506");
        dispatch(
          notifyError(t("Cannot join the registering service, try again in a few minutes"))
        );
      }
    } else if (statusCode === 406) {
      // Handle specific cases for status 406 and error codes -10, -35, -26, and -30
      if (errorCode === "-10") {
        console.log("Displaying error notification for code -10");
        dispatch(
          notifyError(t("No such subscription for this type"))
        );
      } else if (errorCode === "-35") {
        console.log("Displaying error notification for code -35");
        dispatch(
          notifyError(t("No such time zone for this ID"))
        );
      } 
      else if (errorCode === "-26") {
        console.log("Displaying error notification for code -26");
        dispatch(
          notifyError(t("This Camera is unknown, please check the camera identification"))
        );
      } 
     else if (errorCode === "-30") {
        console.log("Displaying error notification for code -30");
        dispatch(
          notifyError(t("This camera is already associated with another user"))
        );
      } 
    } else {
      console.log("Displaying general error notification");
      dispatch(notifyError(t("Error when adding camera")));
    }
     console.error("Add camera mutation error:", error);
  },
});


  // Handle create operation
  const handleCreate = async () => {
    // Validate form fields
  
   const cameraNameError = validateCameraName(cameraForm.cameraName);
   const macAddressError = validateMacAddress(cameraForm.macAddress);

   if (cameraNameError || macAddressError) {
     setErrors({ cameraName: cameraNameError, macAddress: macAddressError });
     return;
   }
  
    const cameraRequest: CameraAddRequest = {
      camera_identification: cameraForm.macAddress,
      camera_name: cameraForm.cameraName,
      sessionId: sessionId,
      site_id: siteId,
      subscription_type: "ABT_FREE_JARVIS",
      time_zone_id: timeZoneId,
      user_id: userId,
    };
  
    try {
      // Perform the mutation
      await userMutation.mutateAsync(cameraRequest);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };
  

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: "bold", color: "#395069" }}>
      {t("Add a new camera to this site")}
      </DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <>
            <Typography variant="body1">
            {t("Step 1")}
            </Typography>
            <br />
            <Typography variant="body1">
            {t("Step 2")}
            </Typography>
            <br />
            <Typography variant="body1">
            {t("Step 3")}
            </Typography>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label={t("Camera Name")}
                fullWidth
                size="small"
                value={cameraForm.cameraName}
                onChange={(e) => setCameraForm({ ...cameraForm, cameraName: e.target.value })}
                variant="outlined"
                error={!!errors.cameraName}
                helperText={errors.cameraName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label={t("MAC Address")}
                fullWidth
                size="small"
                value={cameraForm.macAddress}
                onChange={(e) => setCameraForm({ ...cameraForm, macAddress: e.target.value })}
                variant="outlined"
                error={!!errors.macAddress}
                helperText={errors.macAddress}
              />
              <Typography variant="caption" color="textSecondary">
              {t("Format (XX:XX:XX:XX:XX:XX)")}
              </Typography>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
        {t("Cancel")}
        </Button>
        {step === 1 ? (
          <Button onClick={handleNext} color="primary">
               {t("Next")}
          </Button>
        ) : (
          <Button onClick={handleCreate} color="primary">
                  {t("Add")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateCameraToSite;
