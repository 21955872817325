import { css, SerializedStyles } from "@emotion/react";
import { Color } from "../../constants/color";

// Type definitions for different style categories
type LayoutStyles = {
  container: SerializedStyles;
};

type HeaderStyles = {
  appBar: SerializedStyles;
  logo: SerializedStyles;
  pageLink: SerializedStyles;
  selectedPageLink: SerializedStyles;
  settingElementContainer: SerializedStyles;
};

type MainStyles = {
  container: SerializedStyles;
  content: SerializedStyles;
  toast: SerializedStyles;
};

type SidebarStyles = {
  container: SerializedStyles;
  link: SerializedStyles;
};

type FooterStyles = {
  container: SerializedStyles;
};

// Styles for the layout component
const layoutStyle: LayoutStyles = {
  container: css({
    height: "100%",
    width: "100%",
    paddingLeft: "3px",
  }),
};

// Styles for the header component
export const headerStyle: HeaderStyles = {
  appBar: css({
    height: "70px",
    "& .MuiAppBar-root": {
      backgroundColor: Color.AppBarBg,
    },
  }),
  logo: css({
    width: "150px",
    height: "40px",
    marginLeft: "0px",
  }),
  pageLink: css({
    textDecoration: "none",
    color: Color.TextBlack,
    "& .MuiButton-text": {
      color: Color.TextBlack,
    },
  }),
  selectedPageLink: css({
    textDecoration: "none",
    color: Color.TextPrimary,
    "& .MuiButton-text": {
      color: Color.TextPrimary,
      fontWeight: "bolder",
      textDecoration: "underline",
      textUnderlineOffset: "8px",
    },
  }),
  settingElementContainer: css({
    width: 120,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      color: Color.TextPrimary,
    },
  }),
};

// Styles for the main content area
export const mainStyle: MainStyles = {
  container: css({
    height: "100%",
    overflowY: "auto",
    width: "calc(100% - 10px)",
    maxWidth: "calc(1536px)",
    margin: "10px auto",
    paddingTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: 'red',
  }),
  content: css({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    position: "relative",
  }),
  toast: css({
    position: "fixed",
    top: 100,
    right: 100,
    zIndex: 9999,
  }),
};

// Styles for the sidebar component
export const sidebarStyle: SidebarStyles = {
  container: css({
    backgroundColor: "#475d78",
    padding: "2px",
    position: "fixed",
    left: 0,
    top: "64.7px", // adjust according to your layout
    height: "100vh",
    width: "185px",
  }),
  link: css({
    textDecoration: "white",
    color: "#000", // adjust the color according to your design
    "&:hover": {
      color: "#333", // adjust the hover color
    },
  }),
};

// Styles for the footer component
export const footerStyle: FooterStyles = {
  container: css({
    width: "100%",
    height: "30px",
    backgroundColor:"rgb(244, 245, 250)",
    //backgroundColor: Color.AppFooterBg,
    boxShadow: `${Color.AppFooterShadow} 0px 10px 20px`,
    position: 'fixed',
    bottom: "0px",
  }),
};

export default layoutStyle;
