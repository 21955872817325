import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Typography } from "@mui/material";
import BasicModal from "../../reusable/modals/BasicModal";
import { useTranslation } from "react-i18next";


interface AlarmVideoClipProps {
  open: boolean;
  onClose: () => void;
  videoUrl: string;
}

const AlarmVideoClip: React.FC<AlarmVideoClipProps> = ({ open, onClose, videoUrl }) => {
  const { t } = useTranslation(); 
  
  // Define the actions to be shown in the modal
  const actions = (
    <Button onClick={onClose} color="primary">
       {t('Close')}
    </Button>
  );

  return (
    <BasicModal
      open={open}
      handleClose={onClose}
      title={t("Vidéo clip d'alarme")}
      maxWidth="md"
      actions={actions}
      actionsLoading={false} // Assuming no loading state; set to true if needed
      fullWidth
    >
      {videoUrl ? (
        <video controls style={{ width: "100%" }}>
          <source src={videoUrl} type="video/mp4" />
          {t('Your browser does not support the video tag.')} 
        </video>
      ) : (
        <Typography>{t('No video available')}</Typography>
      )}
    </BasicModal>
  );
};

export default AlarmVideoClip;
