import getConfiguration from "../../config/config";
import axios from "axios";
import { APIPaths } from "../paths";
import {
  GetCameraListBySiteRequest,
  SiteAddRequest,
  SiteUpdateRequest,
} from "../../types/Requests";
import { getUserSession } from "../../utils/auth";
import { SitesListRequest, SiteDeleteRequest } from "../../types/Requests";

/*Get site List*/
const getSitesList = (request: SitesListRequest) => {
  const configuration = getConfiguration();

  return axios
    .post(configuration.apiPath + APIPaths.siteList, request)
    .then((response) => {
      console.log("API", response);
      return response;
    })
    .catch((error) => {
      console.error("API error:", error);
      throw error;
    });
};

/**
 * Adds a new site.
 *
 * @param request The user data to be added.
 * @returns {Promise<AddSiteResponse>} Promise containing the response data of the added user.
 */

const addSite = async (request: SiteAddRequest) => {
  try {
    const sessionId = getUserSession();

    if (!sessionId) {
      throw new Error("Session ID is required"); // Vérification de l'ID de session
    }
    const configuration = getConfiguration();
    const url = `${configuration.apiPath}${APIPaths.siteAdd}?sessionId=${sessionId}`;
    console.log("URL:", url);

    const response = await axios.post(url, request, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
    });
    return response.data; // Retourne la réponse reçue du serveur
  } catch (error) {
    // Gestion des erreurs
    console.error("Error adding user:", error);
    throw error; // Rethrow pour propager l'erreur
  }
};

/**
 * Updates the details of a specific site.
 * @param {SiteUpdateRequest} request
  //  @returns {Promise<UpdateUserResponse >} Promise indicating the success of the operation.
 */
const updateSite = async (request: SiteUpdateRequest) => {
  try {
    const sessionId = getUserSession();
    const configuration = getConfiguration();
    const url = `${configuration.apiPath}${APIPaths.siteUpdate}?sessionId=${sessionId}`;
    const response = await axios.post(url, {
      ...request,
    });
    return response.data;
  } catch (error) {
    // Gestion des erreurs
    console.error("Error adding user:", error);
    throw error;
  }
};

/**
 * Delete a site.
 *
 * @param request The user data to be added.
 * @returns {Promise<DeleteSiteResponse>} Promise containing the response data of the added user.
 */

const deleteSite = async (request: SiteDeleteRequest) => {
  try {
    const sessionId = getUserSession();
    if (!sessionId) {
      throw new Error("Session ID is required"); // Vérification de l'ID de session
    }
    const configuration = getConfiguration();
    const url = `${configuration.apiPath}${APIPaths.siteDelete}?sessionId=${sessionId}`;
    const response = await axios.post(url, request, {});
    return response.data; // Retourne la réponse reçue du serveur
  } catch (error) {
    // Gestion des erreurs
    console.error("Error adding user:", error);
    throw error; // Rethrow pour propager l'erreur
  }
};

/**
 * Get timeZones list
 * @returns {Promise} Promise containing the list of users.
 */
const getTimeZonesList = () => {
  const configuration = getConfiguration();
  const sessionId = getUserSession();
  const url = `${configuration.apiPath}${APIPaths.timeZoneList}?sessionId=${sessionId}`;
  return axios.get(url);
};

/**
 * Get cameras by site.
 *
 * @param {GetCameraListBySiteRequestrequest} request object containing sessionId and site_id.
 * @returns{Promise} Promise containing the list of cameras for the site.
 */
const getCamerasBySite = (request: GetCameraListBySiteRequest) => {
  const configuration = getConfiguration();
  return axios.post(configuration.apiPath + APIPaths.camerasBySite, {
    ...request,
  });
};

export {
  addSite,
  getTimeZonesList,
  getSitesList,
  deleteSite,
  getCamerasBySite,
  updateSite
};
