import Switch from "@mui/material/Switch";

interface SwitchProps {
  value: boolean;
  handleClick: any;
  disabled?: boolean;
}
/**
 * Component for customising yes/no switches
 *
 * @component
 * @param {boolean} value     the current state of the switch
 * @param {any} handleClick   the switch click function
 * @param {boolean} disabled  [optional] the switch disabled state
 * @example
 * const value = true
 * const handleClick = () => {
 *  setSwitchValue((oldValue) => {return !oldValue;});
 * };
 * return (
 *   <DefaultSwitch value handleClick />
 * )
 */
const DefaultSwitch = (props: SwitchProps) => {
  const { handleClick, value, disabled = false } = props;
  return <Switch checked={value} onChange={handleClick} disabled={disabled} />;
};

export default DefaultSwitch;
