import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

interface LongMenuProps {
  row: any;
  menuItems: {
    id: string;
    label: string;
    color: string;
    handleClick: (row: any) => void;
  }[];
}

const ITEM_HEIGHT = 48;

const LongMenu: React.FC<LongMenuProps> = ({ row, menuItems }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={anchorEl ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: 12,
        }}
      >
        <NotificationsActiveIcon sx={{color:"#5585b5"}}/>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              item.handleClick(row);
              handleClose();
            }}
            sx={{
              color: "#303841",
              fontSize: "0.888rem",
              fontWeight: 500,
              "&:hover": {
                background: " #cadefc", // Light gray background on hover
              },
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LongMenu;
